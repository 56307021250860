import {BookingModel} from "../../booking.model";
import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {SeatMutationBase} from "./seat-mutation-base";
import {ISeatModel} from "../../seat-maps/seat.model.interface";

export class RemoveSeatMutation extends SeatMutationBase {
    constructor(booking: BookingModel,
                private readonly journeyKey: string,
                private readonly passenger: MaturePassengerModel,
                private readonly seatToRemove: ISeatModel) {
        super(booking);
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        try {
            await this.session.removeSeat({
                passengerKey: this.passenger.passengerKey,
                seatKey: this.seatToRemove.seatKey
            });
            this.booking.analyticsHandler.removeKeyMappings([this.seatToRemove.seatKey])
            this.services.analytics.seatSelectionEvents.logRemoveFromCart(this.booking, this.journeyKey, this.passenger.passengerKey);
            return {};

        } catch (err){
            this._showError();
            this.services.logger.error(`Failed to free seat ${this.seatToRemove.seatKey} from passenger ${this.passenger.passengerKey}`, err);
            return await this.session.bookingStateQueryBuilder().useSeatMapsAvailability().getBookingState();
        }


    }

    private _showError(): void {
        const message = this.services.language.translationFor("Sorry! We couldn't deallocate seat {seatNumber} from passenger {passengerName}. Please try again.").withParams({
            seatNumber: this.seatToRemove.seatNumber,
            passengerName: this.passenger.getFullName()
        });

        this.services.alert.showError(message);
    }
}
