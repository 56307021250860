import React, {useState} from "react";
import {useServices} from "../../../hooks/use-services.hook";
import {observer} from "mobx-react";
import {PassengerDetailsEditorComponent} from "./components/passenger-details.component";
import {BookingContactComponent} from "./components/booking-contact.component";
import {FormComponent, FormComponentType} from "../../../components/forms/form.component";
import {IPassengerViewModel} from "../../../services/booking/models/passenger/passenger-view-model.interface";
import {BookingFlowPage} from "../booking-flow.page";
import styled from "styled-components";
import {useScreenMediaQueriesChecks} from "../../../hooks/use-screen-media-queries-checks.hook";

const PageTitleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`

const SubtitleBox = styled.span`
    font-size: ${props => props.theme.fontSize.body2};
    font-weight: ${props => props.theme.fontWeight.regular};
    color: ${props => props.theme.colors.darkTint};
    padding-right: ${props => props.theme.spacing.spacing8};
    ${props => props.theme.screenMediaQuery.smallScreen} {
        padding: 0 ${props => props.theme.spacing.spacing20};
    }
`

const SubtitleComponent: React.FC = observer(() => {
    const services = useServices();
    return (
        <SubtitleBox>
            {services.language.translate('Please enter names as they appear on passport or travel documentation.')}
        </SubtitleBox>
    );
})

const PageTitleComponent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const titleText = services.language.translate('Passengers details');
    if(screenMediaQuery.smallScreen) {
        return <>{titleText}</>;
    }
    return (
        <PageTitleContainerBox>
            <span>{titleText}</span>
            <SubtitleComponent/>
        </PageTitleContainerBox>
    )
})

const PassengersDetailsPageContent: React.FC = observer(() => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const [currentEditedPassenger, setCurrentEditedPassenger] = useState<IPassengerViewModel | null>(null);
    const passengers = services.booking.current.passengers.getAllPersonsInTheBooking();
    const onEnterPassengerDetailsHandler = (passenger: IPassengerViewModel) => {
        if(currentEditedPassenger && currentEditedPassenger.passengerKey !== passenger.passengerKey) {
            currentEditedPassenger.companion.showAvailableCompanions = false;
        }
        passenger.companion.showAvailableCompanions = true;
        setCurrentEditedPassenger(passenger);
    }

    const onSubmit = async () => {
        await services.booking.steps.nextStep();
    }

    return (
        <FormComponent type={FormComponentType.Flat} onSubmit={onSubmit}>
            {screenMediaQuery.smallScreen && <PageTitleContainerBox><SubtitleComponent/></PageTitleContainerBox>}
            {
                passengers.map((p) => {
                    return (
                        <PassengerDetailsEditorComponent key={p.stablePassengerUniqueKey}
                                                         passenger={p}
                                                         onEnterPassengerDetails={onEnterPassengerDetailsHandler}/>
                    );
                })
            }
            <BookingContactComponent/>
        </FormComponent>
    );
});

export const BookingPassengersDetailsPage: React.FC = observer(() => {

    return (
        <BookingFlowPage headerOptions={{
                            title: (<PageTitleComponent/>)
                         }}
                         contentOptions={{
                            renderContent: () => <PassengersDetailsPageContent/>
                         }}
                         shoppingCartOptions={{
                             showPromotionCode: true
                         }}
                         />
    );
});
