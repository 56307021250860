import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {
    DotRezUpdateSsrsRequestBuilder
} from "../../../../dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {isDotRezSessionExpiredError} from "../../../../dot-rez-api/session/dot-rez-exception";
import {IDotRezUpdateSsrsResponse} from "../../../../dot-rez-api/data-contracts/responses/booking/update-ssrs.response";


export class SellSsrsMutation extends BookingMutationActionBase {

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        const builder = new DotRezUpdateSsrsRequestBuilder();
        this.booking.getAllPassengersSegments().forEach(ps => {
            ps.getAllSsrsEditors().forEach(ssr => {
                const sellSsrResult = ssr.buildUpdateSsrsRequest();
                builder.append(sellSsrResult);
            });
        });

        const request = builder.build();
        if(request.ssrsToAdd.length === 0 && request.ssrKeysToRemove.length === 0) {
            return {};
        }

        const getBookingState = async () => {
            try {
                return await this.session.bookingStateQueryBuilder().useBookingData().getBookingState();
            } catch (err) {
                this.services.logger.error('Failed to get booking state after sell ssrs', err);
                return {};
            }

        }

        let result: IDotRezUpdateSsrsResponse;
        try {
            result = await this.session.updateSsrs(request);
        } catch (err) {
            this.services.logger.error('Failed to sell ssrs', err);
            this.booking.revertSsrsQuantity();
            if(!isDotRezSessionExpiredError(err)) {
                this.services.alert.showError(this.services.language.translate('There was an error trying to save your selection'));
                return await getBookingState();
            }

            return {};
        }

        this._sendRemovedFromCartAnalytics(result);

        const bookingState = await getBookingState()

        this.booking.updateBookingSessionData(bookingState);

        this._sendAddToCartAnalytics(result);

        this.booking.tryChangePassengersTypes();

        //no need to return bookingState here because we did the updateBookingSessionData above
        return {};

    }

    private _sendRemovedFromCartAnalytics(updateSsrsResult: IDotRezUpdateSsrsResponse): void {
        this.booking.analyticsHandler.removeKeyMappings(updateSsrsResult.removedSsrsKeys);
        this.booking.analyticsHandler.addKeyMappings(updateSsrsResult.addedSsrsKeys);

        this.services.analytics.extrasSelectionEvents.logRemoveFromCart(this.booking, updateSsrsResult.removedSsrsKeys)
    }

    private _sendAddToCartAnalytics(updateSsrsResult: IDotRezUpdateSsrsResponse): void {
        this.services.analytics.extrasSelectionEvents.logAddToCart(this.booking, updateSsrsResult.addedSsrsKeys)
    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {
           await super.onAfterBookingSessionDataUpdated();
    }

}
