import {IPassengerTypeSelector} from "../passengers-types.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {NullableString} from "../../../types/nullable-types";
import {
    IDotRezAvailabilityLowFarePassengers
} from "../../dot-rez-api/data-contracts/requests/booking/lowfare-search.request";
import {
    IDotRezAvailabilitySimpleRequestPassengers
} from "../../dot-rez-api/data-contracts/requests/booking/search-simple.request";

import {InfantSelector} from "./infant-selector";
import {PassengersTypesSelectorsListBase} from "./passengers-types-selectors-list-base";

export class ReadWritePassengersTypesSelectorsList extends PassengersTypesSelectorsListBase {

    constructor(services: IServiceFactory, public withBlueBenefits: () => boolean) {
        super(services);
    }

    push(selector: IPassengerTypeSelector) {
        this._allSelectors.push(selector);
    }

    get canAddMorePassengers(): boolean {
        return this.getSelectedOnly().sum(item => item.count) - this.countInfants() < this.maximumNumberOfPassengersAllowedOnBooking;
    }

    get maximumNumberOfPassengersAllowedOnBooking(): number {
        if(this.withBlueBenefits()) {
            return this.services.user.profile.blueBenefitsSubscription.maxNumberOfPassengers;
        }

        return this.services.configuration.data.maxAllowedPassengersNumberOnBooking;
    }

    get passengersMaximumLimitReachedErrorMessage(): NullableString {
        if(this.canAddMorePassengers) {
            return null;
        }

        return this.services.language.translationFor(`Maximum number of passengers allowed on a booking is {numberOfPassengers}`).withParams({
            numberOfPassengers: this.maximumNumberOfPassengersAllowedOnBooking
        });
    }



    private _getSelectedMaturePassengerTypesSelectors(): IPassengerTypeSelector[] {
        return this._allSelectors.filter(selector => selector.count > 0 && !(selector instanceof InfantSelector));
    }

    createPassengerTypesForLowFareSearch(): IDotRezAvailabilityLowFarePassengers {
        return this.createPassengerTypesForAvailabilitySearch();
    }
    createPassengerTypesForAvailabilitySearch(): IDotRezAvailabilitySimpleRequestPassengers {
        let result: IDotRezAvailabilitySimpleRequestPassengers = [];
        for(let selector of this._getSelectedMaturePassengerTypesSelectors()) {
            result = [
                ...result,
                ...selector.getCodesForAvailabilitySearch()
            ]
        }

        return result;
    }

    createPassengerTypesForRegularSell(): IDotRezAvailabilitySimpleRequestPassengers {
        return this.createPassengerTypesForAvailabilitySearch();
    }
    createPassengerTypesForBlueBenefitsSell(): IDotRezAvailabilitySimpleRequestPassengers {
        return this._getSelectedMaturePassengerTypesSelectors()
            .map(selector => {
                return {
                    type: selector.getCodeForBlueBenefitsSell(),
                    count: selector.count
                }
            });
    }

    limit(maxNumberOfMaturePassengers: number): void {
        let diff = this.countAllWithoutInfants() - maxNumberOfMaturePassengers;
        if(diff <= 0) {
            return;
        }

        const sortedByMinAgeAsc = this._allSelectors.filter(p => !(p instanceof InfantSelector) && p.count > 0)
            .sort((p1, p2) => p1.minimumAge - p2.minimumAge);

        for(let pSelector of sortedByMinAgeAsc) {
            const toReduce = Math.min(diff, pSelector.count);
            pSelector.count -= toReduce;
            diff -= toReduce;
            if(diff === 0) {
                return;
            }
        }
    }
}