import {IBookingPsoViewModel} from "./booking-pso.view-mode.interface";
import {BookingModel} from "../booking.model";
import {PsoMarket, PsoMarketDiscountOptions} from "../../../stations/station.service.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {PsoUserAgreementEnum, PsoUserOptionsEnum} from "../../../flight-search/flight-search-controller/pso.enums";
import {BookingFlowTypeEnum} from "../../booking-strategies/booking-flow-type.enum";
import {IBookingStrategyToBookingAdapter} from "../../booking-strategies/booking-strategy-to-booking.adapter.interface";
import {JourneyModel} from "../journey/journey.model";

export class BookingPsoModel implements IBookingPsoViewModel {
    constructor(private readonly booking: BookingModel) {
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    private get bookingStrategy(): IBookingStrategyToBookingAdapter {
        return this.booking.bookingStrategyAdapter;
    }

    private get isOnBookingFlow(): boolean {
        return this.bookingStrategy.bookingFlowType === BookingFlowTypeEnum.Booking;
    }

    get isPsoAcceptedByUser(): boolean {
        return this.isOnBookingFlow && this.bookingStrategy.flightSearchController.psoUserAgreement === PsoUserAgreementEnum.Agree;
    }

    private isJourneyEligibleForPsoDiscount(journey: JourneyModel, departureLimitDate: Date): boolean {

        const departureDate = journey.designator.departureDate;

        return departureDate.getTime() <= departureLimitDate.getTime();
    }

    get shouldAskForPsoUserAgreement(): boolean {
        return (this.isOnBookingFlow
                && this.bookingStrategy.flightSearchController.psoUserOption !== PsoUserOptionsEnum.None
                && this.bookingStrategy.flightSearchController.psoUserAgreement === PsoUserAgreementEnum.None);
    }

    private get psoMarket(): PsoMarket | null {
        const journey = this.booking.departureJourney;
        if(!journey) {
            return null;
        }

        return this.services.stations.getPsoMarket(journey.designator.origin, journey.designator.destination);
    }

    private get psoDiscountOptions(): PsoMarketDiscountOptions | null {


        if(!this.isPsoAcceptedByUser) {
            return null;
        }

        const psoMarket = this.psoMarket;


        if(!psoMarket?.discountOptions) {
            return null;
        }


        if(this.booking.getBookedDate().getTime() > psoMarket.bookDateUntil.getTime()) {
            return null;
        }

        return psoMarket.discountOptions;
    }

    get allowReducedMobilityDiscount(): boolean {
        return this.isPsoAcceptedByUser && Boolean(this.psoMarket?.allowReducedMobilityDiscount);
    }

    getReducedMobilitySsrTranslation(): string {
        if(this.psoMarket) {
            return this.services.language.translationFor(`Passenger with a civil disability equal to or greater than 80%, resident in {market}`)
                .withParams({
                    market: this.psoMarket.getMarketNameTranslation()
                })
        } else {
            return this.services.ssrTypes.PRMP.name;
        }
    }

    get isEligibleForPsoMarketDiscount(): boolean {
        if(!this.psoDiscountOptions) {
            return false;
        }

        return  this.booking.unfilteredJourneys.some(j => this.isJourneyEligibleForPsoDiscount(j, this.psoDiscountOptions!.departureDateUntil));
    }

    public get shouldRecalculatePsoDiscount(): boolean {
        return this.isEligibleForPsoMarketDiscount || Boolean(this.psoDiscountOptions?.hasPsoDiscountFee(this.booking));
    }


    async applyPsoMarketDiscount(): Promise<void> {
        if(!this.shouldRecalculatePsoDiscount) {
            return;
        }
        const discountOptions = this.psoDiscountOptions;

        if(discountOptions) {
            await discountOptions.applyDiscount(this.booking);
        }
    }
}