import {
    IPassengersTypesService,
    IPassengerType,
    IPassengerTypeSelector,
    IPassengerTypeSelectorsList,
} from "./passengers-types.service.interface";
import {ServiceBase} from "../service-base";
import {ADULT_TYPE_CODES, CHILD_TYPE_CODES, PassengerTypeCodesEnum} from "./passenger-type-codes.enum";
import {
    Adult,
    AdultBlueBenefits,
    AdultWithChild,
    AdultWithChildAndCompanionForPRM,
    AdultWithReducedMobility
} from "./models/adult";
import {Child, ChildBlueBenefits} from "./models/child";
import {Infant} from "./models/infant";
import {AdultSelector} from "./selectors/adult-selector";
import {ChildSelector} from "./selectors/child-selector";
import {InfantSelector} from "./selectors/infant-selector";
import {ReadWritePassengersTypesSelectorsList} from "./selectors/read-write-passengers-types-selectors-list";
import {ReadonlyPassengersTypesSelectorsList} from "./selectors/readonly-passengers-types-selectors-list";

export class PassengersTypesService extends ServiceBase implements IPassengersTypesService {

    createReadWritePassengersTypesSelectorsList(withBlueBenefits: () => boolean, initialCountValues?: Record<string, number>): IPassengerTypeSelectorsList {
        initialCountValues = initialCountValues ?? {};
        const passengerTypesSelectors = new ReadWritePassengersTypesSelectorsList(this.services, withBlueBenefits);

        const initialAdults = this._matchInitialPassengerTypeCount(initialCountValues, ADULT_TYPE_CODES, 1);
        const initialChildren = this._matchInitialPassengerTypeCount(initialCountValues, CHILD_TYPE_CODES, 0);

        const adultSelector = new AdultSelector(this.services, passengerTypesSelectors, initialAdults);
        passengerTypesSelectors.push(adultSelector);
        passengerTypesSelectors.push(new ChildSelector(this.services, passengerTypesSelectors, initialChildren));
        passengerTypesSelectors.push(new InfantSelector(this.services, adultSelector, passengerTypesSelectors, initialCountValues[PassengerTypeCodesEnum.Infant] || 0));
        return passengerTypesSelectors;
    }

    createReadonlyPassengersTypesSelectorsList(initialPassengersTypesCount: Record<PassengerTypeCodesEnum, number>): IPassengerTypeSelectorsList {
        return new ReadonlyPassengersTypesSelectorsList(this.services, initialPassengersTypesCount);
    }

    private _matchInitialPassengerTypeCount(initialCountValues: Record<string, number>, passengerTypes: PassengerTypeCodesEnum[], minValue: number): number {
        const matchedCode = passengerTypes.find(c => Boolean(initialCountValues[c]));
        if(matchedCode) {
            return Math.max(minValue, initialCountValues[matchedCode]);
        }

        return minValue;
    }

    createMaturePassengerTypeSelector(passengerTypeCode: PassengerTypeCodesEnum, count: number, passengersTypesSelectorList: IPassengerTypeSelectorsList): IPassengerTypeSelector {
        if(ADULT_TYPE_CODES.includes(passengerTypeCode)) {
            return new AdultSelector(this.services, passengersTypesSelectorList, count);
        } else if(CHILD_TYPE_CODES.includes(passengerTypeCode)) {
            return new ChildSelector(this.services, passengersTypesSelectorList, count);
        } else {
            throw new Error(`Unknown mature passenger type code ${passengerTypeCode}`);
        }
    }

    createInfantTypeSelector(count: number, adultSelector: AdultSelector, passengersTypesSelectorList: IPassengerTypeSelectorsList): IPassengerTypeSelector {
        return new InfantSelector(this.services,
                   adultSelector,
                   passengersTypesSelectorList,
                   count);
    }

    getPassengerType(code: string): IPassengerType {
        switch (code) {
            case PassengerTypeCodesEnum.Adult:
                return new Adult(this.services);
            case PassengerTypeCodesEnum.Child:
                return new Child(this.services);
            case PassengerTypeCodesEnum.AdultWithChild:
                return new AdultWithChild(this.services);
            case PassengerTypeCodesEnum.AdultWithReducedMobility:
                return new AdultWithReducedMobility(this.services);
            case PassengerTypeCodesEnum.Infant:
                return new Infant(this.services);
            case PassengerTypeCodesEnum.AdultWithAndChildAndCompanionForPRM:
                return new AdultWithChildAndCompanionForPRM(this.services);
            case PassengerTypeCodesEnum.AdultClubMember:
                return new AdultBlueBenefits(this.services);
            case PassengerTypeCodesEnum.ChildClubMember:
                return new ChildBlueBenefits(this.services);
            default:
                return new Adult(this.services);
        }
    }



    getPassengerTypeCodeForLowFareSearch(withBlueBenefits: boolean): string {
        if(withBlueBenefits) {
            return PassengerTypeCodesEnum.AdultClubMember;
        } else {
            return PassengerTypeCodesEnum.Adult;
        }
    }

    getInfantType(): IPassengerType {
        return this.getPassengerType(PassengerTypeCodesEnum.Infant);
    }

    getAdultTypes(): IPassengerType[] {
        return ADULT_TYPE_CODES.map(c => this.getPassengerType(c));
    }

    getChildTypes(): IPassengerType[] {
        return CHILD_TYPE_CODES.map(c => this.getPassengerType(c));
    }
}





