import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {JourneyModel} from "../../journey/journey.model";
import {InfantSegmentShoppingCartModel} from "../../base-models/shopping-cart/infant-segment-shopping-cart.model";
import {computed, makeObservable} from "mobx";
import {NullablePrice, Price} from "../../../../currency/price";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";

export class InfantJourneyShoppingCartModel {
    constructor(private readonly passenger: MaturePassengerModel,
                private readonly journey: JourneyModel,
                private readonly shoppingCartMode: ShoppingCartModeEnum) {

        if(!passenger.infant) {
            throw new Error(`Passenger ${passenger.getFullName()} doesn't have an infant`);
        }

        makeObservable(this, {
            infantSegmentsShoppingCarts: computed,
            initialFare: computed,
            currentFare: computed,
            fareToDisplay: computed
        });
    }

    get infantSegmentsShoppingCarts(): InfantSegmentShoppingCartModel[] {
        const result: InfantSegmentShoppingCartModel[] = [];

        this.journey.getAllPassengersSegments().forEach(ps => {
            if(ps.infantShoppingCart && ps.passenger.passengerKey === this.passenger.passengerKey) {
                result.push(ps.infantShoppingCart);
            }
        });

        return result;
    }

    get fareQuantity(): number {
        return 1;
    }

    get initialFare(): NullablePrice {
        const fares: Price[] = [];
        this.infantSegmentsShoppingCarts.forEach(issc => {
            if(issc.initialFare) {
                fares.push(issc.initialFare);
            }
        });

        if(fares.length === 0) {
            return null;
        }

        return Price.sumAll(fares, this.journey.createPrice(0));
    }

    get currentFare(): Price {
        return Price.sumAll(this.infantSegmentsShoppingCarts.map(issc => issc.currentFare), this.journey.createPrice(0));
    }

    get fareToDisplay(): Price {
        if(this.initialFare && this.shoppingCartMode === ShoppingCartModeEnum.ShowNewPurchasesOnly) {
            return this.currentFare.subtract(this.initialFare);
        } else {
            return this.currentFare;
        }

    }
}
