export enum PassengerTypeCodesEnum {
    Adult = 'ADT',
    Child = 'CHD',
    Infant = 'INF',
    AdultWithChild = 'ADTC',
    AdultWithReducedMobility = 'WADT',
    /**
     * Adult that is at the same time a companion for a child and for another adult with reduced mobility
     */
    AdultWithAndChildAndCompanionForPRM = 'ADCW',
    AdultClubMember = 'ADTA',
    ChildClubMember = 'CHDA'

}

export const ADULT_TYPE_CODES = [
    PassengerTypeCodesEnum.Adult,
    PassengerTypeCodesEnum.AdultWithReducedMobility,
    PassengerTypeCodesEnum.AdultWithAndChildAndCompanionForPRM,
    PassengerTypeCodesEnum.AdultWithChild,
    PassengerTypeCodesEnum.AdultClubMember
];
export const CHILD_TYPE_CODES = [PassengerTypeCodesEnum.Child, PassengerTypeCodesEnum.ChildClubMember];