import {NullableNumber, NullableString} from "../../../types/nullable-types";
import {IServiceFactory} from "../../service-factory.interface";
import {PassengerTypeBase} from "./passenger-type-base";
import {PassengerTypeCodesEnum} from "../passenger-type-codes.enum";

export abstract class ChildBase extends PassengerTypeBase {

    get description(): string {
        return this.services.language.translate('Child');
    }

    get implicitTitleValue(): NullableString {
        return 'CHD';
    }

    get isAdult(): boolean {
        return false;
    }

    get isChild(): boolean {
        return true;
    }

    get isInfant(): boolean {
        return false;
    }

    getMinimumAge(): number {
        return 2;
    }

    protected _getMaximumAge(): NullableNumber {
        return 12;
    }

    get canBeMadePrimaryContact(): boolean {
        return false;
    }

    get benefitDedicatedFreeSeats(): boolean {
        return true;
    }
}

export class Child extends ChildBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.Child);
    }
}

export class ChildBlueBenefits extends ChildBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.ChildClubMember);
    }

    get isBlueBenefits(): boolean {
        return true;
    }
}
