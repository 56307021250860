import {IDotRezPartialBookingSessionData} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {IDotRezSsrToAdd} from "../../../../dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {FeeCodeEnum} from "../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {BookingMutationActionBase} from "../booking-mutation-action-base";

interface IInsuranceFeeToAdd {
    passengerKey: string;
    amount: number;
    note: string;
}


export class SellInsuranceMutation extends BookingMutationActionBase {

    protected _getInsuranceFeesToAdd(): IInsuranceFeeToAdd[] {
        let feesToAdd: IInsuranceFeeToAdd[] = [];

        const quote = this.booking.insurance.quote;
        let remainingAmount = quote.totalPrice.amount;

        for(let passenger of this.booking.passengers) {
            const passengerPrice = quote.getPassengerTypePrice(passenger.passengerType);
            let feeAmount = passengerPrice.amount;
            if(feeAmount > remainingAmount) {
                feeAmount = remainingAmount;
            }

            feesToAdd.push({
                passengerKey: passenger.passengerKey,
                amount: feeAmount,
                note: `${passenger.passengerType.code}|${quote.packageId}|${quote.quoteId}`,
            });

            remainingAmount -= feeAmount;

            if(passenger.infant) {
                const infantPrice = quote.getInfantPrice();
                let feeAmount = infantPrice.amount;
                if(feeAmount > remainingAmount) {
                    feeAmount = remainingAmount;
                }

                feesToAdd.push({
                    passengerKey: passenger.passengerKey,
                    amount: feeAmount,
                    note: `${this.services.passengerTypes.getInfantType().code}|${quote.packageId}|${quote.quoteId}`,
                });

                remainingAmount -= feeAmount;
            }
        }

        return feesToAdd;
    }

    private _getSsrsToAdd(): IDotRezSsrToAdd[] {
        const ssrsToAdd: IDotRezSsrToAdd[] = [];

        for(let passengerSegment of this.booking.getAllPassengersSegments()) {

            const ssrEditor = passengerSegment.getSsr(this.services.ssrTypes.INS1);
            const ssrToAdd = ssrEditor.createSsrToAddRequest(1);
            if(ssrToAdd) {
                ssrEditor.newQuantity = 1;
                ssrsToAdd.push(ssrToAdd);
            }
        }

        return ssrsToAdd;

    }


    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        const ssrsToAdd = this._getSsrsToAdd();

        if(ssrsToAdd.length > 0) {
            await this.session.addSsrs(ssrsToAdd);
        }

        const feesToAdd = this._getInsuranceFeesToAdd();

        for(let fee of feesToAdd) {
            await this.services.airlineWebapi.createBookingFee({
                dotRezToken: this.session.token,
                passengerKey: fee.passengerKey,
                feeCode: FeeCodeEnum.Insurance,
                note: fee.note
            });
        }

        const bookingState = await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();
        this.booking.updateBookingSessionData(bookingState);

        for(let fee of feesToAdd) {
            if(fee.amount > 0) {
                const passenger = this.booking.passengers.getByPassengerKey(fee.passengerKey);
                const passengerFee = passenger.fees.find(f => f.feeCode === FeeCodeEnum.Insurance && f.note === fee.note);
                if(passengerFee) {
                    await this.services.airlineWebapi.overrideBookingFee({
                        dotRezToken: this.session.token,
                        feeKey: passengerFee.feeKey,
                        amount: fee.amount
                    });
                }
            }
        }

        return await this.booking.session.bookingStateQueryBuilder().useBookingData().getBookingState();
    }

}
