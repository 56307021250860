import { NullableString } from "../../../types/nullable-types";
import { IDotRezAvailabilityLowFarePassengers } from "../../dot-rez-api/data-contracts/requests/booking/lowfare-search.request";
import { IDotRezAvailabilitySimpleRequestPassengers } from "../../dot-rez-api/data-contracts/requests/booking/search-simple.request";
import {IPassengerTypeSelector} from "../passengers-types.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {PassengerTypeCodesEnum} from "../passenger-type-codes.enum";
import {AdultSelector} from "./adult-selector";
import {PassengersTypesSelectorsListBase} from "./passengers-types-selectors-list-base";

export class ReadonlyPassengersTypesSelectorsList extends PassengersTypesSelectorsListBase {
    constructor(services: IServiceFactory,
                initialPassengersTypesCount: Record<PassengerTypeCodesEnum, number>) {
        super(services);
        this._initialPassengersTypesCount = initialPassengersTypesCount;
        this._loadAllSelectors();
    }

    private readonly _initialPassengersTypesCount: Record<PassengerTypeCodesEnum, number>;

    get canAddMorePassengers(): boolean {
        return false;
    }
    get passengersMaximumLimitReachedErrorMessage(): NullableString {
        return null;
    }

    get maximumNumberOfPassengersAllowedOnBooking(): number {
        return Object.values(this._initialPassengersTypesCount).sum(c => c);
    }

    private _loadAllSelectors(): void {
        const maturePassengersTypes = Object.keys(this._initialPassengersTypesCount).filter(pt => pt !== PassengerTypeCodesEnum.Infant);

        for(let typeCodeStr of maturePassengersTypes) {
            const typeCode = typeCodeStr as PassengerTypeCodesEnum;
            const count = this._initialPassengersTypesCount[typeCode as PassengerTypeCodesEnum];
            if(count <= 0) {
                continue;
            }

            this._allSelectors.push(this.services.passengerTypes.createMaturePassengerTypeSelector(typeCode, count, this));
        }

        const infantsCount = this._initialPassengersTypesCount[PassengerTypeCodesEnum.Infant] ?? 0;
        if(infantsCount > 0) {
            const adultSelector = this._allSelectors.find(pt => pt.hasCode(PassengerTypeCodesEnum.Adult)) as AdultSelector;
            if(!adultSelector) {
                throw new Error(`Can't create an infant selector because there is no adult in the list of passenger types selectors`);
            }

            this._allSelectors.push(this.services.passengerTypes.createInfantTypeSelector(infantsCount, adultSelector, this));
        }

    }

    limit(maxNumberOfMaturePassengers: number): void {

    }

    withBlueBenefits(): boolean {
        return false;
    }

    getSelectedOnly(): IPassengerTypeSelector[] {
        return this.getAll();
    }

    createPassengerTypesForLowFareSearch(): IDotRezAvailabilityLowFarePassengers {
        const result: IDotRezAvailabilityLowFarePassengers = [];
        for(let typeCode of Object.keys(this._initialPassengersTypesCount)) {
            const count = this._initialPassengersTypesCount[typeCode as PassengerTypeCodesEnum]
            if(count > 0 && typeCode !== PassengerTypeCodesEnum.Infant) {
                result.push({
                    type: typeCode,
                    count: this._initialPassengersTypesCount[typeCode as PassengerTypeCodesEnum]
                })
            }
        }
        return result;
    }

    createPassengerTypesForAvailabilitySearch(): IDotRezAvailabilitySimpleRequestPassengers {
        return this.createPassengerTypesForLowFareSearch();
    }
    createPassengerTypesForRegularSell(): IDotRezAvailabilitySimpleRequestPassengers {
        return this.createPassengerTypesForLowFareSearch();
    }
    createPassengerTypesForBlueBenefitsSell(): IDotRezAvailabilitySimpleRequestPassengers {
        return this.createPassengerTypesForLowFareSearch();
    }

}