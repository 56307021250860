import React from 'react';
import {ISeatViewModel} from "../../../../../services/booking/models/seat-maps/seat-view-model.interface";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import classNames from "classnames";
import {XIcon} from "../../../../../assets/svg/x.icon";
import {SeatsRowGroupType} from "../../../../../services/booking/models/seat-maps/seats-row-group-type.enum";
import styled, {css} from "styled-components";
import {
    CSS_VALUE_SEAT_HEIGHT_SMALL,
    CSS_VALUE_SEAT_WIDTH_SMALL,
    CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR,
    CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR_RADIUS,
    CSS_VAR_SEAT_FONT_SIZE,
    CSS_VAR_SEAT_HEIGHT,
    CSS_VAR_SEAT_WIDTH
} from "../seat-map-css-constants";

const IconBox = styled.div`
    margin-top: 1px;
    margin-bottom: 3px;
`

export const PassengerInitialsBox = styled.div`
`


export const SeatBox = styled.div<{$shouldPreserveExtraSpaceBefore?: boolean}>`
    display: flex;
    width: var(${CSS_VAR_SEAT_WIDTH}, ${CSS_VALUE_SEAT_WIDTH_SMALL});
    height: var(${CSS_VAR_SEAT_HEIGHT}, ${CSS_VALUE_SEAT_HEIGHT_SMALL});
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    line-height: 1;
    font-size: var(${CSS_VAR_SEAT_FONT_SIZE}, ${props => props.theme.fontSize.h6});
    font-weight: ${props => props.theme.fontWeight.medium};
    padding-bottom: 4px;  
    flex-direction: column;
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      bottom: 0.4375rem;
      /*left: -5px;*/
      width: calc(100% + 2px);
      height: calc(70%);
      border-left: 4px solid ${props => `rgba(${props.theme.colors.secondaryRgb}, 0.4)`};
      border-right: 4px solid ${props => `rgba(${props.theme.colors.secondaryRgb}, 0.4)`};
      background: linear-gradient(to bottom, transparent 20%, transparent 20% calc(100% - 2px), ${props => props.theme.colors.primaryContrast} calc(100% - 2px) calc(100% - 1px), transparent 1px);
    }
`

const MissingSeatBox = styled.div`
  display: flex;
  height: 41px;
  width: 36px;
`

export const SeatNotAvailableBox = styled(SeatBox)`
    color: ${props => props.theme.colors.mediumContrast};
    background-color: ${props => props.theme.colors.medium};
    cursor: not-allowed;
    &:after {
        border-color: ${props => props.theme.colors.medium};
    }
`

export const NotSelectedSeatBox = styled(SeatBox)`
    color: ${props => props.theme.colors.mediumContrast};
    background-color: ${props => props.theme.colors.medium};
    &:after {
        border-color: ${props => props.theme.colors.medium};
    }
`

export const SeatSelectedBox = styled(SeatBox)`
    color: ${props => props.theme.colors.tertiaryContrast};
    background-color: ${props => props.theme.colors.tertiary};
    &:after {
        border-color: ${props => props.theme.colors.tertiary};
    }
`

export const RearSeatBox = styled(SeatBox)`
  background-color: rgba(${props => props.theme.colors.successRgb}, 0.3);
  &:after {
    border-color: rgba(${props => props.theme.colors.successRgb}, 0.3);
  }
`

export const FrontSeatBox = styled(SeatBox)`
    background-color: ${props => props.theme.colors.primary};
    &:after {
        border-color: ${props => props.theme.colors.primary};
    }
`

export const SeatDangerBox = styled(SeatBox)`
    background-color: ${props => props.theme.colors.dangerTint};
    &:after {
        border-color: ${props => props.theme.colors.dangerTint};
    }
`



function extraLegRoomCommonCss(arrowsColorRgb: string, shouldAddExtraSpaceBefore?: boolean) {
    const backgroundUrl = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="20.011" xml:space="preserve"><path d="M3.292,7.707A1,1,0,0,0,4.707,6.293L3.413,5H16.6L15.3,6.293a1,1,0,0,0,1.414,1.414l3-3a1,1,0,0,0,0-1.416l-3-3A1,1,0,1,0,15.3,1.708L16.6,3H3.413L4.707,1.708A1,1,0,1,0,3.292.294l-3,3a1,1,0,0,0,0,1.414Z" transform="translate(0 20.011) rotate(-90)" fill="rgb(${arrowsColorRgb})"/></svg>`;
    return css`
        
        ${shouldAddExtraSpaceBefore && css`
            margin-top: 30px;
        `}
        &:before {
            content: '';
            position: absolute;
            top: calc(-1 * var(${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR}) + 7px);
            left: 0;
            width: calc(100% - 2px);
            height: var(${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR});
            background-image: url('${backgroundUrl}');
            background-size: auto;
            background-position: center calc(50% - 4px);
            background-repeat: no-repeat;
            border-radius: var(${CSS_VAR_EXTRA_LEG_ROOM_SEAT_INDICATOR_RADIUS}, 7px);
            border-bottom: 0;
    `
}

export const ExtraLegSeatBox = styled(SeatBox)`
    background-color: ${props => props.theme.colors.darkTint};
    
    &:after {
        border-color: ${props => props.theme.colors.darkTint};
    }
    &:before {
        border: 1px dotted ${props => props.theme.colors.darkTint};
    }
    
    ${props => extraLegRoomCommonCss('3,71,82', props.$shouldPreserveExtraSpaceBefore)}
`

const ExtraLegSelectedSeatBox = styled(SeatSelectedBox)`
     
    &:before {
        border: 1px dotted ${props => props.theme.colors.tertiary};
    }
    
    ${props => extraLegRoomCommonCss('82,96,255', props.$shouldPreserveExtraSpaceBefore)}
`
const ExtraLegNotAvailableSeatBox = styled(SeatNotAvailableBox)`
    &:before {
        border: 1px dotted ${props => props.theme.colors.medium};
    }
    
    ${props => extraLegRoomCommonCss('146,148,156', props.$shouldPreserveExtraSpaceBefore)}
`

function getSeatBox(seat: ISeatViewModel | null) {
    if(!seat) {
        return MissingSeatBox;
    }
    if (seat.hasExtraLegroom) {
        if (seat.assignedTo) {
            return ExtraLegSelectedSeatBox;
        } else if (!seat.isAvailable) {
            return ExtraLegNotAvailableSeatBox;
        } else {
            return ExtraLegSeatBox;
        }
    }
    if (seat.assignedTo) {
        return SeatSelectedBox;
    } else if (!seat.isAvailable) {
        return SeatNotAvailableBox;
    }

    switch (seat.location) {
        case SeatsRowGroupType.first:
            return FrontSeatBox;
        case SeatsRowGroupType.front:
            return FrontSeatBox;
        case SeatsRowGroupType.rear:
            return RearSeatBox;
        default:
            return SeatBox;
    }
}

interface SeatComponentProps {
    seat: ISeatViewModel | null;
}

export const SeatComponent: React.FC<SeatComponentProps> = observer((props) => {
    const services = useServices();

    const Box = getSeatBox(props.seat);

    if(!props.seat) {
        return <Box/>;
    }

    const assignedTo = props.seat.assignedTo;

    const className = classNames('tst-seat-box', {
        'tst-seat-assigned': Boolean(assignedTo),
        'tst-seat-available': props.seat.isAvailable,
        'tst-seat-extra': props.seat.hasExtraLegroom,
    });


    const onClick = async () => {
        if (assignedTo) {
            await assignedTo.removeSeat();
        } else {
            await props.seat?.sellSeat();
        }

    }

    const shouldPreserveExtraSpaceBefore = props.seat.isOnEmergencyRow && props.seat.hasEmergencyRowInBeforeIt;
    
    if (assignedTo) {
        return (<Box id={props.seat.seatKey} key={props.seat.seatKey}  onClick={onClick} className={className} $shouldPreserveExtraSpaceBefore={shouldPreserveExtraSpaceBefore}>
            <PassengerInitialsBox>
                {assignedTo.passenger.getInitials()}
            </PassengerInitialsBox>
        </Box>);
    }

    if (!props.seat.isAvailable) {
        return (
            <Box id={props.seat.seatKey} key={props.seat.seatKey} className={className} $shouldPreserveExtraSpaceBefore={shouldPreserveExtraSpaceBefore} onClick={() => {
                /*
                The reason I added this empty click handler is because if I don't add it then
                for some strange reason when you click on an unavailable seat the next available seat next to it
                gets the click. So the result is that you click on an unavailable seat and the next seat is selected.
                */
            }}>
                <XIcon size={0.9} color={services.theme.currentTheme.colors.primaryContrast}  />
            </Box>);
    }



    const renderExtraLegArrow = () => {
        if (props.seat?.hasExtraLegroom) {
            return (
                <IconBox className={className} />
            )
        }
        return null;
    }

    return (
        <Box id={props.seat.seatKey} key={props.seat.seatKey} onClick={onClick} className={className} $shouldPreserveExtraSpaceBefore={shouldPreserveExtraSpaceBefore}>
            {renderExtraLegArrow()}
        </Box>
    );
});

