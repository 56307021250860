import React from "react";
import {observer} from "mobx-react";
import {SsrsSelectionComponentProps} from "./ssrs-selection.component.props";
import {useServices} from "../../../../hooks/use-services.hook";
import styled from "styled-components";
import {PassengerSsrsSelectionComponent} from "./passenger-ssrs-selection.component";
import {
    IMaturePassengerViewModel
} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {SameForAllPassengersToggleComponent} from "../same-for-all-passengers-toggle.component";

const SsrsSelectionBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
`

export const SsrsSelectionComponent: React.FC<SsrsSelectionComponentProps> = observer((props) => {
    const services = useServices();
    const passengers = props.passengers ?? services.booking.current.passengers;

    const renderSameForAllPassengers = (passenger: IMaturePassengerViewModel) => {
        if(passenger.passengerIndex !== 0
            || !props.ssrsAggregator.allowUseSameOptionsForAllPassengers
            || passengers.length === 0) {
            return null;
        }

        return (
            <SameForAllPassengersToggleComponent ssrsProvider={props.ssrsAggregator}/>
        )
    }

    const renderOnePassenger = (passenger: IMaturePassengerViewModel) => {
        return (
            <React.Fragment key={passenger.stablePassengerUniqueKey}>
                <PassengerSsrsSelectionComponent passenger={passenger} journey={props.journey} ssrsAggregator={props.ssrsAggregator}/>
                {renderSameForAllPassengers(passenger)}
            </React.Fragment>

        );
    }

    return (
        <SsrsSelectionBox>
            {passengers.map(renderOnePassenger)}
        </SsrsSelectionBox>
    );
});
