import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {PeopleIcon} from "../../../../../assets/svg/people.icon";
import {
    DiscountDiagonalNorthWestTagEmptyIcon
} from "../../../../../assets/svg/discount-diagonal-north-west-tag-empty.icon";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {InformationCircleIcon} from "../../../../../assets/svg/information-circle.icon";
import {showFreeSeatsNotificationDialog} from "../../dialogs/show-free-seats-notification-dialog";

const ContainerBox = styled.div`
    display: grid;
    grid-template-columns: 21px 1fr;
    align-items: center;
    column-gap: ${props => props.theme.spacing.spacing8};
    row-gap: ${props => props.theme.spacing.spacing8};
    background-color: ${props => props.theme.colors.tertiaryVeryLight};
    color: ${props => props.theme.colors.tertiaryShade};
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    line-height: 1;
    padding: ${props => props.theme.spacing.spacing12};
    border-radius: ${props => props.theme.border.defaultRadius};
    width: 100%;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        position: absolute;
        left: 50%;
        top: ${props => props.theme.spacing.spacing8};
        transform: translateX(-50%);
        z-index: 1;
        font-size: ${props => props.theme.fontSize.h6};
        width: calc(100% - 2 * ${props => props.theme.spacing.pageContentHorizontalPadding});
    }
    
`

const InformationIconBox = styled.div`
    cursor: pointer;
`

const ClickHereForDetailsBox = styled.span`
    font-size: ${props => props.theme.fontSize.subtitle2};
    text-decoration: underline;
    color: ${props => props.theme.colors.notesShade};
`



export const SeatMapMarketingMessageComponent:React.FC<{segmentSeatMap: ISegmentSeatMapEditorViewModel; className?: string}> = observer((props) => {
    const services = useServices();

    const onFreeOfChargeRowsClick = async () => {
        if(props.segmentSeatMap.currentPassengerSegment.benefitDedicatedFreeSeatsRows) {
            await showFreeSeatsNotificationDialog(services.booking.current);
        }

    }

    const renderContent = () => {
        if(props.segmentSeatMap.currentPassengerSegment.benefitDedicatedFreeSeatsRows) {
            return (
                <>
                    <InformationIconBox style={{cursor: "pointer"}}>
                        <InformationCircleIcon size={1.4}/>
                    </InformationIconBox>
                    <div style={{cursor: "pointer"}}>
                        <span>
                            {services.language.translate('Adults traveling with children benefit from free seats.')}
                        </span>
                        <span>  </span>
                        <ClickHereForDetailsBox style={{textDecoration: 'underline'}}>{services.language.translate('Click here for details')}</ClickHereForDetailsBox>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <DiscountDiagonalNorthWestTagEmptyIcon size={1.1}/>
                    <div>{services.language.translate('Now is the cheapest time to book!')}</div>
                    <PeopleIcon size={1}/>
                    <div>{services.language.translate('Sit next to your travel partner')}</div>
                </>
            );
        }
    }
    return (
        <ContainerBox className={props.className} onClick={onFreeOfChargeRowsClick}>
            {renderContent()}
        </ContainerBox>
    )
})