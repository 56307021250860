import {SegmentSeatMapEditorModel} from "./segment-seat-map-editor.model";
import {
    IDotRezCompartmentUnit,
    IDotRezSeatFee
} from "../../../dot-rez-api/data-contracts/booking/seat-map/seat-map.data-contracts";
import {SeatAvailabilityEnum} from "./seat-availability.enum";
import {
    DisabilProperty,
    ExitRowProperty, FreeForFamiliesProperty,
    LegRoomProperty,
    LocationProperty,
    NChildProperty,
    PETProperty,
    SeatProperty,
    WindowProperty,
    WingProperty
} from "./seat-properties";
import {computed, makeObservable, runInAction} from "mobx";
import {Price} from "../../../currency/price";
import {sumOfNonTaxesServiceCharges} from "../base-models/fees/service-charges.helpers";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";
import {ISeatModel} from "./seat.model.interface";
import {SeatsRowGroupType} from "./seats-row-group-type.enum";
import {scrollElementIntoViewSmooth} from "../../../../utils/scroll-element-into-view";

export class SeatModel implements ISeatModel {
    constructor(public readonly seatKey: string,
                public readonly rowNumber: number,
                private readonly seatMapEditor: SegmentSeatMapEditorModel) {
        makeObservable(this, {
            seatDotRezData: computed,
            price: computed,
            location: computed,
            isAvailable: computed,
            isCheckedIn: computed,
            isOnExitRow: computed,
            isOnFrontRow: computed,
            isNextToWindow: computed,
            isOnWing: computed,
            hasExtraLegroom: computed,
            allowsPet: computed,
            assignedTo: computed,
            allowsSpecialAssistance: computed,
            hasEmergencyRowInBeforeIt: computed,
            isOnEmergencyRow: computed
        })
    }

    get seatDotRezData(): IDotRezCompartmentUnit {
        return this.seatMapEditor.getSeatDotRezData(this.seatKey);
    }

    get seatNumber(): string {
        return this.seatDotRezData.designator;
    }

    get seatLetter(): string {
        return this.seatNumber.replace(this.rowNumber.toString(), "");
    }

    get isAvailable(): boolean {

        if(!this.seatMapEditor.shouldAllowSeatByFilters(this)) {
            return false;
        }

        const availability = this.seatDotRezData.availability;

        return (availability === SeatAvailabilityEnum.Open || availability === SeatAvailabilityEnum.ReservedForPnr)
                && !this.seatMapEditor.isSeatRestrictedForCurrentPassengerType(this)
                && !this.seatMapEditor.isSeatRestrictedBySelectedSsrs(this);
    }

    get isCheckedIn(): boolean {
        return this.seatDotRezData.availability === SeatAvailabilityEnum.CheckedIn;
    }

    get isOnExitRow(): boolean {
        return this._hasProperty(ExitRowProperty.True);
    }

    get isOnFrontRow(): boolean {
        return this._hasProperty(LocationProperty.Front);
    }

    get isNextToWindow(): boolean {
        return this._hasProperty(WindowProperty.True);
    }

    get isOnWing(): boolean {
        return this._hasProperty(WingProperty.True);
    }

    get hasExtraLegroom(): boolean {
        return this._hasProperty(LegRoomProperty.True);
    }

    get allowsPet(): boolean {
        return this._hasProperty(PETProperty.True);
    }

    get allowsSpecialAssistance(): boolean {
        return this._hasProperty(DisabilProperty.True);
    }

    get allowChild(): boolean {
        return !this._hasProperty(NChildProperty.True);
    }

    private _hasProperty(searchProperty: SeatProperty): boolean {
        return this.seatDotRezData.properties.some(prop => searchProperty.isMatch(prop.code, prop.value));
    }

    get price(): Price {

        let seatFees: IDotRezSeatFee[];

        if(this.assignedTo) {
            seatFees = this.seatMapEditor.getPassengerGroupFee(this.assignedTo.passenger.passengerKey, this.seatDotRezData.group)
        } else {
            seatFees = this.seatMapEditor.getCurrentPassengerGroupFees(this.seatDotRezData.group);
        }

        return this.seatMapEditor.createPrice(seatFees.sum(f => sumOfNonTaxesServiceCharges(f.serviceCharges)));
    }

    get assignedTo(): PassengerSegmentModel | null {
        if(this.seatDotRezData.availability === SeatAvailabilityEnum.Open) {
            return null;
        }

        return this.seatMapEditor.findSeatOwner(this.seatKey);
    }

    async sellSeat(): Promise<void> {
        await this.seatMapEditor.sellSeat(this);
    }

    makeItAvailable(): void {
        runInAction(() => {
            this.seatDotRezData.availability = SeatAvailabilityEnum.Open;
        });

    }

    holdItForThisSession(): void {
        runInAction(() => {
            this.seatDotRezData.availability = SeatAvailabilityEnum.HeldForThisSession;
        });
    }

    isAssigmentInProgress: boolean = false;

    get location(): SeatsRowGroupType {
        if(this.rowNumber === 1) {
            return SeatsRowGroupType.first;
        }

        if(this._hasProperty(ExitRowProperty.True)) {
            return SeatsRowGroupType.emergency;
        }

        const firstEmergencyRow = this.seatMapEditor.firstEmergencyRow;
        if(this._hasProperty(LocationProperty.Front) || this.rowNumber < firstEmergencyRow) {
            return SeatsRowGroupType.front;
        }

        return SeatsRowGroupType.rear;
    }

    get isOnEmergencyRow(): boolean {
        return this.location === SeatsRowGroupType.emergency;
    }

    get hasEmergencyRowInBeforeIt(): boolean {
        const previousRow = this.seatMapEditor.getRow(this.rowNumber - 1)
        if(previousRow) {
            return previousRow.isMiddleEmergencyRow
        }
        return false;
    }

    get isPurchasedOnCurrentSession(): boolean {
        if(!this.assignedTo) {
            return false;
        }

        return Boolean(this.assignedTo.seatFee?.isPurchasedOnCurrentSession);
    }

    scrollIntoView() {

        const el = this.seatMapEditor.segment.services.document.getElementById(this.seatKey);
        if(el) {
            scrollElementIntoViewSmooth(el)
        }
    }

    get isFreeOfCharge(): boolean {
        return this._hasProperty(FreeForFamiliesProperty.True);
    }
}
