import React from "react";
import {observer} from "mobx-react";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {StandardDialogPageComponent} from "../../../../components/modal/page/standard-dialog-page.component";
import {StandardDialogContentComponent} from "../../../../components/modal/content/standard-dialog-content.component";
import {PrimaryButton} from "../../../../basic-components/buttons/primary-button";
import styled from "styled-components";
import {
    IMaturePassengerViewModel
} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {PersonCircleFilledIcon} from "../../../../assets/svg/person-circle-filled.icon";
import {StandardDialogHeaderComponent} from "../../../../components/modal/header/standard-dialog-header.component";
import {StandardDialogFooterComponent} from "../../../../components/modal/footer/standard-dialog-footer.component";
import {useScreenMediaQueriesChecks} from "../../../../hooks/use-screen-media-queries-checks.hook";
import {
    IFreeOfChargeSeatRowsRange
} from "../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {DesignatorComponent, DesignatorDisplayMode} from "../../../../components/designator/designator.component";
import {useServices} from "../../../../hooks/use-services.hook";

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap16};
    width: 100%;
`

const DescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const PassengersWithFreeOfChargeSeatsSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: ${props => props.theme.spacing.spacing16};
    border-top: 1px solid ${props => props.theme.border.mainColor};
    gap: ${props => props.theme.spacing.flexGap8};
`

const PassengersContainerBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.spacing.flexGap8};
    width: 100%;
  
`

const PassengerNameBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing.flexGap8};
    align-items: center;
    font-size: ${props => props.theme.fontSize.h6};
`

const FreeOfChargeRowsSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: ${props => props.theme.spacing.spacing16};
    border-top: 1px solid ${props => props.theme.border.mainColor};
    gap: ${props => props.theme.spacing.flexGap8};
`

const SectionTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`

const FreeOfChargeRowsRangesBox = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: flex-start;
    column-gap: ${props => props.theme.spacing.flexGap20};
    row-gap: ${props => props.theme.spacing.flexGap8};
`

const DesignatorBox = styled(DesignatorComponent)`
    justify-content: flex-start;
    font-size: ${props => props.theme.fontSize.h5};
`

const RowsRangeBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    font-size: ${props => props.theme.fontSize.h5};
`

const RowsRangeHeaderBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.medium};
`

const FreeOfChargeRowsRangesComponent: React.FC<{ranges: IFreeOfChargeSeatRowsRange[]}> = observer((props) => {
    const services = useServices();

    if(props.ranges.length === 0) {
        return null;
    }

    const renderOneRange = (range: IFreeOfChargeSeatRowsRange)=> {
        return (
            <React.Fragment key={range.designator.uniqueKey}>
                <DesignatorBox displayMode={DesignatorDisplayMode.stationsCodeOnly} designator={range.designator}/>

                <RowsRangeBox>
                    <span>{range.firstRow}</span>
                    <span>-</span>
                    <span>{range.lastRow}</span>
                </RowsRangeBox>
            </React.Fragment>
        )
    }

    return (
        <FreeOfChargeRowsSectionBox>
            <SectionTitleBox>
                {services.language.translate('The rows of seats listed below are free:')}
            </SectionTitleBox>
            <FreeOfChargeRowsRangesBox>
                <RowsRangeHeaderBox>{services.language.translate('Flight')}</RowsRangeHeaderBox>
                <RowsRangeHeaderBox>{services.language.translate('Rows')}</RowsRangeHeaderBox>
                {props.ranges.map(renderOneRange)}
            </FreeOfChargeRowsRangesBox>
        </FreeOfChargeRowsSectionBox>
    );
})

const PassengersWithFreeOfChargeSeatsComponent: React.FC<{passengersWithFreeSeats: IMaturePassengerViewModel[]}> = observer((props) => {
    const services = useServices();


    const renderOnPassenger = (passenger: IMaturePassengerViewModel)=> {
        return (
            <PassengerNameBox key={passenger.stablePassengerUniqueKey}>
                <PersonCircleFilledIcon size={1.2}/>
                <span>
                    {passenger.getFullName()}
                </span>
            </PassengerNameBox>
        );
    }

    return (
        <PassengersWithFreeOfChargeSeatsSectionBox>
            <SectionTitleBox>
                {services.language.translate('The following passengers benefit from free seats:')}
            </SectionTitleBox>
            <PassengersContainerBox>
                {props.passengersWithFreeSeats.map(renderOnPassenger)}
            </PassengersContainerBox>
        </PassengersWithFreeOfChargeSeatsSectionBox>

    )
})


interface FreeSeatsNotificationDialogComponentProps {
    dialogHandler: IDialogConfirmationHandler;
    booking: IBookingViewModel;
}
export const FreeSeatsNotificationDialogComponent: React.FC<FreeSeatsNotificationDialogComponentProps> = observer((props) => {
    const services = useServices();
    const screenMediaQuery = useScreenMediaQueriesChecks();
    const onOkClick = () => {
        props.dialogHandler.accept();
    }


    const passengersWithFreeSeats = props.booking.passengers.filter(p => p.passengerType.benefitDedicatedFreeSeats);




    const renderButton = () => {
        return (
            <PrimaryButton onClick={onOkClick}>
                {services.language.translate('OK, I got it')}
            </PrimaryButton>
        );
    }

    const renderButtonInsideContent = () => {
        if(screenMediaQuery.smallScreen) {
            return renderButton();
        }

        return null;
    }

    const renderButtonInsideFooter = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <StandardDialogFooterComponent dialogHandler={props.dialogHandler}>
                {renderButton()}
            </StandardDialogFooterComponent>
        )
    }

    return (
        <StandardDialogPageComponent>
            <StandardDialogHeaderComponent dialogHandler={props.dialogHandler}
                                           title={services.language.translate('Travel with family')}
                                           renderTitleOnTheFirstRow={false}/>

            <StandardDialogContentComponent dialogHandler={props.dialogHandler}>
                <ContentBox>
                    <DescriptionBox>
                        {services.language.translate('When you choose the seats, place the children next to the adults.')}
                    </DescriptionBox>

                    <FreeOfChargeRowsRangesComponent ranges={props.booking.seatsMapsEditors.getFreeOfChargeSeatsRanges()}/>

                    <PassengersWithFreeOfChargeSeatsComponent passengersWithFreeSeats={passengersWithFreeSeats} />

                    {renderButtonInsideContent()}
                </ContentBox>

            </StandardDialogContentComponent>
            {renderButtonInsideFooter()}

        </StandardDialogPageComponent>
    )
})