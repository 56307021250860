import React from "react";
import {PaymentMethodCategoryEnum} from "../payment-method-category.enum";
import {MobileWalletPaymentMethodModel} from "../mobile-wallet-payment-method.model";
import {IPaypalPaymentMethodViewModel} from "./paypal-payment-method.view-model.interface";
import {
    PaymentSupportedOS,
    PaymentSupportedPlatformTypes
} from "../../../../../configuration/objects/payments-configuration.interface";

export class PaypalPaymentMethodModel extends MobileWalletPaymentMethodModel implements IPaypalPaymentMethodViewModel {

    get name(): string {
        return "PayPal";
    }

    renderIcon(): React.ReactElement | null {
        return null;
    }

    protected _getSupportedOS(): PaymentSupportedOS {
        return ['all'];
    }

    protected _getSupportedPlatformsTypes(): PaymentSupportedPlatformTypes {
        return ['all'];
    }

    protected async _canMakePayments(): Promise<boolean> {
        return Boolean(this.services.configuration.data.pspConfigs?.PayPal?.clientId);
    }

    get category(): PaymentMethodCategoryEnum {
        return PaymentMethodCategoryEnum.MobileWallet;
    }


    async startOrder(): Promise<string> {
        const result = await this.booking.paymentHandler.startBookingPayment({
            paymentMethodCode: this.code
        });
        return result.pspOrderToken ?? "";
    }

    async finalizeOrder(): Promise<void> {
        await this.booking.paymentHandler.finalizePspOrder();
    }

    async cancelOrder(): Promise<void> {
        await this.booking.paymentHandler.cancelPspOrder()
    }

}