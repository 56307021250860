import {IDotRezPassengerSegmentSsr} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {
    IDotRezPassengerSsrAvailability
} from "../../../dot-rez-api/data-contracts/booking/ssrs-availability/ssr-availability.data-contracts";
import {
    IDotRezSsrToAdd,
    IDotRezUpdateSsrsRequest
} from "../../../dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {Price} from "../../../currency/price";
import {IPassengerSsrEditorViewModel} from "./passenger-ssr-editor-view-model.interface";
import {ISsrAvailability} from "./availability/ssr-availability.iterface";
import {BookingModel} from "../booking.model";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {IJourneyViewModel} from "../journey/journey-view-model.interface";
import {NullableString} from "../../../../types/nullable-types";
import {ISeatModel} from "../seat-maps/seat.model.interface";

/**
 * Encapsulate the logic for selling a specific SSR for a passenger on a specific segment
 */
export class PassengerSegmentSsrEditorModel implements IPassengerSsrEditorViewModel {
    constructor(public readonly ssrType: ISsrType,
                public readonly passengerSegment: PassengerSegmentModel) {
        this._newQuantity = this.currentQuantity;
        if(passengerSegment.booking.isCommitted) {
            this._newQuantityWasSet = true;
        } else if(ssrType.shouldWriteQuantityChangeStatusInSessionStorage) {
            this._newQuantityWasSet = passengerSegment.segment.journey.storage.getItem(this._newQuantityWasSetLocalStorageKey) === 'true';
        }
        makeObservable<this, '_newQuantity' | '_newQuantityWasSet'>(this, {
            _newQuantity: observable.ref,
            _newQuantityWasSet: observable.ref,
            modificationBlockingReason: computed,
            markNewQuantityAsSet: action.bound,
            quantityIncludedInBundle: computed,
            virtualQuantityIncludedInBundle: computed,
            totalPrice: computed,
            totalPriceOnCurrentSession: computed
        });
    }

    private get _newQuantityWasSetLocalStorageKey(): string {
        return `${this.passengerSegment.passengerSegmentStableUniqueKey}_${this.ssrType.ssrCode}_${BookingSessionStorageKeys.newSsrQuantity}`;
    }

    get passengerKey(): string {
        return this.passengerSegment.passenger.passengerKey;
    }

    get stablePassengerUniqueKey(): string {
        return this.passengerSegment.passenger.stablePassengerUniqueKey;
    }

    get passengerFullName(): string {
        return this.passengerSegment.passengerFullName;
    }

    get passengerIndex(): number {
        return this.passengerSegment.passenger.passengerIndex;
    }

    get ssrAvailability(): ISsrAvailability {
        return this.passengerSegment.getSsrAvailability(this.ssrType);
    }

    get soldSsrs(): IDotRezPassengerSegmentSsr[] {
        return this.passengerSegment.getSoldUnitsForSsrType(this.ssrType);
    }

    get parentJourney(): IJourneyViewModel {
        return this.passengerSegment.segment.journey;
    }



    get virtualQuantityIncludedInBundle(): number {
        let q = 0;
        for(let virtualSsrInBundle of this.passengerSegment.getVirtualSsrsIncludedInBundle()) {
            if(virtualSsrInBundle.aliasFor?.ssrCode === this.ssrType.ssrCode) {
                q++;
            }
        }

        return q;
    }

    get quantityIncludedInBundleToDisplay(): number {
        return this.quantityIncludedInBundle + this.virtualQuantityIncludedInBundle;
    }

    get quantityIncludedInBundle(): number {
        return this.soldSsrs.filter(soldSsr => soldSsr.inBundle).length;
    }

    private get committedQuantity(): number {
        if(this.ssrType.shouldLockCommittedQuantity) {
            return this.soldSsrs.filter(soldSsr => this.passengerSegment.isSsrCommitted(soldSsr.ssrCode, soldSsr.ssrNumber)).length;
        } else {
            return 0;
        }
    }

    get quantityPurchasedOnPreviousSession(): number {
        return this.soldSsrs.filter(ssr => this.passengerSegment.isSsrPurchasedOnPreviousSession(ssr.ssrCode, ssr.ssrNumber)).length;
    }

    get quantityPurchasedOnCurrentSession(): number {
        return this.newQuantity - this.quantityPurchasedOnPreviousSession;
    }

    get limitPerPassenger(): number {
        return this.ssrType.computeLimitPerPassengerQuantity(this);
    }

    get minAllowedQuantity(): number {
        return Math.max(this.quantityIncludedInBundle, this.committedQuantity);
    }

    get maxAllowedQuantity(): number {
        return Math.min(this.limitPerPassenger, this.ssrAvailability.availableQuantity);
    }

    get maxAllowedQuantityReachedMessage(): NullableString {
        return this.ssrType.getMaxLimitPerPassengerReachedMessage(this);
    }

    get totalPrice(): Price {
        const fees = this.passengerSegment.passenger.fees.filter(f => f.ssrType?.ssrCode === this.ssrType.ssrCode && this.passengerSegment.segment.flightReference === f.flightReference);
        return Price.sumAll(fees.map(f => f.totalToDisplay), this.booking.createPrice(0));
    }

    get totalPriceOnCurrentSession(): Price {
        const fees = this.passengerSegment.passenger.fees.filter(f => f.isPurchasedOnCurrentSession && f.ssrType?.ssrCode === this.ssrType.ssrCode && this.passengerSegment.segment.flightReference === f.flightReference);
        return Price.sumAll(fees.map(f => f.totalToDisplay), this.booking.createPrice(0));
    }

    get currentQuantity(): number {
        return this.soldSsrs.length;
    }

    private _newQuantityWasSet: boolean = false;

    get newQuantityWasSet(): boolean {
        return this.newQuantity > 0 || this._newQuantityWasSet;
    }

    get newQuantityToDisplay(): number {
        return this.newQuantity + this.virtualQuantityIncludedInBundle;
    }

    private _newQuantity: number;
    get newQuantity(): number {
        return this._newQuantity;
    }

    set newQuantity(value: number) {
        if(!this.canBeModified) {
            return;
        }

        const diff = value - this._newQuantity;
        const isIncrease = diff > 0;

        if(!isIncrease && value < this.minAllowedQuantity) {
            return;
        }

        // This if is here for the following case:
        // If the remaining quantity available is consumed on this booking then dotREZ doesn't return any availability for that SSR. (AVIH is a good example because it has a small availability).
        // This means that the only quantity available on this booking is its own quantity.
        // Because there is no more SSR left he can only decrease the quantity.
        // So he can decrease the quantity for one passenger, but we can't let him increase the quantity for another passenger with the amount removed from the first passenger,
        // because in order to sell this quantity on another passenger we first must remove from booking state the quantity from this passenger,
        // so we will get an availability and a SSR key to be used for selling.
        // The steps to reproduce are like this (only on check-in flow):
        //      1. Go to extras page on on check-in flow
        //      2. Open Add pet dialog and put all "Pet in hold" (AVIH) quantity on one passenger
        //      3. Close the dialog
        //      4. Open the dialog again
        //      5. Remove one pet for for that passenger
        //      6. Try to add the pet to another passenger
        //      7. This if statement here will prevent this, because we can't put the free quantity on the other passenger until we close this dialog in order to free this pet also in dotREZ and to get an availability for it.
        if(!this.passengerSsrAvailability && value > this.currentQuantity) {
            return;
        }




        if(isIncrease && value > this.limitPerPassenger) {
            return;
        }

        //if diff is greater than zero it means that new quantity is added
        //so we need to make sure this quantity doesn't exceed the available quantity

        if(isIncrease && diff > this.ssrAvailability.availableQuantity) {
            value = this.ssrAvailability.availableQuantity;
        }

        runInAction(() => {
            this._newQuantity = value;
            this.markNewQuantityAsSet();
        });
    }

    markNewQuantityAsSet(): void {
        if(!this._newQuantityWasSet && this.ssrType.shouldWriteQuantityChangeStatusInSessionStorage) {
            this.passengerSegment.segment.journey.storage.setItem(this._newQuantityWasSetLocalStorageKey, 'true');
        }

        this._newQuantityWasSet = true;
    }

    markNewQuantityAsUnset(): void {
        this.passengerSegment.segment.journey.storage.removeItem(this._newQuantityWasSetLocalStorageKey);
    }

    getRestrictedSeats(): ISeatModel[] {
        if(this.passengerSegment.assignedSeat && this.ssrType.isSeatRestricted(this.passengerSegment.assignedSeat)) {
            return [this.passengerSegment.assignedSeat];
        } else {
            return [];
        }
    }

    get discountedMinPrice(): Price {
        if(!this.passengerSsrAvailability) {
            return this.passengerSegment.createPrice(0);
        }
        return this.passengerSegment.createPrice(this.passengerSsrAvailability.price);
    }

    private get booking(): BookingModel {
        return this.passengerSegment.segment.journey.booking;
    }

    get standardMinPrice(): Price {
        return this.booking.blueBenefits.computeSsrStandardPrice(this.ssrType, this.discountedMinPrice)
    }


    get availableQuantity(): number {
        return this.ssrAvailability.availableQuantity;
    }


    private get passengerSsrAvailability(): IDotRezPassengerSsrAvailability | null {
        return this.ssrAvailability.getPassengerAvailability(this.passengerSegment.passenger.passengerKey);
    }

    get hasAvailability(): boolean {
        return Boolean(this.passengerSsrAvailability);
    }

    private _getNotInBundleSsrs() {
        return this.soldSsrs.filter(ssr => !ssr.inBundle);
    }

    private _sortSsrsDescendingBySsrNumber(ssrsToSort: IDotRezPassengerSegmentSsr[]): IDotRezPassengerSegmentSsr[] {
        return ssrsToSort.sort((s1, s2) => s1.ssrNumber > s2.ssrNumber ? -1 : 1)
    }

    private _getPurchaseBlockingMessage(): NullableString {
        return this.ssrType.getPurchaseBlockingMessage(this.passengerSegment.segment.journey);
    }

    get canBeModified(): boolean {
        return !Boolean(this.modificationBlockingReason);
    }

    /**
     * This is the implementation of the ISsrEditorViewModel.canBeModifiedForAtLeastOneSegment
     */
    get canBeModifiedForAtLeastOneSegment(): boolean {
        return this.canBeModified;
    }


    get modificationBlockingReason(): NullableString {

        if(this.isCompletelyIncludedInBundle) {
            return this.passengerSegment.services.language.translate('Included in bundle');
        }

        //The order of these validation is important. This order establish the messages precedence in case there are multiple blocking reasons
        if(this.availableQuantity === 0 && this.newQuantity === 0) { //if newQuantity would be greater than zero then he can modify the quantity by decreasing it
            return this.passengerSegment.services.language.translate('Not available');
        }

        if(this.passengerSegment.isCheckedIn && this.ssrType.shouldBlockOnlineCheckIn) {
            return this.passengerSegment.services.language.translate('Cannot be purchased for checked-in passengers');
        }

        const purchaseBlockingMessage = this._getPurchaseBlockingMessage();
        if(purchaseBlockingMessage) {
            return purchaseBlockingMessage;
        }


        return null;
    }

    get isCompletelyIncludedInBundle(): boolean {
        return this.quantityIncludedInBundle > 0 && this.quantityIncludedInBundle >= this.maxAllowedQuantity;
    }


    createSsrToAddRequest(quantity: number): IDotRezSsrToAdd | null {
        const ssrKey = this.passengerSsrAvailability?.ssrKey;
        if(!ssrKey) {
            return null;
        }

        return {
            ssrKey: ssrKey,
            count: quantity,
            note: this.ssrType.getBookingNote(this.passengerSegment.booking)
        }
    }

    buildUpdateSsrsRequest(): IDotRezUpdateSsrsRequest {
        const newQuantity = this.newQuantity;
        if(newQuantity < this.currentQuantity) { //it means that quantity was decreased
            const quantityToRemove = this.currentQuantity - newQuantity;
            if(quantityToRemove === 0) {
                return {
                    ssrsToAdd: [],
                    ssrKeysToRemove: []
                };
            }
            //sort sold ssrs descending by ssrNumber
            const sortedSoldSsrs = this._sortSsrsDescendingBySsrNumber(this._getNotInBundleSsrs());
            return {
                ssrsToAdd: [],
                ssrKeysToRemove: sortedSoldSsrs.slice(0, quantityToRemove).map(ssr => ssr.ssrKey)
            }
        } else if(newQuantity > this.currentQuantity) {
            if(this.currentQuantity === this.ssrAvailability.limitPerPassenger) {
                return {
                    ssrKeysToRemove: [],
                    ssrsToAdd: []
                };
            }

            if(!this.passengerSsrAvailability) {
                return {
                    ssrsToAdd: [],
                    ssrKeysToRemove: []
                };
            }

            return {
                ssrKeysToRemove: [],
                ssrsToAdd: [
                    {
                        ssrKey: this.passengerSsrAvailability.ssrKey,
                        count: newQuantity - this.currentQuantity,
                        note: this.ssrType.getBookingNote(this.passengerSegment.booking)
                    }
                ]
            }
        }

        return {
            ssrsToAdd: [],
            ssrKeysToRemove: []
        };
    }


    getSsrKeysToRemove(quantityToRemove: number): string[] {
        quantityToRemove = Math.min(quantityToRemove, this.currentQuantity - this.quantityIncludedInBundle);

        const sortedSoldSsrs = this._sortSsrsDescendingBySsrNumber(this._getNotInBundleSsrs());

        return sortedSoldSsrs.slice(0, quantityToRemove).map(ssr => ssr.ssrKey);
    }

    revertSssQuantity(): void {
        this.newQuantity = this.currentQuantity;
    }
}
