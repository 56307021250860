import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {BookingFlowStepBase} from "../common/booking-flow-step.base";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";

export class BookingFlowPassengerDetailsStep extends BookingFlowStepBase  {

    get title(): string {
        return this.services.language.translate('Passengers');
    }

    get route() {
        return this.routes.passengersDetails;
    }

    async validate(): Promise<ValidationResultEnum> {
        return await this.booking.passengers.savePassengersDetails();
    }

    async next(): Promise<void> {

        await waitForBookingMutations(this.booking);

        if(ValidationResultEnum.Success !== await this.validate()) {
            return;
        }

        // this should have been done on onBlur, but has no logic to have it there as the validation is made at the end
        this.services.analytics.passengerDetailsEvents.logContactPresent(this.booking);
        this.services.analytics.passengerDetailsEvents.logBeginCheckout(this.booking);

        this.booking.tryChangePassengersTypes();

        await waitForBookingMutations(this.booking);

        this._activateStepByRoute(this.routes.seats);


    }
}
