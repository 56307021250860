import {DotRezBookingSession} from "../../session/booking-session/dot-rez-booking.session";
import {DotRezGraphQLQueryBuilder} from "../dot-rez-query-builder";
import {BOOKING_IN_STATE_DATA_QUERY} from "./booking-data-graphql.query";
import {BUNDLES_AVAILABILITY_QUERY} from "./bundles-availability-graphql.query";
import {SEAT_MAPS_QUERY} from "./seat-maps-graphql.query";
import {SSRS_AVAILABILITY_QUERY} from "./ssrs-availability-graphql.query";
import {IDotRezPartialBookingSessionData} from "../../data-contracts/booking/dot-rez-booking-session-data.interface";
import {IBookingStateQueryBuilder} from "./booking-state-query-builder.interface";
import {IDotRezGraphQLQuery} from "../dot-rez-graph-ql-query.interface";
import {IDotRezBookingData} from "../../data-contracts/booking/booking-state/booking-state.data-contracts";
import {LegStatusEnum} from "../../data-contracts/enums/leg-status.enum";
import {IDotRezJourneyCheckInRequirements} from "../../data-contracts/booking/check-in/check-in.data-contracts";
import {IServiceFactory} from "../../../service-factory.interface";
import {ChangeReasonCodeEnum} from "../../data-contracts/enums/change-reason-code.enum";
import {ISegmentDelay} from "../../../airline-webapi/responses/segments-delay.response";
import {DotRezException} from "../../session/dot-rez-exception";

export class BookingStateQueryBuilder implements IBookingStateQueryBuilder {
    constructor(private readonly dotRezSession: DotRezBookingSession,
                private readonly services: IServiceFactory,
                private initialBookingData?: IDotRezBookingData) {
    }

    useAllAvailabilities(): this {
        return this.useBookingData()
                    .useBundlesAvailability()
                    .useSsrsAvailability()
                    .useSeatMapsAvailability();
    }

    private _useBookingData = false;
    useBookingData(): this {
        this._useBookingData = true;
        return this;
    }

    skipQueryBookingData(): this {
        this._useBookingData = false;
        return this;
    }

    useInitialBookingData(initialBookingData: IDotRezBookingData): this {
        this.initialBookingData = initialBookingData;
        return this;
    }

    private get shouldUseBookingData(): boolean {
        if(this._useBookingData) {
            return true;
        }

        return (this._useCheckInRequirements || this._useSegmentsDisruptionDelays) && !Boolean(this.initialBookingData);
    }

    private _useBundlesAvailability = false;
    useBundlesAvailability() {
        this._useBundlesAvailability = true;
        return this;
    }

    private _useSeatMap = false;
    useSeatMapsAvailability() {
        this._useSeatMap = true;
        return this;
    }

    private _useSsrsAvailability = false;
    useSsrsAvailability() {
        this._useSsrsAvailability = true;
        return this;
    }

    private _useCheckInRequirements = false;
    useCheckInRequirements(): this {
        this._useCheckInRequirements = true;
        return this;
    }

    private _useSegmentsDisruptionDelays = false;
    useSegmentsDisruptionDelays(): this {
        this._useSegmentsDisruptionDelays = true;
        return this;
    }

    private _buildQueries(): IDotRezGraphQLQuery[] {
        const queryBuilder = new DotRezGraphQLQueryBuilder();
        if(this.shouldUseBookingData) {
            queryBuilder.addQuery('bookingData', BOOKING_IN_STATE_DATA_QUERY);
        }

        if(this._useBundlesAvailability) {
            queryBuilder.addQuery('bundlesAvailability', BUNDLES_AVAILABILITY_QUERY);
        }

        if(this._useSsrsAvailability) {
            queryBuilder.addQuery('ssrsAvailability', SSRS_AVAILABILITY_QUERY);
        }

        if(this._useSeatMap) {
            queryBuilder.addQuery('seatMaps', SEAT_MAPS_QUERY);
        }

        return queryBuilder.build();
    }

    private _isBookingCanceled(bookingData: IDotRezBookingData): boolean {
        return bookingData.journeys.some(j => j.segments.some(s => s.legs.some(l => l.legInfo.status === LegStatusEnum.Canceled)));
    }

    private async _getCheckInRequirements(bookingData: IDotRezBookingData | undefined): Promise<null | Record<string, IDotRezJourneyCheckInRequirements>> {
        if(!this._useCheckInRequirements) {
            return null;
        }

        if(!bookingData) {
            throw new Error('useCheckInRequirements can only work if you specify useBookingData or provide initialBookingData');
        }

        if(!bookingData.recordLocator) {
            return null;
        }

        if(this._isBookingCanceled(bookingData)) {
            return null;
        }

        return await this.dotRezSession.getCheckInRequirements(bookingData.journeys.map(j => j.journeyKey));
    }

    private _shouldRequestDelays(bookingData: IDotRezBookingData): boolean {
        if(!this.services.configuration.data.moveFlight.enabled) {
            return false;
        }
        if(!bookingData.recordLocator) {
            return false;
        }

        if(this._isBookingCanceled(bookingData)) {
            return false;
        }

        return bookingData.journeys.some(j => j.segments.some(s => {
            if(s.changeReasonCode === ChangeReasonCodeEnum.ScheduleChange) {
                return true;
            }

            return s.passengerSegment.some(ps => ps.value.timeChanged);
        }));
    }


    private async _getSegmentsDelays(bookingData: IDotRezBookingData | undefined): Promise<ISegmentDelay[]> {
        if(!this._useSegmentsDisruptionDelays) {
            return [];
        }

        if(!bookingData) {
            throw new Error('useSegmentsDisruptionDelays can only work if you specify useBookingData or provide initialBookingData');
        }

        if(!this._shouldRequestDelays(bookingData)) {
            return [];
        }

        try {
            const apiResponse = await this.services.airlineWebapi.getSegmentsDelays(bookingData.recordLocator!);
            return apiResponse.segmentsDelays;

        } catch (err) {
            this.services.logger.error(`Failed to read delays for legs: ${bookingData.recordLocator}`, err);
            return [];
        }
    }


    async getBookingState(): Promise<IDotRezPartialBookingSessionData> {
        let result: IDotRezPartialBookingSessionData = {};
        const queries = this._buildQueries();
        for(let query of queries) {
            try {
                const response =  await this.dotRezSession.executeGraphQLQuery<IDotRezPartialBookingSessionData>(query);
                result = response.data;
            } catch (err) {
                if(err instanceof DotRezException) {
                    const dotRezError = err as DotRezException;
                    if(dotRezError.hasErrorCode('nsk:Booking:NoBookingInState')) {
                        return result;
                    }
                }
                throw err;
            }

        }

        if(!result.bookingData) {
            if(this.initialBookingData) {
                result.bookingData = this.initialBookingData
            }
        }


        const journeysCheckInRequirements = await  this._getCheckInRequirements(result.bookingData);
        if(journeysCheckInRequirements) {
            result = {
                ...result,
                checkInRequirements: {
                    journeysRequirements: journeysCheckInRequirements
                }
            };
        }


        const segmentsDelays = await this._getSegmentsDelays(result.bookingData);

        return {
            ...result,
            segmentsDelays: segmentsDelays
        };
    }
}
