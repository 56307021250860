import {
    IDotRezJourney,
    IDotRezJourneyBreakdown,
    IDotRezSegment
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {FlightDesignatorModel} from "../designator/flight-designator.model";
import {SegmentModel} from "../segment/segment.model";
import {IDotRezSegmentSeatMap} from "../../../dot-rez-api/data-contracts/booking/seat-map/seat-map.data-contracts";
import {NullablePrice,  Price} from "../../../currency/price";
import {IServiceFactory} from "../../../service-factory.interface";
import {IJourneyViewModel} from "./journey-view-model.interface";
import {BookingModel} from "../booking.model";
import {BundleModel} from "../bundle/bundle.model";
import {MaturePassengerModel} from "../passenger/mature-passenger.model";
import {JourneySessionStorage} from "./journey-session-storage";
import {BundlePriceModel} from "../bundle/bundle-price.model";
import {BundleIncludedSsrModel} from "../bundle/bundle-included-ssr.model";
import {computed, makeObservable, observable, runInAction} from "mobx";
import {PassengerSegmentModel} from "../passenger-segment/passenger-segment.model";
import {
    CheckInRestrictionsEnumApi
} from "../../../dot-rez-api/data-contracts/enums/check-in-restrictions.enum";
import {IFareToSell} from "../booking-view-model.interface";
import {JourneyAllBagsBucketModel} from "../ssrs/bags/journey-all-bags-bucket.model";
import {IDotRezJourneyCheckInRequirements} from "../../../dot-rez-api/data-contracts/booking/check-in/check-in.data-contracts";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";
import {Check} from "../../../../types/type-checking";
import {JourneyBaseModel} from "../base-models/journey/journey-base.model";
import {LegModel} from "../segment/leg.model";
import {IPassengerSsrTypesCount, ISsrTypeCount} from "../ssrs/ssr-type-count.interface";
import {IDotRezSsrToAdd} from "../../../dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {JourneySnapshotModel} from "../snapshots/journey/journey-snapshot.model";
import {IUnresolvedPassengerSegmentSeat} from "./unresolved-passenger-segment-seat.interface";
import {IUnresolvedSsrs} from "./unresolved-ssrs";
import {NullableString} from "../../../../types/nullable-types";
import {FeeTypeEnum} from "../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {sumOfNonTaxesServiceCharges} from "../base-models/fees/service-charges.helpers";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {IJourneySsrsBucket, IJourneySsrsBucketOptions} from "../ssrs/journey-ssrs-bucket.interface";
import {composeJourneySsrsBucketIdentifier, JourneySsrsBucketModel} from "../ssrs/journey-ssrs-bucket.model";
import {SeatModel} from "../seat-maps/seat.model";

export interface ISeatToResellOnFlightChange {
    passengerKey: string;
    seat: SeatModel;
}

export class JourneyModel extends JourneyBaseModel<LegModel, SegmentModel> implements IJourneyViewModel {
    constructor(public readonly journeyKey: string,
                public readonly booking: BookingModel,
                private readonly initialJourneySnapshot: JourneySnapshotModel | null) {

        super();

        this.storage = new JourneySessionStorage(this);
        // segments must be initialized before all the other, because all the other are using the segments
        this.segments = this.journeyDotRezData.segments.map((segment) => new SegmentModel(this, segment.segmentKey));



        const isJourneySelectedValue = this.storage.getItem(BookingSessionStorageKeys.isJourneySelected);
        if(!Check.isEmpty(isJourneySelectedValue)) {
            this._isJourneySelected = (isJourneySelectedValue === 'true');
        }

        makeObservable<this, '_isJourneySelected'>(this, {
            _isJourneySelected: observable.ref,
            bundlesAvailability: computed,
            selectedBundle: computed
        });
    }


    get hasBlueBenefits(): boolean {
        return this.booking.blueBenefits.isValidSubscription;
    }

    private readonly _ssrsBuckets: Record<string, IJourneySsrsBucket> = {};

    createSsrsBucket(options: IJourneySsrsBucketOptions): IJourneySsrsBucket {
        const identifier = composeJourneySsrsBucketIdentifier(options.ssrTypes, options.onlyForPassengers());
        if(!this._ssrsBuckets[identifier]) {
            this._ssrsBuckets[identifier] = new JourneySsrsBucketModel(this, options);
        }
        return this._ssrsBuckets[identifier];
    }

    get allSsrsBuckets(): IJourneySsrsBucket[] {
        return Object.values(this._ssrsBuckets);
    }

    public get bundlesAvailability(): BundleModel[] {
        const availableBundles: BundleModel[] = [];
        for (let availableBundle of this.booking.bundlesAvailability) {
            const availableBundlePrices = availableBundle.pricesByJourney.toDictionary(item => item.key)[this.journeyKey].value.prices;
            const bundleType = this.booking.services.bundleTypes.getBundleType(availableBundle.bundleCode)
            const bundle = new BundleModel(bundleType, this,
                availableBundlePrices.map(bundlePrice => {

                    const includedSsrsTypes = bundleType.appendVirtualSsrs(bundlePrice.includedSsrs.map(s => s.ssrCode))
                                                        .map(ssrCode => this.booking.getSSR(ssrCode));

                    const includedSsrsAliases: Record<string, ISsrType[]> = {};

                    for(let includedSsrType of includedSsrsTypes) {
                        if(includedSsrType.aliasFor) {
                            if(!includedSsrsAliases[includedSsrType.aliasFor.ssrCode]) {
                                includedSsrsAliases[includedSsrType.aliasFor.ssrCode] = [];
                            }

                            includedSsrsAliases[includedSsrType.aliasFor.ssrCode].push(includedSsrType)
                        }
                    }

                    return new BundlePriceModel(this.createPrice(bundlePrice.totalPrice),
                        bundlePrice.passengerType,
                        includedSsrsTypes.map(ssrType => new BundleIncludedSsrModel(ssrType, includedSsrsAliases[ssrType.ssrCode] ?? [])))
                }));
            availableBundles.push(bundle);
        }
        return availableBundles;
    }

    private _allBags: JourneyAllBagsBucketModel | null = null;

    get allBags(): JourneyAllBagsBucketModel {
        if(!this._allBags) {
            this._allBags = new JourneyAllBagsBucketModel(this);
            this._ssrsBuckets[this._allBags.bucketIdentifier] = this._allBags;
        }
        return this._allBags;
    }

    get checkInBagsCount(): number {
        let counter = 0;
        const bagsSsrTypes = this.services.ssrTypes.getCheckInBagsThatCanBeSoldIndividually();
        this.getAllPassengersSegments().forEach(ps => bagsSsrTypes.forEach(ssrType => counter += ps.getSsr(ssrType).currentQuantity))
        return counter;
    }

    get priorityBoardingCount(): number {
        return this.getAllPassengersSegments().sum(ps => ps.getSsr(this.services.ssrTypes.PBRD).currentQuantity);
    }


    readonly storage: JourneySessionStorage;

    get services(): IServiceFactory {
        return this.booking.services;
    }

    get selectedBundle(): BundleModel | null {
        const bundleCode = this.initialJourneySnapshot?.currentBundleCode || this.currentBundleCode;
        if (!bundleCode) {
            return null;
        }

        return this.bundlesAvailability.filter(b => b.bundleCode === bundleCode)[0] || null;
    }

    get journeyDotRezData(): IDotRezJourney {
        const journeyData = this.booking.bookingData.journeys.find(j => j.journeyKey === this.journeyKey);
        if (!journeyData) {
            throw new Error(`There is no journey with key ${this.journeyKey}`);
        }
        return journeyData;
    }

    get journeyDotRezBreakdown(): IDotRezJourneyBreakdown {
        const journeyBreakdown = this.booking.bookingData.breakdown.journeys.find(j => j.key === this.journeyKey);
        if(!journeyBreakdown) {
            throw new Error(`Journey ${this.journeyKey} doesn't have a breakdown`);
        }

        return journeyBreakdown.value;
    }

    readonly segments: SegmentModel[];


    get minSeatsFee(): Price {
        return Price.min(this.segments.map(segment => segment.minSeatsFee));
    }


    getSegmentDotRezData(segmentKey: string): IDotRezSegment {
        return this.journeyDotRezData.segments.filter(s => s.segmentKey === segmentKey)[0];
    }

    private _findSeatMapData(designator: FlightDesignatorModel): IDotRezSegmentSeatMap | undefined {
        return this.booking.bookingSessionData.seatMaps.filter(sm => designator.isMatch(sm.seatMap.departureStation, sm.seatMap.arrivalStation))[0];
    }
    isSeatMapLoaded(designator: FlightDesignatorModel): boolean {
        return Boolean(this._findSeatMapData(designator));
    }
    getSeatMap(designator: FlightDesignatorModel): IDotRezSegmentSeatMap {
        const seatMapData = this._findSeatMapData(designator);
        if (!seatMapData) {
            throw new Error(`There is no seat map for segment ${designator.toString()}`);
        }
        return seatMapData;
    }

    translate(key: string): string {
        return this.booking.services.language.translate(key);
    }

    getPassenger(passengerKey: string): MaturePassengerModel {
        return this.booking.passengers.getByPassengerKey(passengerKey);
    }

    createPrice(value: number): Price {
        return this.booking.createPrice(value);
    }

    getAllSoldSsrKeysWithoutBundleIncluded(): string[] {
        let result: string[] = [];

        this.segments.forEach(segment => {
            result = [...result, ...segment.getAllSoldSsrKeysWithoutBundleIncluded()]
        });

        return result;
    }

    getSsrKeysToRemoveOnBundleChange(newBundle: BundleModel): string[] {
        let ssrKeys: string[] = [];

        this.segments.forEach(segment => {
            ssrKeys = [...ssrKeys, ...segment.getSsrKeysToRemoveOnBundleChange(newBundle)]
        });

        return ssrKeys;
    }

    onBundleUpdated() {
        this.segments.forEach(s => s.onBundleUpdated());
    }

    get isOpenForCheckIn(): boolean {
        if(this.services.configuration.envConfigs.byPassCheckInRestrictions) {
            return true;
        }
        if (!this.booking.checkInRequirements) {
            return false;
        }

        if (!this.isOnlineCheckInAllowed) {
            return false;
        }
        const restrictions = this.booking.checkInRequirements.journeysRequirements[this.journeyKey]?.restrictions || [];

        return 0 === restrictions.filter(r => r !== CheckInRestrictionsEnumApi.NotPaidInFull).length;

    }

    getCheckInRequirements(): IDotRezJourneyCheckInRequirements | null {
        const bookingCheckInInfo = this.booking.checkInRequirements;
        if (bookingCheckInInfo?.journeysRequirements) {
            return bookingCheckInInfo.journeysRequirements[this.journeyKey] || null;
        }

        return null;
    }

    get isOnlineCheckInAllowed(): boolean {
        if(this.services.configuration.envConfigs.byPassCheckInRestrictions) {
            return true;
        }
        return this.segments.all(segment => segment.isOnlineCheckInAllowed);
    }

    getFirstUserIndependentCheckInBlockingRestrictionsMessage(): NullableString {
        for(let s of this.segments) {
            const restrictions = s.getUserIndependentCheckInBlockingRestrictions();
            if(restrictions.length > 0) {
                return restrictions[0].description;
            }
        }

        return null;
    }

    private _isJourneySelected: boolean = true;
    get isJourneySelected(): boolean {


        if(this.isFutureJourney) {
            return this._isJourneySelected;
        }


        // a journey should never be selectable if it is a past journey
        return false;
    }

    set isJourneySelected(value: boolean) {
        if(this.isCanceled) {
            return; //Changing the selection status for a canceled journey is not allowed. A canceled journey must always stay selected unless is a past journey
        }
        runInAction(() => {
            this._isJourneySelected = value;
            this.storage.setItem(BookingSessionStorageKeys.isJourneySelected, value ? 'true' : 'false');
        });
    }

    getAllPassengersSegments(): PassengerSegmentModel[] {
        let all: PassengerSegmentModel[] = [];
        this.segments.forEach(segment => {
            all = [...all, ...segment.passengers];
        });
        return all;
    }

    get areAllPassengersCheckedIn(): boolean {
        return this.segments.all(segment => segment.passengers.all(passenger => passenger.isCheckedIn));
    }

    get allPassengersHaveUserIndependentOnlineCheckInBlockingRestrictions(): boolean {
        return this.segments.all(segment => segment.passengers.all(passenger => passenger.hasUserIndependentOnlineCheckInBlockingRestrictions));
    }

    get allPassengersHaveSeats(): boolean {
        return this.segments.all(segment => segment.passengers.all(passenger => Boolean(passenger.assignedSeat)));
    }

    markBundleAsSelected(bundleCode: string): void {
        this.segments.forEach(s => s.markBundleAsSelected(bundleCode));
    }

    wasSoldFromThisFare(fareToSell: IFareToSell): boolean {
        return this.journeyKey === fareToSell.journeyKey && this.hasBlueBenefits === fareToSell.withBlueBenefits;
    }

    get analyticsFormattedDepartureDate(): string {
        return this.services.time.formatYYY_MM_DD(this.designator.departureDate);
    }

    getAnalyticsUniqueId(): string {
        return this.segments.map(s => `${s.identifier.carrierCode} ${s.identifier.identifier} ${this.services.time.formatDD_MM_YYY(s.designator.departureDate)} ${this.services.time.formatHHmm(s.designator.departureDate)}`).join(' ');
    }

    get analyticsFormattedStationCodes() {
        return `${this.designator.origin.stationCode}:${this.designator.destination.stationCode}`
    }

    get analyticsFlightId(): string {
        return `${this.segments.map(segment => segment.identifier.fullIdentifierCode()).join(",")}`;
    }

    get analyticsFareClassOfService(): string {
        return this.segments[0].classOfService;
    }

    getSeatsToResellOnFlightChange(): ISeatToResellOnFlightChange[] {
        const initialSeatsGroupedBySegmentIndex = (this.initialJourneySnapshot?.getPassengersSeats() || []).groupByKey(seat => seat.segmentIndex.toString());
        if(Check.isEmpty(initialSeatsGroupedBySegmentIndex)) {
            return [];
        }

        const result: ISeatToResellOnFlightChange[] = [];

        const previousSegmentsIndexes = Object.keys(initialSeatsGroupedBySegmentIndex).map(index => parseInt(index));

        const tryMatchInitialSegmentIndex = (currentSegmentIndex: number): number => {

            return Math.max(...previousSegmentsIndexes.filter(index => index <= currentSegmentIndex));
        }

        for(let i = 0; i < this.segments.length; i++) {
            const initialPassengersSeats = initialSeatsGroupedBySegmentIndex[tryMatchInitialSegmentIndex(i)].toDictionary(seat => seat.passengerKey);
            const segment = this.segments[i];
            for(let passengerSegment of segment.passengers) {
                const initialSeatNumber = initialPassengersSeats[passengerSegment.passenger.passengerKey]?.seatNumber;
                if(initialSeatNumber) {
                    const seatModel = segment.seatMapEditor.findSeatByNumber(initialSeatNumber);
                    if(seatModel?.isAvailable) {
                        result.push({
                            passengerKey: passengerSegment.passenger.passengerKey,
                            seat: seatModel
                        });
                    }
                }
            }
        }

        return result;
    }

    getSsrsToResellOnFlightChange(): IDotRezSsrToAdd[] {
        if(!this.initialJourneySnapshot) {
            return [];
        }

        const ssrsToAdd: IDotRezSsrToAdd[] = [];

        const initialSsrsPerPassengers: IPassengerSsrTypesCount[] = this.initialJourneySnapshot.countPassengersSsrs(false);

        const currentPassengersSegments = this.getAllPassengersSegments().groupByKey(ps => ps.passenger.passengerKey);
        for(let initialPassengerSsrs of initialSsrsPerPassengers) {
            const currentPassengerSegments = currentPassengersSegments[initialPassengerSsrs.passengerKey];
            for(let ssrCount of initialPassengerSsrs.ssrsCount) {
                if(ssrCount.ssrType.shouldBeSoldPerSegment) {
                    currentPassengerSegments.forEach(ps => {
                        const ssrEditor = ps.getSsr(ssrCount.ssrType);
                        const addRequest = ssrEditor.createSsrToAddRequest(ssrCount.count);
                        if(addRequest) {
                            ssrsToAdd.push(addRequest);
                        }
                    });
                } else {
                    const addRequest = currentPassengerSegments[0].getSsr(ssrCount.ssrType).createSsrToAddRequest(ssrCount.count);
                    if(addRequest) {
                        ssrsToAdd.push(addRequest)
                    }
                }
            }
        }

        return ssrsToAdd;
    }


    get hasChangedSegments(): boolean {
        if(!this.initialJourneySnapshot) {
            return false;
        }
        const initialSegmentsDictionary = this.initialJourneySnapshot.segments.toDictionary(s => s.segmentKey);
        return this.segments.some(s => !initialSegmentsDictionary[s.segmentKey]);
    }


    get shouldConsumeFlex(): boolean {
        return this.hasChangedSegments && !this.booking.initialBookingSnapshot.canMoveDisruptedFlights;
    }

    get flexUsageBlockingReason(): NullableString {
        return this.initialJourneySnapshot?.flexUsageBlockingReason || null;
    }

    /**
     * Returns the SSR keys that should be removed when consuming FLEX SSR
     */
    getFlexSsrKeysToRemove(): string[] {

        if(!this.shouldConsumeFlex) {
            return [];
        }

        const flexSsrKeys: string[] = [];

        for(let segment of this.segments) {
            for(let passengerSegment of segment.passengers) {
                for(let ssr of (passengerSegment.passengerSegmentDotRezData.ssrs ?? [])) {
                    if(ssr.ssrCode === this.services.ssrTypes.FLX.ssrCode && !ssr.inBundle) {
                        flexSsrKeys.push(ssr.ssrKey);
                        break; // The break here is to consume only one FLEX. At this time should be only one but who knows in the future the business might decide to allow more.
                    }
                }
            }
        }

        return flexSsrKeys;
    }

    /**
     * Returns the FLEX SSR fee amount on the initial journey that needs to be added as a special fee to the booking when consuming FLEX
     */
    getFlexFeeAmountToConsume(): number {
        if(!this.initialJourneySnapshot) {
            return 0;
        }

        if(!this.shouldConsumeFlex) {
            return 0;
        }

        const initialSegmentsFlightReferences = this.initialJourneySnapshot.segments.map(s => s.flightReference);

        let totalFlexFeeAmount = 0;
        const flexSsrCode = this.services.ssrTypes.FLX.ssrCode;
        for(let flightReference of initialSegmentsFlightReferences) {
            for(let passenger of this.booking.initialBookingSnapshot.bookingData.passengers) {
                for(let fee of passenger.value.fees) {
                    if(fee.type === FeeTypeEnum.SsrFee && fee.ssrCode === flexSsrCode && fee.flightReference === flightReference) {
                        totalFlexFeeAmount += sumOfNonTaxesServiceCharges(fee.serviceCharges);
                    }
                }
            }
        }

        return totalFlexFeeAmount;

    }

    getUnresolvedSeats(): IUnresolvedPassengerSegmentSeat[] {
        const initialSeatsGroupedBySegmentIndex = (this.initialJourneySnapshot?.getPassengersSeats() || []).groupByKey(seat => seat.segmentIndex.toString());
        if(Check.isEmpty(initialSeatsGroupedBySegmentIndex)) {
            return [];
        }

        const previousSegmentsIndexes = Object.keys(initialSeatsGroupedBySegmentIndex).map(index => parseInt(index));

        const tryMatchInitialSegmentIndex = (currentSegmentIndex: number): number => {
            return Math.max(...previousSegmentsIndexes.filter(index => index <= currentSegmentIndex));
        }

        const result: IUnresolvedPassengerSegmentSeat[] = [];
        for(let i = 0; i < this.segments.length; i++) {
            const initialPassengersSeats = initialSeatsGroupedBySegmentIndex[tryMatchInitialSegmentIndex(i)].toDictionary(seat => seat.passengerKey);
            const segment = this.segments[i];
            for(let passengerSegment of segment.passengers) {
                if(passengerSegment.assignedSeat) {
                    continue;
                }

                const initialSeatNumber = initialPassengersSeats[passengerSegment.passenger.passengerKey]?.seatNumber;
                if(!initialSeatNumber) {
                    continue;
                }

                result.push({
                    passengerSegment: passengerSegment,
                    seatNumber: initialSeatNumber
                });
            }
        }

        return result;
    }

    getUnresolvedSsrs(): IUnresolvedSsrs {
        if(!this.initialJourneySnapshot) {
            return {
                designator: this.designator,
                ssrs: []
            };
        }

        const initialSsrs = this.initialJourneySnapshot.countAllSsrs();
        const currentSsrs = this.countAllSsrs().toDictionary(ssrCount => ssrCount.ssrType.ssrCode);

        const result: ISsrTypeCount[] = [];

        for(let ssrCount of initialSsrs) {
            if(this.shouldConsumeFlex && ssrCount.ssrType.ssrCode === this.services.ssrTypes.FLX.ssrCode) {
                continue;
            }

            const diff = ssrCount.count - (currentSsrs[ssrCount.ssrType.ssrCode]?.count || 0);
            if(diff > 0) {
                result.push({
                    ssrType: ssrCount.ssrType,
                    count: diff
                });
            }
        }

        return {
            designator: this.designator,
            ssrs: result
        };
    }

    get initialJourneyDateChangeBlockingReason(): NullableString {
        return this.initialJourneySnapshot?.dateChangeBlockingReason || null;
    }

    get initialJourneyAllowsDateChange(): boolean {
        return !Boolean(this.initialJourneyDateChangeBlockingReason);
    }

    get initialBundlePrice(): Price | null {
        const amount = this.initialJourneySnapshot?.currentBundleAmount;
        if(Check.isNullOrUndefined(amount)) {
            return null;
        }

        return this.createPrice(amount)
    }

    get currentBundlePrice(): Price {
        return this.createPrice(this.currentBundleAmount);
    }


    getInitialFare(segmentIndex: number, passengerKey: string) {
        return this.initialJourneySnapshot?.shoppingCart.getInitialFare(segmentIndex, passengerKey) || null;
    }

    getInitialSeatPrice(segmentIndex: number, passengerKey: string): NullablePrice {
        return this.initialJourneySnapshot?.shoppingCart.getInitialSeatPrice(segmentIndex, passengerKey) || null;
    }

    getInitialSeatNumber(segmentIndex: number, passengerKey: string): NullableString {
        return this.initialJourneySnapshot?.shoppingCart.getInitialSeatNumber(segmentIndex, passengerKey) || null;
    }



    getInitialPurchasedSsrPrice(segmentIndex: number, passengerKey: string, ssrType: ISsrType, ssrIndex: number): NullablePrice {
        return this.initialJourneySnapshot?.shoppingCart.getPurchasedSsrPrice(segmentIndex, passengerKey, ssrType.ssrCode, ssrIndex) || null;
    }

    getInitialInBundleSsrPrice(segmentIndex: number, passengerKey: string, ssrType: ISsrType, ssrIndex: number): NullablePrice {
        return this.initialJourneySnapshot?.shoppingCart.getInBundleSsrPrice(segmentIndex, passengerKey, ssrType.ssrCode, ssrIndex) || null;
    }

    getInitialOtherFeePrice(segmentIndex: number, passengerKey: string, feeType: FeeTypeEnum, feeIndex: number): NullablePrice {
        return this.initialJourneySnapshot?.shoppingCart.getOtherFeePrice(segmentIndex, passengerKey, feeType, feeIndex) || null;
    }

    getInitialInfantPrice(segmentIndex: number, passengerKey: string): NullablePrice {
        return this.initialJourneySnapshot?.shoppingCart.getInfantPrice(segmentIndex, passengerKey) || null;
    }

    async removeAllSeats(): Promise<void> {
        for(let segment of this.segments) {
            for(let passengerSegment of segment.passengers) {
                if(passengerSegment.assignedSeat) {
                    await this.booking.session.removeSeat({
                        passengerKey: passengerSegment.passenger.passengerKey,
                        seatKey: passengerSegment.assignedSeat.seatKey
                    });
                }
            }
        }
    }

    markQuantityAsUnsetForAllSoldSsrs(): void {
        for(let segment of this.segments) {
            for(let passengerSegment of segment.passengers) {
                for(let soldSsr of passengerSegment.getSoldSsrsEditors()) {
                    soldSsr.markNewQuantityAsUnset();
                }
            }
        }
    }
}
