import {IDotRezSellBundleRequest} from "../../data-contracts/requests/booking/sell-bundle.request";
import {
    IDotRezApiPayment,
    IDotRezBookingData,
    IDotRezContact, IDotRezPassengerAddressApi,
    IDotRezPassengerTravelDocument
} from "../../data-contracts/booking/booking-state/booking-state.data-contracts";
import {IDotRezUpdatePassengerDetailsRequest} from "../../data-contracts/requests/booking/update-passenger-details.request";
import {IDotRezSsrToAdd, IDotRezUpdateSsrsRequest} from "../../data-contracts/requests/booking/update-ssrs.request";
import {DotRezGraphQLMutationBuilder} from "../../graph-ql/dot-rez-query-builder";
import {
    removeSsrsGraphQLMutations,
} from "../../graph-ql/mutations/ssrs-remove-graphql.mutation";
import {sellBundleGraphqlMutation} from "../../graph-ql/mutations/sell-bundle-graphql.mutation";
import {cancelJourneyGraphqlMutation} from "../../graph-ql/mutations/cancel-journey-graphql.mutation";
import {BookingStateQueryBuilder} from "../../graph-ql/queries/booking-state-query-builder";
import {removeSeatsGraphqlMutation} from "../../graph-ql/mutations/remove-seats-graphql.mutation";
import {IDotRezRemoveSeatRequest} from "../../data-contracts/requests/booking/remove-seat.request";
import {IDotRezCheckInSegmentPassengersRequest} from "../../data-contracts/requests/booking/check-in-segment-passengers.request";
import {checkInGraphqlMutation} from "../../graph-ql/mutations/check-in-graphql.mutation";
import {IDotRezPassengerBoardingPass} from "../../data-contracts/booking/boarding-pass/boarding-passe.data-contracts";
import {journeyBoardingPassesQuery} from "../../graph-ql/queries/boarding-passes-data-graphql.query";
import {IDotRezJourneyBoardingPassesRequest} from "../../data-contracts/requests/booking/boarding-passes.request";
import {commitBookingGraphqlMutation} from "../../graph-ql/mutations/commit-booking-graphql.mutation";
import {sellJourneysGraphqlMutation} from "../../graph-ql/mutations/sell-journeys-graphql.mutation";
import {DotRezSessionBase} from "../dot-rez-session-base";
import {IDotRezRetrieveBookingByEmailRequest} from "../../data-contracts/requests/booking/retrieve-booking-by-email.request";
import {
    bringBookingInStateByEmailQuery,
    bringBookingInStateByLastNameQuery,
} from "../../graph-ql/queries/booking-data-graphql.query";
import {IDotRezRetrieveBookingByLastNameRequest} from "../../data-contracts/requests/booking/retrieve-booking-by-last-name.request";
import {ICommitBookingOptions, IDotRezBookingSession} from "./dot-rez-booking.session.interface";
import {IDotRezValidateVoucherResponse} from "../../data-contracts/responses/booking/validate-voucher.response";
import {ValidateVoucherResultEnum} from "../../data-contracts/enums/validate-voucher-result.enum";
import {IDotRezValidateVoucherRequest} from "../../data-contracts/requests/booking/validate-voucher.request";
import {
    DotRezPaymentCode,
    DotRezPaymentStatusApiEnum,
    PaymentFieldsNamesEnum
} from "../../data-contracts/enums/payment-type.enum";
import {IDotRezAddVoucherToBookingRequest} from "../../data-contracts/requests/booking/add-voucher-to-booking.request";
import {IDotRezSellTripRequestWrapper} from "../../data-contracts/requests/booking/sell-trip.request";
import {IDotRezVoucherInfo} from "../../data-contracts/booking/vouchers/vouchers.data-contracts";
import {Check} from "../../../../types/type-checking";
import {IServiceFactory} from "../../../service-factory.interface";
import {IDotRezSessionTimerModel} from "../session-timer/dot-rez-session-timer.interface";
import {makeObservable, observable} from "mobx";
import {IDotRezChangePassengerNameRequest} from "../../data-contracts/requests/booking/change-passenger-name.request";
import {genderToNumber} from "../../data-contracts/enums/gender.enum";
import {IDotRezSavePassengerTravelDocumentResponse} from "../../data-contracts/responses/booking/save-passenger-travel-document.response";
import {IDotRezUpdateInfantDetailsRequest} from "../../data-contracts/requests/booking/update-infant-details.request";
import {IBookingStateQueryBuilder} from "../../graph-ql/queries/booking-state-query-builder.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IDotRezJourneyCheckInRequirements} from "../../data-contracts/booking/check-in/check-in.data-contracts";
import {addSsrsGraphQLMutation} from "../../graph-ql/mutations/ssrs-add-graphql.mutation";
import {IDotRezCancelJourneyRequest} from "../../data-contracts/requests/booking/cancel-journey.request";
import {NullableString} from "../../../../types/nullable-types";
import {IDotRezPutBookingInQueueRequest} from "../../data-contracts/requests/booking/put-booking-in-queue.request";
import {IDotRezRemoveBookingFromQueueRequest} from "../../data-contracts/requests/booking/remove-booking-from-queue.request";
import {IDotRezAvailabilitySimpleRequest} from "../../data-contracts/requests/booking/search-simple.request";
import {IDotRezSimpleAvailability} from "../../data-contracts/booking/search-simple/search-simple.data-contracts";
import {IDotRezAvailabilityLowFareRequest} from "../../data-contracts/requests/booking/lowfare-search.request";
import {IDotRezAvailabilityLowFareResponse} from "../../data-contracts/responses/booking/lowfare-search.response";
import {IDotRez3dsPaymentDetailsRequest} from "../../data-contracts/requests/booking/3ds-payment-details.request";
import {IDotRez3dsPaymentDetailsResponse} from "../../data-contracts/responses/booking/3ds-payment-details.response";
import {DotRezResponse} from "../dot-rez-response";
import {delay} from "../../../../utils/util-functions";
import {HttpStatusCodeEnum} from "../../../../types/http-status-code.enum";
import {TimeSpan} from "../../../../types/time-span";
import {IDotRezUpdateSsrsResponse} from "../../data-contracts/responses/booking/update-ssrs.response";
import {IDotRezSsrsUpdateMutationResponseData} from "../../graph-ql/mutations/ssrs-update-mutation-response";
import {PaymentTransactionStatusEnum} from "../../../airline-webapi/enums/payment-transaction-status.enum";
import {IDotRezChangePassengerTypeRequest} from "../../data-contracts/requests/booking/change-passenger-type.request";
import {
    IDotRezChangePassengerTypeResponse
} from "../../data-contracts/responses/booking/change-passenger-type.response";

export class DotRezBookingSession extends DotRezSessionBase implements IDotRezBookingSession {

    constructor(token: string,
                services: IServiceFactory,
                sessionTimer: IDotRezSessionTimerModel) {
        super(token, services, sessionTimer);
        makeObservable<this, '_sessionTimer'>(this, {
            _sessionTimer: observable.ref
        });
    }

    async isExpired(): Promise<boolean> {
        return await this._sessionTimer.isExpired();
    }

    bookingStateQueryBuilder(initialBookingData?: IDotRezBookingData): IBookingStateQueryBuilder {
        return new BookingStateQueryBuilder(this, this.services, initialBookingData);
    }

    async availabilityLowFare(request: IDotRezAvailabilityLowFareRequest): Promise<IDotRezAvailabilityLowFareResponse> {
        const {classesOfService, ...restOfTheParams} = {...request};
        if((classesOfService?.length ?? 0) === 0) {
            const response = await this.post<IDotRezAvailabilityLowFareResponse>('/api/nsk/v2/availability/lowfare/simple', {
                body: restOfTheParams
            });
            return response.data;
        }

        const response = await this.post<IDotRezAvailabilityLowFareResponse>('/api/nsk/v2/availability/lowfare', {
            body: {
                passengers:{
                    types: request.passengers
                },
                codes: {
                    currency: request.currencyCode
                },
                filters: {
                    bookingClasses: classesOfService
                },
                criteria: [
                    {
                        originStationCodes: [request.origin],
                        destinationStationCodes: [request.destination],
                        beginDate: request.beginDate,
                        endDate: request.endDate
                    }
                ]
            }
        });
        return response.data;
    }

    async availabilitySimple(request: IDotRezAvailabilitySimpleRequest): Promise<IDotRezSimpleAvailability> {
        const response = await this.post<IDotRezSimpleAvailability>('/api/nsk/v4/availability/search/simple', {
            body: request
        });

        return response.data;
    }

    async sellTrip(request: IDotRezSellTripRequestWrapper): Promise<void> {
        let builder = new DotRezGraphQLMutationBuilder();

        if(request.journeysToCancel.length > 0) {
            builder.addQueryNamePart('cancelPrevTrip');
            builder = cancelJourneyGraphqlMutation(request.journeysToCancel, builder);
        }

        if(request.ssrsToRemove.length > 0) {
            builder.addQueryNamePart('removeSsrs');
            builder = removeSsrsGraphQLMutations(request.ssrsToRemove, builder);
        }


        builder.addQueryNamePart('sellTrip');
        builder = sellJourneysGraphqlMutation(request.sellTripRequest, builder)

        await this.executeGraphQLMutations(builder.build());
    }

    async bookingReset(): Promise<void> {
        if(!await this.isExpired()) {
            await this.delete('/api/nsk/v1/booking/reset', {
                doNotThrowOnError: true,
                doNotThrowOnSessionExpired: true
            });
        }
    }

    async sellBundle(journeyKeys: string[], ssrKeysToRemove: string[], request: IDotRezSellBundleRequest): Promise<void> {
        let builder = new DotRezGraphQLMutationBuilder();

        if(ssrKeysToRemove.length > 0) {
            builder.addQueryNamePart('removeSsrs');
            builder = removeSsrsGraphQLMutations(ssrKeysToRemove, builder);
        }

        builder.addQueryNamePart('sellBundle');
        builder = sellBundleGraphqlMutation(journeyKeys, request, builder);

        await this.executeGraphQLMutations(builder.build());
    }

    async addBookingContact(request: IDotRezContact): Promise<void> {
        await this.post(`/api/nsk/v1/booking/contacts/`, {
            body: request
        });
    }

    async updateBookingContact(request: IDotRezContact): Promise<void> {
        const {contactTypeCode, ...body} = request;
        await this.put(`/api/nsk/v1/booking/contacts/${contactTypeCode}`, {
            body: body
        });
    }

    async updatePassengerDetails(passengerKey: string, request: Partial<IDotRezUpdatePassengerDetailsRequest>): Promise<void> {
        if(request.info?.gender) {
            request = {
                ...request,
                info: {
                    ...request.info,
                    gender: genderToNumber(request.info.gender)
                }
            };
        }
        await this.patch(`/api/nsk/v3/booking/passengers/${passengerKey}`, {
            body: request
        });
    }

    async changePassengerName(passengerKey: string,  request: IDotRezChangePassengerNameRequest): Promise<void> {
        await this.patch(`/api/nsk/v3/booking/passengers/${passengerKey}`, {
            body: request
        });
    }


    async updateInfantDetails(passengerKey: string, request: IDotRezUpdateInfantDetailsRequest): Promise<void> {
        request = {
            ...request,
            gender: genderToNumber((request.gender))
        };

        await this.put(`/api/nsk/v3/booking/passengers/${passengerKey}/infant`, {
            body: request
        });
    }

    /*
    async addSeat(request: IDotRezSellSeatRequest): Promise<IDotRezAddSeatResponse> {
        const builder = new DotRezGraphQLMutationBuilder();
        builder.addQueryNamePart('sellSeat');
        const responseData = await this.executeGraphQLMutations<IDotRezSellSeatMutationResponse>(sellSeatGraphqlMutation(request, builder).build());
        return {
            seatKeys: responseData
                            .flatMap( r => r.seatAddv2)
                            .map(r => r.unitKey)
        }
    }

     */

    async removeSeat(request: IDotRezRemoveSeatRequest): Promise<void> {
        await this.removeSeats([request]);
    }

    async removeSeats(seatsToRemove: IDotRezRemoveSeatRequest[]): Promise<void> {
        let builder = new DotRezGraphQLMutationBuilder()
        builder.addQueryNamePart('removeSeats');
        builder = removeSeatsGraphqlMutation(seatsToRemove, builder);
        await this.executeGraphQLMutations(builder.build());
    }

    private _getUpdateSsrsRequestName(request: IDotRezUpdateSsrsRequest): string {
        if(request.ssrsToAdd.length === 0) {
            return 'removeSsrs';
        } else if(request.ssrKeysToRemove.length === 0) {
            return 'addSsrs';
        } else {
            return 'updatedSsrs'
        }

    }

    async updateSsrs(request: IDotRezUpdateSsrsRequest): Promise<IDotRezUpdateSsrsResponse> {

        let builder = new DotRezGraphQLMutationBuilder();
        builder.addQueryNamePart(this._getUpdateSsrsRequestName(request));
        builder = removeSsrsGraphQLMutations(request.ssrKeysToRemove, builder);
        builder = addSsrsGraphQLMutation(request.ssrsToAdd, builder);

        const responseData = await this.executeGraphQLMutations<IDotRezSsrsUpdateMutationResponseData>(builder.build());
        return {
            addedSsrsKeys: this._extractAddedSsrs(responseData),
            removedSsrsKeys: request.ssrKeysToRemove
        }
    }

    private _extractAddedSsrs(mutationResponseData: IDotRezSsrsUpdateMutationResponseData[]): string[] {
        let ssrsKeys: string[] = [];

        for(let mutationData of mutationResponseData) {
            if(mutationData.ssrsAddv2) {
                ssrsKeys = [
                    ...ssrsKeys,
                    ...mutationData.ssrsAddv2.map(newSsr => newSsr.ssrKey)
                ]
            }
        }
        return ssrsKeys;
    }


    async removeSsrs(ssrKeysToRemove: string[]): Promise<IDotRezUpdateSsrsResponse> {
        return await this.updateSsrs({
            ssrKeysToRemove: ssrKeysToRemove,
            ssrsToAdd: []
        })
    }

    async addSsrs(ssrsToAdd: IDotRezSsrToAdd[]): Promise<IDotRezUpdateSsrsResponse> {
        return await this.updateSsrs({
            ssrsToAdd: ssrsToAdd,
            ssrKeysToRemove: []
        });
    }



    async bringBookingInStateByEmail(request: IDotRezRetrieveBookingByEmailRequest): Promise<IDotRezBookingData> {
        const response = await this.executeGraphQLQuery<{bookingRetrievev2: IDotRezBookingData}>(bringBookingInStateByEmailQuery(request));
        return response.data.bookingRetrievev2;
    }

    async bringBookingInStateByLastName(request: IDotRezRetrieveBookingByLastNameRequest): Promise<IDotRezBookingData> {
        const response = await this.executeGraphQLQuery<{bookingRetrievev2: IDotRezBookingData}>(bringBookingInStateByLastNameQuery(request));
        return response.data.bookingRetrievev2;
    }

    async savePassengerTravelDocument(passengerKey:string, travelDocument: Partial<IDotRezPassengerTravelDocument>): Promise<IDotRezSavePassengerTravelDocumentResponse> {
        return await this._saveTravelDocument(
            `/api/nsk/v2/booking/passengers/${passengerKey}/documents`,
            travelDocument
        );
    }

    async saveInfantTravelDocument(passengerKey:string, travelDocument: Partial<IDotRezPassengerTravelDocument>): Promise<IDotRezSavePassengerTravelDocumentResponse> {
        return await this._saveTravelDocument(
            `/api/nsk/v2/booking/passengers/${passengerKey}/infant/documents`,
            travelDocument
        );

    }

    private async _saveTravelDocument(apiUrl: string, request: Partial<IDotRezPassengerTravelDocument>): Promise<IDotRezSavePassengerTravelDocumentResponse> {
        const {passengerTravelDocumentKey, ...documentBody} = request;
        if(documentBody.gender) {
            documentBody.gender = genderToNumber(documentBody.gender);
        }
        if(passengerTravelDocumentKey) {
            //documentTypeCode and issuedByCode cannot be updated
            const {documentTypeCode, issuedByCode, ...patchedDocumentBody} = documentBody;
            await this.patch(`${apiUrl}/${passengerTravelDocumentKey}`, {
                body: patchedDocumentBody
            });

            return {
                passengerTravelDocumentKey: passengerTravelDocumentKey
            }

        } else {
            const response = await this.post(apiUrl, {
                body: documentBody
            });

            return {
                passengerTravelDocumentKey: response.getEntityIDFromLocationHeader()
            }
        }
    }


    async checkInPassengers(request: IDotRezCheckInSegmentPassengersRequest[]): Promise<void> {
        let builder = new DotRezGraphQLMutationBuilder();
        builder.addQueryNamePart('checkInPassengers');
        builder = checkInGraphqlMutation(request, builder);
        await this.executeGraphQLMutations(builder.build())
    }

    async getBoardingPasses(journeys: IDotRezJourneyBoardingPassesRequest[]): Promise<IDotRezPassengerBoardingPass[]> {

        let boardingPasses: IDotRezPassengerBoardingPass[] = [];

        for(let journey of journeys) {
            const response = await this.executeGraphQLQuery(journeyBoardingPassesQuery(journey));
            boardingPasses = [
                ...boardingPasses,
                ...response.data.boardingPassByJourneyForPassengerS2D.boardingPasses
            ];
        }

        return boardingPasses;
    }

    async applyPromotionCode(promotionCode: string, isOneWayTrip: boolean): Promise<ValidationResultEnum> {
        const response = await this.post(`/api/nsk/v1/booking/promotion`, {
            body: { promotionCode: promotionCode },
            doNotThrowOnError: true
        });
        if (response.hasErrors) {
            return ValidationResultEnum.Failure;
        }

        const notAppliedCount = response.messages.countMessageType("PromotionNotApplied");
        if ((isOneWayTrip && notAppliedCount === 1) ||
            (!isOneWayTrip && notAppliedCount === 2)) {
            await this.removePromotionCode();
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }

    async removePromotionCode(): Promise<void> {
        await this.delete(`/api/nsk/v1/booking/promotion/`);
    }

    async validateVoucher(voucherRequest: IDotRezValidateVoucherRequest): Promise<IDotRezValidateVoucherResponse> {
        const response = await this.get<IDotRezVoucherInfo>(`/api/nsk/v1/booking/payments/voucher?ReferenceCode=${voucherRequest.voucherCode}`,
            {
                doNotThrowOnError: true
            });

        if (response.hasErrors) {
            return {
                result: ValidateVoucherResultEnum.Error,
                amount: 0
            }
        }

        const notAppliedCount = response.messages.countMessageType("VoucherValidation");
        if ((voucherRequest.isOneWayTrip && notAppliedCount === 1) ||
            (!voucherRequest.isOneWayTrip && notAppliedCount === 2)) {
            return {
                result: ValidateVoucherResultEnum.CannotBeAppliedForClassOfService,
                amount: 0
            }
        }

        const checkVoucherResponse = response.data;
        if ((this.services.configuration.data.forbiddenVouchers ?? []).includes(checkVoucherResponse.configurationCode)) {
            return {
                result: ValidateVoucherResultEnum.Expired,
                amount: 0
            }
        }

        if(!Check.isEmpty(checkVoucherResponse.expiration)){
            const today = this.services.time.currentDate;
            const expiration = this.services.time.parseIsoDate(checkVoucherResponse.expiration);
            if (expiration < today) {
                return {
                    result: ValidateVoucherResultEnum.Expired,
                    amount: 0
                }
            }
        }

        if (checkVoucherResponse.redeemableAmount === 0) {
            return {
                result: ValidateVoucherResultEnum.NotEnoughMoney,
                amount: 0
            }
        }

        if (voucherRequest.balanceDue.amount <= checkVoucherResponse.redeemableAmount) {
            return {
                result: ValidateVoucherResultEnum.CoverEntireAmount,
                amount: voucherRequest.balanceDue.amount
            }
        }
        return {
            result: ValidateVoucherResultEnum.PartialPayment,
            amount: Math.min(checkVoucherResponse.redeemableAmount, voucherRequest.balanceDue.amount)
        }
    }

    async addVoucherPaymentToBooking(request: IDotRezAddVoucherToBookingRequest): Promise<void> {
        await this.post(`/api/nsk/v4/booking/payments/voucher/`, {
            body: { 
                referenceCode: request.voucherCode,
                paymentMethodCode: DotRezPaymentCode.Voucher,
                amount: request.amount,
                currencyCode: request.currencyCode
             }
        });
    }

    async commitBooking(options?: ICommitBookingOptions): Promise<void> {
        let builder = new DotRezGraphQLMutationBuilder();
        builder.addQueryNamePart('commitBooking')
        builder = commitBookingGraphqlMutation(builder, options);
        await this.executeGraphQLMutations(builder.build());
    }


    async upgradeToAuthorizedUser(userName: string, password: string): Promise<void> {
        await this.put('/api/nsk/v1/token', {
            body: {
                username: userName,
                password: password,
                domain: 'WWW'
            }
        });
    }

    async getCheckInRequirements(journeysKeys: string[]): Promise<Record<string, IDotRezJourneyCheckInRequirements>> {
        const promises = journeysKeys.map(jKey => this.get<IDotRezJourneyCheckInRequirements>(`/api/nsk/v1/booking/checkin/journey/${jKey}/requirements`));

        const responses = await Promise.all(promises);

        const requirements: Record<string, IDotRezJourneyCheckInRequirements> = {};

        for(let i = 0; i < responses.length; i++) {
            requirements[journeysKeys[i]] = responses[i].data;
        }

        return requirements;
    }

    async cancelTrip(request: IDotRezCancelJourneyRequest): Promise<void> {
        const {journeyKey, ...restOfParams} = {...request}
        await this.delete(`/api/nsk/v1/booking/journeys/${journeyKey}`, {
            body: restOfParams
        });
    }


    async resellSsrs(ssrsToResell: IDotRezSsrToAdd[]): Promise<void> {
        for(let ssrToResell of ssrsToResell) {
            await this.post('/api/nsk/v2/booking/ssrs', {
                doNotThrowOnError: true,
                body: {
                    keys: [
                        {
                            ssrKey: ssrToResell.ssrKey,
                            count: ssrToResell.count,
                            note: ssrToResell.note
                        }
                    ]
                }
            });
        }
    }


    async putBookingInQueue(request: IDotRezPutBookingInQueueRequest): Promise<void> {
        await this.post('/api/nsk/v1/booking/queue', {
            body: request
        });
    }

    async removeBookingFromQueue(request: IDotRezRemoveBookingFromQueueRequest): Promise<void> {
        await this.delete('/api/nsk/v1/booking/queue', {
            body: {
                ...request,
                startDate: this.services.time.formatYYY_MM_DD(this.services.time.currentDate) // if we don't provide startDate dotREZ throw error
            }
        });
    }

    async getPayments(): Promise<IDotRezApiPayment[]> {
        const response = await this.get<IDotRezApiPayment[]>('/api/nsk/v1/booking/payments');
        return response.data ?? [];
    }

    async get3dsPaymentDetails(request: IDotRez3dsPaymentDetailsRequest): Promise<IDotRez3dsPaymentDetailsResponse> {
        let responseStatus = HttpStatusCodeEnum.Accepted;
        let apiResponse: DotRezResponse<any>;
        const timeout = TimeSpan.fromSeconds(this.services.configuration.data.payments.statusTimeoutInSeconds);
        const startTime = Date.now();
        do {
            apiResponse = await this.get('/api/nsk/v2/booking/status');
            responseStatus = apiResponse.status;
            if(responseStatus === HttpStatusCodeEnum.Accepted) {
                if(Date.now() - startTime > timeout.totalMilliseconds) {
                    throw new Error('Getting booking status timed out');
                }
                await delay(Date.now(), 1000);
            }
        } while(responseStatus === HttpStatusCodeEnum.Accepted)

        const bookingPayments = (apiResponse?.data?.payments as IDotRezApiPayment[]) ?? [];

        const payment = bookingPayments.find(p =>  p.details?.fields && p.details.fields[PaymentFieldsNamesEnum.WebapiPaymentToken] === request.paymentToken);

        if(!payment) {
            throw new Error(`There is no payment on booking with token ${request.paymentToken}`);
        }

        let beginPaymentStatus: PaymentTransactionStatusEnum;
        let redirectUrl: NullableString = null;
        if(payment.details?.fields) {
            redirectUrl = payment.details.fields[PaymentFieldsNamesEnum.NavitaireRedirectUrl] ?? null;
        }

        switch (payment.status) {
            case DotRezPaymentStatusApiEnum.Approved:
                beginPaymentStatus = PaymentTransactionStatusEnum.Completed;
                break;
            case DotRezPaymentStatusApiEnum.Pending:
            case DotRezPaymentStatusApiEnum.PendingCustomerAction:
                if(redirectUrl) {
                    beginPaymentStatus = PaymentTransactionStatusEnum.NotFinalized;
                } else {
                    throw new Error('No 3DS redirect url returned');
                }
                break;
            case DotRezPaymentStatusApiEnum.Declined:
                beginPaymentStatus = PaymentTransactionStatusEnum.Error;
                break;
            default:
                throw new Error('Unexpected payment status');
        }

        return {
            paymentKey: payment.paymentKey,
            paymentToken: request.paymentToken,
            url: redirectUrl,
            status: beginPaymentStatus
        };
    }

    async getBookingPayment(paymentKey: string): Promise<IDotRezApiPayment> {
        const response = await this.get<IDotRezApiPayment>(`/api/nsk/v1/booking/payments/${paymentKey}`);
        return response.data;
    }

    async addPassengerAddress(passengerKey: string, address: Omit<IDotRezPassengerAddressApi, 'passengerAddressKey'>): Promise<string> {
        const result = await this.post(`/api/nsk/v2/booking/passengers/${passengerKey}/addresses`, {
            body: {
                ...address,
                refusedContact: false
            }
        });

        return result.getEntityIDFromLocationHeader();
    }
    async updatePassengerAddress(passengerKey: string, address: {passengerAddressKey: string} &  Partial<Omit<IDotRezPassengerAddressApi, 'passengerAddressKey'>>): Promise<void> {
        const {passengerAddressKey, ...restOfTheAddress} = address;
        await this.patch(`/api/nsk/v3/booking/passengers/${passengerKey}/addresses/${passengerAddressKey}`, {
            body: {
                refusedContact: false,
                ...restOfTheAddress,
            }
        });
    }

    async changePassengerType(request: IDotRezChangePassengerTypeRequest): Promise<IDotRezChangePassengerTypeResponse> {
        const response = await this.patch<IDotRezChangePassengerTypeResponse>(`/api/nsk/v1/booking/passengers/${request.passengerKey}/passengerTypeCode`, {
            body: {
                passengerTypeCode: request.newPassengerTypeCode
            }
        });

        return response.data;
    }
}
