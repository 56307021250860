import {IPassengerType} from "../../../../passenger-types/passengers-types.service.interface";
import {mergeFeesCollections} from "../shopping-cart.helpers";
import {NullablePrice, Price} from "../../../../currency/price";
import {JourneyModel} from "../../journey/journey.model";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {IPassengerJourneyShoppingCartModel} from "../passenger/passenger-journey-shopping-cart-model.interface";
import {IPassengerTypeShoppingCartModel} from "./passenger-type-shopping-cart-model.interface";
import {NullableString} from "../../../../../types/nullable-types";
import {IPassengerAnalyticsData} from "../../analytics/google-analytics.intefaces";

export class PassengerTypeShoppingCartModel implements IPassengerTypeShoppingCartModel {
    constructor(private readonly journey: JourneyModel,
                private readonly passengerJourneyShoppingCarts: IPassengerJourneyShoppingCartModel[]) {
    }

    private get _selectedBundle(): string {
        return this.passengerJourneyShoppingCarts[0].selectedBundleName;
    }

    get uniqueKey(): string {
        return `${this.description}_${this.passengerType.code}_${this.discountCode}_${this.initialFare?.toString()}_${this.currentFare.toString()}`;
    }

    get description(): string {
        return `${this.passengerType.description} ${this._selectedBundle}`.trim();
    }

    get discountCode(): NullableString {
        return this.passengerJourneyShoppingCarts[0].discountCode;
    }

    get passengerType(): IPassengerType {
        return this.passengerJourneyShoppingCarts[0].passengerType;
    }

    get fareQuantity(): number {
        return this.passengerJourneyShoppingCarts.sum(sc => sc.fareQuantity);
    }

    get initialBundlePrice(): NullablePrice {
        return this.passengerJourneyShoppingCarts[0].initialBundlePrice;
    }

    get currentBundlePrice(): Price {
        return this.passengerJourneyShoppingCarts[0].currentBundlePrice;
    }


    get initialFare(): NullablePrice {
        return this.passengerJourneyShoppingCarts[0].initialFare;
    }

    get currentFare(): Price {
        return this.passengerJourneyShoppingCarts[0].currentFare;
    }

    get fareToDisplay(): Price {
        return this.passengerJourneyShoppingCarts[0].fareToDisplay;
    }

    get totalFareToDisplay(): Price {
        return this.fareToDisplay.multiply(this.fareQuantity);
    }

    get seatFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerJourneyShoppingCarts.map(p => p.seatFees));
    }

    get ssrFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerJourneyShoppingCarts.map(p => p.ssrFees));
    }


    get otherFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerJourneyShoppingCarts.map(p => p.otherFees));
    }

    getAnalyticsData(): IPassengerAnalyticsData[] {
        const passengerGroups: Record<string, IPassengerAnalyticsData> = {};

        for (const pjShoppingCart of this.passengerJourneyShoppingCarts) {

            let passengerTypeAnalyticsData = passengerGroups[pjShoppingCart.passengerType.code];
            if(!passengerTypeAnalyticsData) {
                passengerGroups[pjShoppingCart.passengerType.code] = {
                    quantity: pjShoppingCart.fareQuantity,
                    totalAmount: pjShoppingCart.fareToDisplay.amount,
                    passengerTypeCode: pjShoppingCart.passengerType.code,
                    discountCode: this.discountCode
                };

            } else {
                passengerTypeAnalyticsData.quantity += pjShoppingCart.fareQuantity;
            }

        }

        return Object.values(passengerGroups);
    }
}
