import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {JourneyModel} from "../../journey/journey.model";
import {IPassengerType} from "../../../../passenger-types/passengers-types.service.interface";
import {mergeFeesCollections} from "../shopping-cart.helpers";
import {NullablePrice, Price} from "../../../../currency/price";
import {IFeeModel} from "../../base-models/fees/fee-model.interface";
import {computed, makeObservable} from "mobx";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";
import {IPassengerSegmentShoppingCartModel} from "../../base-models/shopping-cart/passenger-segment-shopping-cart-model.interface";
import {IPassengerJourneyShoppingCartModel} from "./passenger-journey-shopping-cart-model.interface";
import {IFeeViewModel} from "../../base-models/fees/fee-view-model.interface";
import {ISeatFeeAnalyticsData} from "../../analytics/google-analytics.intefaces";
import {Check} from "../../../../../types/type-checking";

export class PassengerJourneyShoppingCartModel implements IPassengerJourneyShoppingCartModel {
    constructor(private readonly passenger: MaturePassengerModel,
                private readonly journey: JourneyModel,
                private readonly shoppingCartMode: ShoppingCartModeEnum) {
        makeObservable(this, {
            passengerSegmentsShoppingCarts: computed,
            initialFare: computed,
            currentFare: computed,
            seatFees: computed,
            ssrFees: computed,
            otherFees: computed
        });
    }

    get description(): string {
        return this.passenger.getFullName();
    }

    get selectedBundleName(): string {
        if(this.journey.selectedBundle) {
            return this.journey.selectedBundle.bundleType.bundleName;
        } else {
            return "";
        }
    }

    get selectedBundleCode(): string {
        if(this.journey.selectedBundle) {
            return this.journey.selectedBundle.bundleType.code;
        } else {
            return "";
        }
    }

    get discountCode(): string {
        return this.passenger.discountCode;
    }

    get passengerType(): IPassengerType {
        return this.passenger.passengerType;
    }

    get passengerKey(): string {
        return this.passenger.passengerKey;
    }

    get hasFareChangeOnCurrentSession(): boolean {
        if(!this.initialFare || !this.initialBundlePrice) {
            return true;
        }

        return this.currentFare.amount !== this.initialFare.amount
                || this.currentBundlePrice.amount !== this.initialBundlePrice.amount;
    }

    get passengerSegmentsShoppingCarts(): IPassengerSegmentShoppingCartModel[] {
        const carts: IPassengerSegmentShoppingCartModel[] = [];

        for (let segment of this.journey.segments) {
            for (let passengerSegment of segment.passengers) {
                if(passengerSegment.passenger.passengerKey === this.passenger.passengerKey) {
                    carts.push(passengerSegment.shoppingCart.createDecorator(this.shoppingCartMode));
                }
            }
        }

        return carts;
    }

    createPrice(amount: number): Price {
        return this.passenger.booking.createPrice(amount);
    }

    get fareQuantity(): number {
        return 1;
    }

    getSsrFees(): IFeeViewModel[] {
        return this.ssrFees;
    }

    get initialBundlePrice(): NullablePrice {
        return this.journey.initialBundlePrice;
    }

    get currentBundlePrice(): Price {
        return this.journey.currentBundlePrice;
    }

    get initialFare(): NullablePrice {

        const initialFares = this.passengerSegmentsShoppingCarts.filter(pssc => pssc.initialFare).map(pssc => pssc.initialFare);
        if(initialFares.length === 0) {
            return null;
        }

        return Price.sumAll(initialFares, this.createPrice(0));
    }

    private get initialFareToDisplay(): NullablePrice {
        if(!this.initialFare && !this.initialBundlePrice) {
            return null;
        }

        return Price.sumAll([this.initialFare, this.initialBundlePrice], this.createPrice(0));
    }

    get currentFare(): Price {
        return Price.sumAll(this.passengerSegmentsShoppingCarts.map(ps => ps.currentFare), this.createPrice(0));
    }

    get fareToDisplay(): Price {
        let fare = this.currentFare.sum(this.currentBundlePrice);
        if(this.initialFareToDisplay && this.shoppingCartMode === ShoppingCartModeEnum.ShowNewPurchasesOnly) {
            fare = fare.subtract(this.initialFareToDisplay);
        }

        return fare;
    }

    get totalFareToDisplay(): Price {
        return this.fareToDisplay.multiply(this.fareQuantity);
    }

    get seatFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerSegmentsShoppingCarts.map(s => s.seatFees));
    }

    get ssrFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerSegmentsShoppingCarts.map(s => s.ssrFees));
    }

    get otherFees(): IFeeModel[] {
        return mergeFeesCollections(this.passengerSegmentsShoppingCarts.map(s => s.otherFees));
    }

    get analyticsSsrFeesGroupedByAnalyticsName(): Record<string, IFeeModel[]> {
        const groupedData: Record<string, IFeeModel[][]> = {};

        this.passengerSegmentsShoppingCarts.forEach(ssc => {
            const feeAnalyticsData = ssc.getSsrsFeesGroupedByAnalyticsName();
            if(Check.isNull(feeAnalyticsData)) return;
            // get everything, from all passengers grouped by analytics name
            for (const key in feeAnalyticsData) {
                if(!groupedData[key]){
                    groupedData[key] = [];
                }
                groupedData[key].push(feeAnalyticsData[key]);
            }
        });
        const result: Record<string, IFeeModel[]> = {}
        for(const key in groupedData) {
            result[key] = mergeFeesCollections(groupedData[key]);
        }
        return result;
    }

    getSsrsFeesForId(ids: string[]): IFeeModel[]{
        return mergeFeesCollections(this.passengerSegmentsShoppingCarts.map(s => s.getSsrsFeesForId(ids)));
    }

    getSeatFeeAnalyticsData(): ISeatFeeAnalyticsData[] {
        let result: ISeatFeeAnalyticsData[] = [];

        this.passengerSegmentsShoppingCarts.forEach(ssc => {
            const feeAnalyticsData = ssc.getSeatFeeAnalyticsData();
            if(Check.isNull(feeAnalyticsData)) return;
            result = [
                ...result,
                feeAnalyticsData
            ]
        });
        return result;
    }

}
