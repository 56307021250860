import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {PaypalLogoLargeDesktopIcon} from "../../../../assets/svg/paypal-logo-large-desktop.icon";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";
import {PaypalLogoLargeMobileIcon} from "../../../../assets/svg/paypal-logo-large-mobile.icon";
import {PayPalMessages} from "@paypal/react-paypal-js";
import {PayPalMessagesComponentOptions} from "@paypal/paypal-js/types/components/messages";

const ContentContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${props => props.theme.spacing.spacing16};
`

const ContentTopSectionBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing.flexGap40};
    padding-left: ${props => props.theme.spacing.spacing40};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        flex-direction: column-reverse;
        gap: ${props => props.theme.spacing.flexGap8};
        padding-left: 0;
    }
`

const ContentTopLeftSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: ${props => props.theme.spacing.flexGap20};
`

const ContentTopRightSectionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`


const PayPalButtonSubtitleBox = styled.div`
    width: 100%;
    align-items: center;
    font-size: ${props => props.theme.fontSize.body2};
    color: ${props => props.theme.colors.mediumTint};
    text-align: center;
`

const ContentTextBox = styled.div`
    color: ${props => props.theme.colors.secondaryShade};
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.bold};
    width: 100%;
    text-align: center;
`

const PayLaterBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
`


export const PaypalPaymentMethodTabContentComponent: React.FC = observer(() => {
    const services = useServices();
    //const booking = services.booking.current;
    const containerMediaQuery = useContainerMediaQueriesChecks();


    if(!services.configuration.data.pspConfigs.PayPal.clientId) {
        return null;
    }


    const renderPayLater = () => {
        if(services.configuration.data.pspConfigs.PayPal.enablePayLater !== "true") {
            return null;
        }


        const messagesProps: PayPalMessagesComponentOptions = {
            placement: "payment",
            //amount: booking.balanceDue.amount,
            //currency: (booking.balanceDue.currency as any),
            style: {
                color: "white-no-border",
                layout: "text",
                ratio: "1x1",
                text: {
                    size: 16,
                    align: "center",
                }
            }
        }
        return (
            <PayLaterBox>
                <PayPalMessages {...messagesProps}/>
            </PayLaterBox>

        )
    }

    const renderLargeLogo = () => {

        if(containerMediaQuery.smallScreen) {
            return (
                <PaypalLogoLargeMobileIcon/>
            )
        } else {
            return (
                <PaypalLogoLargeDesktopIcon/>
            )
        }

    }

    return (
        <ContentContainerBox>

            <ContentTopSectionBox>
                <ContentTopLeftSectionBox>
                    <ContentTextBox>
                        {services.language.translate('Use your PayPal account to speed up your booking!')}
                    </ContentTextBox>
                    {renderPayLater()}
                </ContentTopLeftSectionBox>
                <ContentTopRightSectionBox>
                    {renderLargeLogo()}
                </ContentTopRightSectionBox>
            </ContentTopSectionBox>

            <PayPalButtonSubtitleBox dangerouslySetInnerHTML={{
                __html: services.language.translate('Click <b>PayPal</b> button to continue.')
            }}/>

        </ContentContainerBox>
    )
})