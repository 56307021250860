import React from "react";
import {WheelchairIcon} from "../../../../assets/svg/wheelchair.icon";
import {OnlineCheckInBlockerSsr} from "../online-check-in-blocker-ssr";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../ssrs-preferred-orders-in-shopping-cart";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE, SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../ssr-types.service.interface";
import { SsrCategoryEnum } from "../../ssr-category.enum";
import {TimeSpan} from "../../../../types/time-span";

export abstract class SpecialAssistanceSsrBase extends OnlineCheckInBlockerSsr {
//DEAF, BLND DNPA,WCHC, WCHR,WCHS, ,
    get benefitPsoDiscount(): boolean {
        return true;
    }

    get smallIcon(): React.ReactElement | null {
        return <WheelchairIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <WheelchairIcon size={SSR_LARGE_ICON_SIZE}/>;
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<WheelchairIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<WheelchairIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }


    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SPECIAL_ASSISTANCE;
    }

    get category(): SsrCategoryEnum {
        return SsrCategoryEnum.SpecialAssistance;
    }

    protected _getPurchaseMinimumTimeBeforeFlight(): TimeSpan | null {
        return TimeSpan.fromHours(3);
    }
}
