import {PassengerTypeSelectorBase} from "./passenger-type-selector-base";
import {IServiceFactory} from "../../service-factory.interface";
import {IPassengerType, IPassengerTypeSelectorsList} from "../passengers-types.service.interface";
import {PassengerTypeCodesEnum} from "../passenger-type-codes.enum";


export class ChildSelector extends PassengerTypeSelectorBase {
    constructor(services: IServiceFactory, passengersList: IPassengerTypeSelectorsList, count: number = 0) {
        super(services.passengerTypes.getChildTypes(), services, passengersList, count);
    }
    protected _getImplicitPassengerType(): IPassengerType {
        const passengerType = this.passengerTypes.find(pt => pt.code === PassengerTypeCodesEnum.Child);
        if(passengerType) {
            return passengerType;
        }

        throw new Error(`Didn't find passenger type code ${PassengerTypeCodesEnum.Child}`);
    }

}