import React from "react";
import {observer} from "mobx-react-lite";
import {useServices} from "../../../hooks/use-services.hook";
import {SameForAllFlightsToggleComponent} from "../common-components/same-for-all-flights-toggle.component";

import styled from "styled-components";
import {PassengerBagsComponent} from "./components/passenger-bags.component";
import {
    IMaturePassengerViewModel
} from "../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {SameForAllPassengersToggleComponent} from "../common-components/same-for-all-passengers-toggle.component";
import {IDialogConfirmationHandler} from "../../../services/dialog/dialog.service.interface";


const PageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
`

const SameForAllFligtsToggleWrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`


export const BagsSelectionPageContentComponent: React.FC<{dialogHandler: IDialogConfirmationHandler | null}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const passengers = booking.passengers;

    const ssrsAggregator = booking.bagsSsrsAggregator;

    const renderSameForAllFlightsToggleForSmallScreen = () => {
        if(!ssrsAggregator.allowUseSameOptionsForAllFlights) {
            return null;
        }

        if(props.dialogHandler) {
            return null;
        }

        if(!services.screenMediaQuery.smallScreen) {
            return null;
        }


        return (
            <SameForAllFligtsToggleWrapperBox>
                <SameForAllFlightsToggleComponent ssrsAggregator={ssrsAggregator} fullWidth={false}/>
            </SameForAllFligtsToggleWrapperBox>


        );
    }

    const renderOnePassenger = (passenger: IMaturePassengerViewModel) => {
        return (
            <React.Fragment key={passenger.stablePassengerUniqueKey}>
                <PassengerBagsComponent passenger={passenger} bagsAggregator={ssrsAggregator}/>
                {passenger.passengerIndex === 0 && ssrsAggregator.allowUseSameOptionsForAllPassengers && <SameForAllPassengersToggleComponent ssrsProvider={ssrsAggregator}/>}
            </React.Fragment>
        );
    }


    return (
        <PageContentBox>
            {renderSameForAllFlightsToggleForSmallScreen()}
            {passengers.map(renderOnePassenger)}
        </PageContentBox>

    );

})
