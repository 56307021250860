import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {PaypalLogoSmallV2Icon} from "../../../../assets/svg/paypal-logo-small-v2.icon";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";
import {useServices} from "../../../../hooks/use-services.hook";

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.flexGap8};
    padding: ${props => props.theme.spacing.spacing16};
    position: relative;
`

const PayLaterBox = styled.div`
    position: absolute;
    font-size: ${props => props.theme.fontSize.caption};
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    margin-left: -8px;
    ${props => props.theme.containerMediaQuery.smallScreen} {
        left: 0;
        margin-left: 0;
    }
`

export const PaypalPaymentMethodTabHeaderComponent: React.FC = observer(() => {
    const services = useServices();

    const screenMediaQuery = useContainerMediaQueriesChecks();
    const renderLabel = () => {
        if(screenMediaQuery.smallScreen) {
            return null;
        }

        return (
            <span>
                Paypal
            </span>
        );
    }

    const renderPayLater = () => {
        if(services.configuration.data.pspConfigs.PayPal.enablePayLater !== 'true') {
            return null;
        }

        return (
            <PayLaterBox>
                Pay later
            </PayLaterBox>
        )
    }

    return (
        <HeaderBox>
            <PaypalLogoSmallV2Icon/>
            {renderLabel()}
            {renderPayLater()}
        </HeaderBox>
    );
})