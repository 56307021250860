import {IServiceFactory} from "../../service-factory.interface";
import {IPassengerType, IPassengerTypeSelectorsList} from "../passengers-types.service.interface";
import {PassengerTypeSelectorBase} from "./passenger-type-selector-base";
import {InfantSelector} from "./infant-selector";
import {PassengerTypeCodesEnum} from "../passenger-type-codes.enum";
import {
    IDotRezAvailabilitySimpleRequestPassengers
} from "../../dot-rez-api/data-contracts/requests/booking/search-simple.request";

export class AdultSelector extends PassengerTypeSelectorBase {
    constructor(service: IServiceFactory, passengersList: IPassengerTypeSelectorsList, count: number = 1) {
        super(service.passengerTypes.getAdultTypes(),  service, passengersList, count);
    }

    protected _getMinimumCount() {
        return 1;
    }

    private _infantSelector: InfantSelector | null  = null;
    attachInfantSelector(infantSelector: InfantSelector): void {
        this._infantSelector = infantSelector;
    }

    protected _setCount(value: number) {
        super._setCount(value);

        if(!this._infantSelector) {
            return;
        }

        if(this._infantSelector.count > this.count) {
            this._infantSelector.count = this.count;
        }
    }

    protected _getImplicitPassengerType(): IPassengerType {
        const passengerType = this.passengerTypes.find(pt => pt.code === PassengerTypeCodesEnum.Adult);
        if(passengerType) {
            return passengerType;
        }

        throw new Error(`Didn't find passenger type code ${PassengerTypeCodesEnum.Adult}`);
    }

    getCodesForAvailabilitySearch(): IDotRezAvailabilitySimpleRequestPassengers {


        const childrenCount = this.passengersSelectionList.countChildren();

        if(childrenCount === 0 || this.passengersSelectionList.withBlueBenefits()) {
            return super.getCodesForAvailabilitySearch();
        }

        const adultWithChildrenCount = Math.min(childrenCount * 2, this.count);

        const result: IDotRezAvailabilitySimpleRequestPassengers = [
            {
                type: PassengerTypeCodesEnum.AdultWithChild,
                count: adultWithChildrenCount
            }
        ];

        if(adultWithChildrenCount < this.count) {
            result.push({
                type: this.getCurrentCode(),
                count: this.count - adultWithChildrenCount
            })
        }

        return result;
    }

}