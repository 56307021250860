import {
    IDotRezBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {BookingMutationActionBase} from "../booking-mutation-action-base";
import {PassengerTypeCodesEnum} from "../../../../passenger-types/passenger-type-codes.enum";

enum ReducedMobilityTypeEnum {
    None,
    Companion,
    ReducedMobility,
}

interface IPassengerToChangeTypeFor {
    passengerKey: string;
    newPassengerType: PassengerTypeCodesEnum;
    reducedMobilityType: ReducedMobilityTypeEnum;
}

export class PassengersTypesChangeMutation extends BookingMutationActionBase {

    async _runMutation(): Promise<Partial<IDotRezBookingSessionData>> {



        if(!this.booking.pso.allowReducedMobilityDiscount) {
            return {};
        }

        const reducedMobilityPassengers = this._getPassengersWithReducedMobility();
        const reducedMobilityPassengersCompanions = this._getReducedMobilityPassengersCompanions(reducedMobilityPassengers.map(p => p.passengerKey));
        const noReducedMobilityPassengers = this._getAdultsWithoutReducedMobility(reducedMobilityPassengers.map(p => p.passengerKey),
                                                                                                           reducedMobilityPassengersCompanions.map(p => p.passengerKey));


        const adultsToChangeTypeCode = this._applyChildCompanionsRules([
            ...noReducedMobilityPassengers,
            ...reducedMobilityPassengersCompanions,
            ...reducedMobilityPassengers,
        ]);

        const changedPassengers: string[] = [];

        for(let adultToChange of adultsToChangeTypeCode) {
            const passenger = this.booking.passengers.getByPassengerKey(adultToChange.passengerKey);

            if(passenger.passengerType.code === adultToChange.newPassengerType) {
                continue;
            }

            if(passenger.hasErrors()) {
                continue;
            }

            try {
                await passenger.savePassengerDetails();
                await passenger.changePassengerType(adultToChange.newPassengerType);
                changedPassengers.push(adultToChange.passengerKey);
            } catch (e) {
                this.services.logger.error(`Failed to change passengerType for passenger ${passenger.getFullName()}`, e);
            }
        }

        if(changedPassengers.length === 0) {
            return {};
        }


        return await this.booking.session.bookingStateQueryBuilder().useAllAvailabilities().useCheckInRequirements().getBookingState();
    }



    private _getPassengersWithReducedMobility(): IPassengerToChangeTypeFor[] {
        return this.booking.getAllPassengersSegments()
                    .filter(ps => ps.passengerType.isAdult && ps.getSoldSsrsEditors().some(ssr => ssr.ssrType.benefitPsoDiscount))
                    .map(ps => ps.passenger.passengerKey)
                    .distinct(pKey => pKey)
                    .map(pKey => {
                        return {
                            passengerKey: pKey,
                            newPassengerType: PassengerTypeCodesEnum.AdultWithReducedMobility,
                            reducedMobilityType: ReducedMobilityTypeEnum.ReducedMobility,
                        }
                    });
    }

    /**
     * Get the passengers that accompany the passengers with reduced mobility
     * @param reducedMobilityPassengersKeys
     * @private
     */
    private _getReducedMobilityPassengersCompanions(reducedMobilityPassengersKeys: string[]): IPassengerToChangeTypeFor[] {
        let possibleCompanions = this.booking.passengers.filter(p => p.passengerType.isAdult && !reducedMobilityPassengersKeys.includes(p.passengerKey));

        const alreadyCompanions = possibleCompanions.filter(p => p.passengerType.benefitReducedMobilityDiscount)
                                                            .map(p => p.passengerKey);

        if(alreadyCompanions.length === reducedMobilityPassengersKeys.length) {
            return this._mapPassengersKeyToNewPassengerTypeCode(alreadyCompanions,
                                                                PassengerTypeCodesEnum.AdultWithReducedMobility,
                                                                ReducedMobilityTypeEnum.Companion);
        }

        if(alreadyCompanions.length > reducedMobilityPassengersKeys.length) {
            return this._mapPassengersKeyToNewPassengerTypeCode(alreadyCompanions.slice(0, reducedMobilityPassengersKeys.length),
                                                                PassengerTypeCodesEnum.AdultWithReducedMobility,
                                                                ReducedMobilityTypeEnum.Companion);
        }

        possibleCompanions = possibleCompanions.filter(p => !alreadyCompanions.includes(p.passengerKey));

        let remainingCompanionsCount = reducedMobilityPassengersKeys.length - alreadyCompanions.length;

        return this._mapPassengersKeyToNewPassengerTypeCode([
            ...alreadyCompanions,
            ...possibleCompanions.slice(0, remainingCompanionsCount).map(p => p.passengerKey)
        ], PassengerTypeCodesEnum.AdultWithReducedMobility, ReducedMobilityTypeEnum.Companion);

    }

    private _getAdultsWithoutReducedMobility(reducedMobilityPassengersKeys: string[], reducedMobilityPassengersCompanionsKeys: string[]): IPassengerToChangeTypeFor[] {
        const passengersKeys = this.booking.passengers.filter(p => p.passengerType.isAdult
                                                                      && !reducedMobilityPassengersKeys.includes(p.passengerKey)
                                                                      && !reducedMobilityPassengersCompanionsKeys.includes(p.passengerKey))
                                                               .map(ps => ps.passengerKey);

        return this._mapPassengersKeyToNewPassengerTypeCode(passengersKeys, PassengerTypeCodesEnum.Adult, ReducedMobilityTypeEnum.None);
    }

    private _mapPassengersKeyToNewPassengerTypeCode(keys: string[],
                                                    passengerTypeCode: PassengerTypeCodesEnum,
                                                    reducedMobilityType: ReducedMobilityTypeEnum): IPassengerToChangeTypeFor[] {
        return keys.map(k => {
            return {
                passengerKey: k,
                newPassengerType: passengerTypeCode,
                reducedMobilityType: reducedMobilityType,
            }
        })
    }

    private _applyChildCompanionsRules(adults: IPassengerToChangeTypeFor[]): IPassengerToChangeTypeFor[] {
        const childrenCount = this.booking.passengers.countChildren();
        if(childrenCount === 0) {
            return adults;
        }

        const maxCompanionAdultsForChildren = 2 * childrenCount;

        const adultsToApplyTheRules = adults.slice(0, maxCompanionAdultsForChildren);
        const remainingAdults = adults.slice(adultsToApplyTheRules.length);

        for(let adt of adultsToApplyTheRules) {
            switch(adt.reducedMobilityType) {
                case ReducedMobilityTypeEnum.Companion:
                    adt.newPassengerType = PassengerTypeCodesEnum.AdultWithAndChildAndCompanionForPRM;
                    break;
                case ReducedMobilityTypeEnum.ReducedMobility:
                    adt.newPassengerType = PassengerTypeCodesEnum.AdultWithReducedMobility;
                    break;
                default:
                    adt.newPassengerType = PassengerTypeCodesEnum.AdultWithChild;
            }
        }

        return [
            ...adultsToApplyTheRules,
            ...remainingAdults
        ];
    }
}