import {IServiceFactory} from "../../service-factory.interface";
import {NullableNumber, NullableString} from "../../../types/nullable-types";
import {PassengerTypeBase} from "./passenger-type-base";
import {PassengerTypeCodesEnum} from "../passenger-type-codes.enum";

export class Infant extends PassengerTypeBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.Infant);
    }

    get description(): string {
        return this.services.language.translate('Infant');
    }

    getMinimumAge(): number {
        return 0;
    }

    protected _getMaximumAge(): NullableNumber {
        return 2;
    }

    get isAdult(): boolean {
        return false;
    }

    get isChild(): boolean {
        return false;
    }

    get isInfant(): boolean {
        return true;
    }

    get canBeMadePrimaryContact(): boolean {
        return false;
    }

    get implicitTitleValue(): NullableString {
        return 'CHD';
    }
}