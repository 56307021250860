import {NullableStation, PsoMarket, Station} from "../../stations/station.service.interface";
import {NullableUndefinedBoolean, NullableDate, NullableNumber, NullableString} from "../../../types/nullable-types";
import {FormModel} from "../../../models/forms/form.model";
import {FormFields, IFormField} from "../../../models/forms/form-field.interface";
import {IPassengerTypeSelectorsList} from "../../passenger-types/passengers-types.service.interface";
import {computed, IReactionDisposer, makeObservable, observable, reaction, runInAction} from "mobx";
import {FlightScheduleModel} from "../flights-schedule/flight-schedule.model";
import {VoidFlightsScheduleModel} from "../flights-schedule/void-flights-schedule.model";
import {IServiceFactory} from "../../service-factory.interface";
import {SessionStorageKeys} from "../../storage/session-storage-keys";
import {
    IAvailabilityOneWaySearchResult,
    IFlightSearchControllerCloneOptions,
    IFlightSearchControllerViewModel,
    IFlightSearchFields,
} from "./flight-search-controller-view-model.interface";
import {
    IDotRezAvailabilitySimpleRequest
} from "../../dot-rez-api/data-contracts/requests/booking/search-simple.request";
import {IFlightsScheduleViewModel} from "../flights-schedule/flights-schedule-view-model.interface";
import {ValidationResultEnum} from "../../../types/validation-result.enum";
import {
    IDotRezSimpleAvailability
} from "../../dot-rez-api/data-contracts/booking/search-simple/search-simple.data-contracts";
import {Check} from "../../../types/type-checking";
import {IFlightScheduleFilter} from "../flights-schedule/flight-schedule-filter.interface";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {FlightsDatesSelectorModel} from "../dates-selection/flights-dates-selector.model";
import {AnalyticsConstants} from "../../booking/models/analytics/google-analytics.intefaces";
import {PsoUserAgreementEnum, PsoUserOptionsEnum} from "./pso.enums";
import {IRoundTripLowFareReader} from "../../low-fare/low-fare-readers/low-fare-reader.interface";

interface IFlightSearchControllerModelOptions {
    bookingSession?: IDotRezBookingSession;
    getLowFareReader?: () => IRoundTripLowFareReader;
}

export class FlightSearchControllerModel extends FormModel<IFlightSearchFields> implements IFlightSearchControllerViewModel {
    constructor(services: IServiceFactory,
                private readonly _storageKey: SessionStorageKeys,
                options?: IFlightSearchControllerModelOptions) {
        super(services);

        this.departureSearchResult = this._createEmptySearchResult();
        this.returnSearchResult = this._createEmptySearchResult();
        this.datesSelector = new FlightsDatesSelectorModel(this, this.services, options?.getLowFareReader ?? (() => this.services.booking.getLowFareReader()));

        if(options?.bookingSession) {
            this._useBookingSessionInsteadOfFlightSession = true; //it means we are on a booking flow session so we must have the count down timer dialog
            this._flightSearchSessionPromise = Promise.resolve(options?.bookingSession);
        } else {
            this._useBookingSessionInsteadOfFlightSession = false; //it means we are in the home page search and no need for count down timer dialog
        }

        makeObservable<this, '_departureFlightSchedule' | '_returnFlightSchedule'>(this, {
            _departureFlightSchedule: observable.ref,
            _returnFlightSchedule: observable.ref,
            departureFlightSchedule: computed,
            returnFlightSchedule: computed,
            departureSearchResult: observable.ref,
            returnSearchResult: observable.ref
        });
    }

    departureSearchResult: IAvailabilityOneWaySearchResult;
    returnSearchResult: IAvailabilityOneWaySearchResult;
    private readonly _useBookingSessionInsteadOfFlightSession;

    private _createEmptySearchResult(): IAvailabilityOneWaySearchResult {
        return {

            faresAvailable: {},
            trips: []
        }
    }

    readonly datesSelector: FlightsDatesSelectorModel;

    protected _createFields(): FormFields<IFlightSearchFields> {
        const persistedFieldsValues = this.services.sessionStorage.getJson<IPersistedSearchParams>(this._storageKey);
        return {
            isOneWayDepartureTrip: this._createField<boolean>({
                fieldName: () => 'Is one way departure trip',
                defaultValue: persistedFieldsValues?.isOneWayDepartureTrip ?? false
            }),
            isOneWayReturnTrip: this._createField<boolean>({
                fieldName: () => 'Is one way return trip',
                defaultValue: persistedFieldsValues?.isOneWayReturnTrip ?? false
            }),
            departureOrigin: this._createField<Station>({
                fieldName: () => 'Departure origin',
                isRequired: () => true,
                defaultValue: this.services.stations.tryGetStation(persistedFieldsValues?.departureOrigin)
            }),
            departureDestination: this._createField<Station>({
                fieldName: () => 'Departure destination',
                isRequired: () => true,
                defaultValue: this.services.stations.tryGetStation(persistedFieldsValues?.departureDestination)
            }),
            returnOrigin: this._createField<Station>({
                fieldName: () => 'Return origin',
                isRequired: false,
                defaultValue: this.services.stations.tryGetStation(persistedFieldsValues?.returnOrigin)
            }),
            returnDestination: this._createField<Station>({
                fieldName: () => 'Return destination',
                isRequired: false,
                defaultValue: this.services.stations.tryGetStation(persistedFieldsValues?.returnDestination)
            }),
            psoUserOption: this._createField<PsoUserOptionsEnum>({
               fieldName: () => "",
               isRequired: false,
               defaultValue: persistedFieldsValues?.psoUserOption ?? PsoUserOptionsEnum.None,
               isHidden: () => true
            }),
            psoUserAgreement: this._createField<PsoUserAgreementEnum>({
                fieldName: () => "",
                isRequired: false,
                defaultValue: persistedFieldsValues?.psoUserAgreement ?? PsoUserAgreementEnum.None
            }),
            agent: this._createField<string>({
                fieldName: () => "Agent",
                isRequired: false,
                defaultValue: persistedFieldsValues?.agent ?? null,
                isHidden: () => true
            }),
            departureDate: this._createField<Date>({
                fieldName: () => 'Departure date',
                isRequired: () => !this.isOneWayReturnTrip,
                defaultValue: this.services.time.tryConvertToDate(persistedFieldsValues?.departureDate)
            }),
            returnDate: this._createField<Date>({
                fieldName: () => 'Return date',
                isRequired: false, //() => !this.isOneWay,
                defaultValue: this.services.time.tryConvertToDate(persistedFieldsValues?.returnDate)
            }),
            departureClassOfServices: this._createField<string[]>({
                fieldName: () => 'Departure class of services',
                isRequired: false,
                defaultValue: persistedFieldsValues?.departureClassOfServices || [],
            }),
            returnClassOfServices: this._createField<string[]>({
                fieldName: () => 'Return class of services',
                isRequired: false,
                defaultValue: persistedFieldsValues?.returnClassOfServices || [],
            }),
            departureMinPrice: this._createField<NullableNumber>({
                fieldName: () => 'Departure min price',
                isRequired: false,
                defaultValue: persistedFieldsValues?.departureMinPrice || null,
            }),
            returnMinPrice: this._createField<NullableNumber>({
                fieldName: () => 'Return min price',
                isRequired: false,
                defaultValue: persistedFieldsValues?.returnMinPrice || null,
            }),
            withBlueBenefits: this._createField<boolean>({
                fieldName: () => 'Use Blue Benefits',
                defaultValue: persistedFieldsValues?.withBlueBenefits ?? this.services.blueBenefits.isEnabled,

            }),
            passengers: this._createField<IPassengerTypeSelectorsList>({
                fieldName: () => 'Passengers',
                defaultValue: this.services.passengerTypes.createReadWritePassengersTypesSelectorsList(() => this.withBlueBenefits, persistedFieldsValues?.passengers ?? {})
            }),
            allowBogo: this._createField<boolean>({
                fieldName: () => 'Allow Bogo',
                defaultValue: persistedFieldsValues?.allowBogo ?? true,
            }),
            departureFlightScheduleFilter: this._createField<IFlightScheduleFilter>({
                fieldName: () => 'Departure flight schedule filter',
                isRequired: false
            }),
            returnFlightScheduleFilter: this._createField<IFlightScheduleFilter>({
                fieldName: () => 'Return flight schedule filter',
                isRequired: false
            })
        };
    }

    get availableDepartureStations(): Station[] {
        return this.services.stations.getAllStations();
    }

    get availableReturnStations(): Station[] {
        if(this.isOneWayReturnTrip) {
            if(this.returnOrigin) {
                return this.returnOrigin.markets;
            } else {
                return [];
            }
        }

        if(this.departureOrigin) {
            return this.departureOrigin.markets;
        }
        return [];


    }

    get recentlySearchedOrigins(): Station[] {
        return this.services.flightSearch.getRecentlySearchedOrigins();
    }

    get recentlySearchedDestinations(): Station[] {
        const stations = this.services.flightSearch.getRecentlySearchedDestinations();
        if(this.isOneWayReturnTrip) {
            return stations.filter(s => s.stationCode !== this.returnOrigin?.stationCode)
        } else {
            return stations.filter(s => s.stationCode !== this.departureOrigin?.stationCode)
        }
    }

    get isOneWay(): boolean {
        return this.isOneWayDepartureTrip || this.isOneWayReturnTrip;
    }

    get isOneWayDepartureTrip(): boolean {
        return this.fields.isOneWayDepartureTrip.value || false;
    }

    set isOneWayDepartureTrip(value: boolean) {
        if(value) {
            this.fields.isOneWayReturnTrip.setValue(false);
        }
        this.fields.isOneWayDepartureTrip.setValue(value);
    }

    get isOneWayReturnTrip(): boolean {
        return this.fields.isOneWayReturnTrip.value || false;
    }

    set isOneWayReturnTrip(value: boolean) {
        if(value) {
            this.fields.isOneWayDepartureTrip.setValue(false);
        }
        this.fields.isOneWayReturnTrip.setValue(value);
    }

    get withBlueBenefits(): boolean {
        return Boolean(this.fields.withBlueBenefits.value);
    }

    set withBlueBenefits(value: boolean) {
        this.fields.withBlueBenefits.setValue(value);
    }

    get departureOrigin(): NullableStation {
        /*
        if(this.isOneWayReturnTrip) {
            return null;
        }

         */
        return this.fields.departureOrigin.value;
    }

    set departureOrigin(value: NullableStation) {
        this.fields.departureOrigin.setValue(value);
    }

    get departureDestination(): NullableStation {
        /*
        if(this.isOneWayReturnTrip) {
            return null;
        }
         */

        return this.fields.departureDestination.value;
    }
    set departureDestination(value: NullableStation) {
        this.fields.departureDestination.setValue(value);
    }

    get returnOrigin(): NullableStation {
        /*if(this.isOneWayDepartureTrip) {
            return null;
        }*/
        return this.fields.returnOrigin.value || this.departureDestination;
    }

    set returnOrigin(value: NullableStation) {
        this.fields.returnOrigin.setValue(value);
    }

    get returnDestination(): NullableStation {
        /*
        if(this.isOneWayDepartureTrip) {
            return null;
        }
*/
        return this.fields.returnDestination.value || this.departureOrigin;
    }

    set returnDestination(value: NullableStation) {
        this.fields.returnDestination.setValue(value);
    }

    get psoUserOption(): PsoUserOptionsEnum {
        return this.fields.psoUserOption.value ?? PsoUserOptionsEnum.None;
    }

    set psoUserOption(value: PsoUserOptionsEnum) {
        this.fields.psoUserOption.setValue(value);
    }

    get psoUserAgreement(): PsoUserAgreementEnum {
        return this.fields.psoUserAgreement.value ?? PsoUserAgreementEnum.None;
    }

    set psoUserAgreement(value: PsoUserAgreementEnum) {
        this.fields.psoUserAgreement.setValue(value);
    }


    get agent(): NullableString {
        return this.fields.agent.value;
    }

    set agent(value: NullableString) {
        this.fields.agent.setValue(value);
    }

    get departureDate(): NullableDate {
        if(this.isOneWayReturnTrip) {
            return null;
        }
        return this.fields.departureDate.value;
    }

    set departureDate(value: NullableDate) {
        this.fields.departureDate.setValue(value);
    }

    get departureDateError(): NullableString {
        return this.fields.departureDate.error;
    }

    get returnDate(): NullableDate {
        if(this.isOneWayDepartureTrip) {
            return null;
        }
        return this.fields.returnDate.value;
    }

    set returnDate(value: NullableDate) {
        this.fields.returnDate.setValue(value);
    }

    get returnDateError(): NullableString {
        return this.fields.returnDate.error;
    }

    get passengers(): IPassengerTypeSelectorsList {
        return this.fields.passengers.value!
    }

    set passengers(value: IPassengerTypeSelectorsList) {
        this.fields.passengers.setValue(value);
    }

    get departureClassOfServices(): string[] {
        return this.fields.departureClassOfServices.value || [];
    }

    set departureClassOfServices(value: string[]) {
        this.fields.departureClassOfServices.setValue(value);
    }

    get returnClassOfServices(): string[] {
        return this.fields.returnClassOfServices.value || [];
    }

    set returnClassOfServices(value: string[]) {
        this.fields.returnClassOfServices.setValue(value);
    }

    get departureMinPrice(): NullableNumber {
        return this.fields.departureMinPrice.value;
    }

    set departureMinPrice(value: NullableNumber) {
        this.fields.departureMinPrice.setValue(value);
    }

    get returnMinPrice(): NullableNumber {
        return this.fields.returnMinPrice.value;
    }

    set returnMinPrice(value: NullableNumber) {
        this.fields.returnMinPrice.setValue(value);
    }

    get allowBogo(): boolean {
        return Boolean(this.fields.allowBogo.value);
    }

    set allowBogo(value: boolean) {
        this.fields.allowBogo.setValue(value);
    }

    get departureFlightScheduleFilter(): IFlightScheduleFilter | null {
        return this.fields.departureFlightScheduleFilter.value;
    }

    set departureFlightScheduleFilter(value: IFlightScheduleFilter | null) {
        this.fields.departureFlightScheduleFilter.setValue(value);
    }

    get returnFlightScheduleFilter(): IFlightScheduleFilter | null {
        return this.fields.returnFlightScheduleFilter.value;
    }

    set returnFlightScheduleFilter(value: IFlightScheduleFilter | null) {
        this.fields.returnFlightScheduleFilter.setValue(value);
    }

    hasErrors(): boolean {
        if(super.hasErrors()) {
            return true;
        }

        return (!this.fields.departureOrigin.value
                && !this.fields.departureDestination.value
                && !this.fields.returnOrigin.value
                && !this.fields.returnDestination.value)
            || (
                !this.fields.departureDate.value
                && !this.fields.returnDate.value
            );

    }

    getAnalyticsAgentName(): string {
        return this.agent ?? AnalyticsConstants.defaultAgentName;
    }


    validateFlightDatesSelection(): ValidationResultEnum {

        if((this.isOneWayDepartureTrip && !this.departureDate)
            || (this.isOneWayReturnTrip && !this.returnDate)) {
            this.services.alert.showError(this.services.language.translate('Please select the flight date'));
            return ValidationResultEnum.Failure;
        }

        if(!this.isOneWay && !this.departureDate) {
            this.services.alert.showError(this.services.language.translate('Please select departure date'));
            return ValidationResultEnum.Failure;
        }

        if(!this.isOneWay && !this.returnDate) {
            this.services.alert.showError(this.services.language.translate('Please select return date'));
            return ValidationResultEnum.Failure;
        }

        return ValidationResultEnum.Success;
    }
    /*
        private async _setWithBlueBenefitsDefault(): Promise<void> {
            if(Check.isNullOrUndefined(this.fields.withBlueBenefits.value)) {
                if(this.services.user.isAuthorized) {
                    const userProfile = this.services.user.profile;
                    await userProfile.waitForProfileInitialization();
                    this.fields.withBlueBenefits.setValue(this.services.blueBenefits.isEnabled);
                } else {
                    this.fields.withBlueBenefits.setValue(this.services.blueBenefits.isEnabled);
                }
            }
        }
    */


    clone(cloneOptions: IFlightSearchControllerCloneOptions): FlightSearchControllerModel {
        const theClone = new FlightSearchControllerModel(this.services, cloneOptions.storageKey, {
            bookingSession: cloneOptions.bookingSession
        });
        theClone.fields.isOneWayDepartureTrip.setValue(this.fields.isOneWayDepartureTrip.value);
        theClone.fields.isOneWayReturnTrip.setValue(this.fields.isOneWayReturnTrip.value);
        theClone.fields.withBlueBenefits.setValue(this.fields.withBlueBenefits.value);
        theClone.fields.departureOrigin.setValue(this.fields.departureOrigin.value);
        theClone.fields.departureDestination.setValue(this.fields.departureDestination.value);
        theClone.fields.returnOrigin.setValue(this.fields.returnOrigin.value);
        theClone.fields.returnDestination.setValue(this.fields.returnDestination.value);
        theClone.fields.departureDate.setValue(this.fields.departureDate.value);
        theClone.fields.returnDate.setValue(this.fields.returnDate.value);
        theClone.fields.passengers.setValue(this.services.passengerTypes.createReadWritePassengersTypesSelectorsList(() => theClone.withBlueBenefits, this._serializePassengers()));
        theClone.fields.allowBogo.setValue(this.fields.allowBogo.value);
        theClone.fields.departureMinPrice.setValue(this.fields.departureMinPrice.value);
        theClone.fields.returnMinPrice.setValue(this.fields.returnMinPrice.value);
        theClone.fields.departureClassOfServices.setValue( [...(this.fields.departureClassOfServices.value ?? [])]);
        theClone.fields.returnClassOfServices.setValue( [...(this.fields.returnClassOfServices.value ?? [])]);
        theClone.fields.psoUserOption.setValue(this.fields.psoUserOption.value);
        theClone.fields.psoUserAgreement.setValue(this.fields.psoUserAgreement.value);
        theClone.fields.agent.setValue(this.fields.agent.value);
        return theClone;
    }

    private _reactions: IReactionDisposer[] = [];

    private _resetBookingSessionPromise(): void {
        const tempBookingSessionPromise = this._flightSearchSessionPromise;
        this._flightSearchSessionPromise = null;

        if(tempBookingSessionPromise) {
            tempBookingSessionPromise.then(session => {
                session.dispose();
            });
        }
    }

    protected _onFieldsCreated(fields: FormFields<IFlightSearchFields>) {
        super._onFieldsCreated(fields);

        fields.withBlueBenefits.onChange((value: boolean | null) => {
            if(value) {
                const passengers = this.fields.passengers.value;
                if(passengers) {
                    passengers.limit(this.services.user.profile.blueBenefitsSubscription.maxNumberOfPassengers)
                }
            }
        });

        /*
        fields.isOneWayDepartureTrip.onChange(() => {
            this._resetFlightsSchedule();
        });

        fields.isOneWayReturnTrip.onChange(() => {
            this._resetFlightsSchedule();
        });
         */

        fields.departureOrigin.onChange(() => {
            this.fields.departureDestination.setValue(null);
            this._resetFlightsSchedule();
            this._resetUsePsoMarketSelection();
        });

        fields.departureDestination.onChange(() => {
            this._resetFlightsSchedule();
            this._resetUsePsoMarketSelection();
        });

        fields.returnOrigin.onChange(() => {
            this.fields.returnDestination.setValue(null);
            this._resetFlightsSchedule();
            this._resetUsePsoMarketSelection();
        });

        fields.returnDestination.onChange(() => {
            this._resetFlightsSchedule();
            this._resetUsePsoMarketSelection();
        });

        fields.departureFlightScheduleFilter.onChange(() => {
            this._resetFlightsSchedule();
        });

        fields.returnFlightScheduleFilter.onChange(() => {
            this._resetFlightsSchedule();
        });

        fields.psoUserOption.onChange(() => {
            this._resetBookingSessionPromise();
            this.fields.psoUserAgreement.setValue(PsoUserAgreementEnum.None);

        });

        fields.psoUserAgreement.onChange(() => {
            this._resetBookingSessionPromise();
        });

        this._reactions.push(reaction(() =>  fields.passengers.value?.getAll().map(p => p.count),
                                        () => this._persistFieldsValues()))
    }

    protected _onFieldChanged(field: IFormField) {
        super._onFieldChanged(field);
        this._persistFieldsValues();
    }

    private _resetFlightsSchedule(): void {
        runInAction(() => {
            this.fields.departureDate.setValue(null);
            this.fields.returnDate.setValue(null);
            this._departureFlightSchedule = null;
            this._returnFlightSchedule = null;

        })
    }

    private _resetUsePsoMarketSelection(): void {
        this.fields.psoUserOption.setValue(PsoUserOptionsEnum.None);
        this.fields.psoUserAgreement.setValue(PsoUserAgreementEnum.None);
    }


    private _departureFlightSchedule: IFlightsScheduleViewModel | null = null;
    get departureFlightSchedule(): IFlightsScheduleViewModel {
        return this._getFlightSchedule(this.departureOrigin,
                                       this.departureDestination,
                                        () => null,
                                        () => this._departureFlightSchedule,
                                        schedule => this._departureFlightSchedule = schedule,
                                        this.departureFlightScheduleFilter);
    }

    private _getMinAllowedReturnDate(): NullableDate {
        if(this.isOneWay) {
            return null;
        }

        return this.departureDate;

    }
    private _returnFlightSchedule: IFlightsScheduleViewModel | null = null;
    get returnFlightSchedule(): IFlightsScheduleViewModel {
        return this._getFlightSchedule(
                this.returnOrigin,
                this.returnDestination,
                () =>  this._getMinAllowedReturnDate(),
                () => this._returnFlightSchedule,
                schedule => this._returnFlightSchedule = schedule,
                this.returnFlightScheduleFilter);
    }

    private _getFlightSchedule(origin: NullableStation,
                               destination: NullableStation,
                               getMinAllowedDate: () => NullableDate,
                               getCurrentSchedule: () => IFlightsScheduleViewModel | null,
                               setCurrentSchedule: (schedule: IFlightsScheduleViewModel) => void,
                               scheduleFilter: IFlightScheduleFilter | null): IFlightsScheduleViewModel{

        const currentSchedule = getCurrentSchedule();
        if(currentSchedule) {
            return currentSchedule;
        }

        if(!origin || !destination) {
            return new VoidFlightsScheduleModel();
        }

        if(origin.stationCode === destination.stationCode) {
            return new VoidFlightsScheduleModel();
        }

        const newSchedule = new FlightScheduleModel(this.services, origin, destination, getMinAllowedDate, scheduleFilter);

        runInAction(() => {
            setCurrentSchedule(newSchedule);
        });

        return newSchedule;
    }


    private async _waitForSchedule(): Promise<void> {
        await Promise.all([this.departureFlightSchedule.waitForSchedule(), this.returnFlightSchedule.waitForSchedule()]);

    }

    private  _persistFieldsValues(): void {

        const persistedFieldValues: IPersistedSearchParams = {
            isOneWayDepartureTrip: this.fields.isOneWayDepartureTrip.value,
            isOneWayReturnTrip: this.fields.isOneWayReturnTrip.value,
            withBlueBenefits: this.fields.withBlueBenefits.value,
            departureOrigin: this.fields.departureOrigin.value?.stationCode || null,
            departureDestination: this.fields.departureDestination.value?.stationCode || null,
            returnOrigin: this.fields.returnOrigin.value?.stationCode || null,
            returnDestination: this.fields.returnDestination.value?.stationCode || null,
            psoUserOption: this.fields.psoUserOption.value,
            psoUserAgreement: this.fields.psoUserAgreement.value,
            agent: this.fields.agent.value,
            departureDate: this.services.time.formatYYY_MM_DD(this.fields.departureDate.value),
            returnDate: this.services.time.formatYYY_MM_DD(this.fields.returnDate.value),
            passengers: this._serializePassengers(),
            departureClassOfServices: this.fields.departureClassOfServices.value,
            returnClassOfServices: this.fields.returnClassOfServices.value,
            departureMinPrice: this.fields.departureMinPrice.value,
            returnMinPrice: this.fields.returnMinPrice.value,
            allowBogo: this.allowBogo
        }

        this.services.sessionStorage.setJson(this._storageKey, persistedFieldValues);
    }

    private _serializePassengers(): Record<string, number> {
        if(this.fields.passengers.value) {
            return this.fields.passengers.value.getAll().toDictionaryOfType(item => item.getCurrentCode(), item => item.count);
        } else {
            return {}
        }
    }

    dispose(): void {
        this.services.sessionStorage.removeItem(this._storageKey);
        this._reactions.forEach(disposer => disposer());
        this._reactions = [];
        this.datesSelector.dispose();
    }

    private _returnAndDestinationHaveSameClassOfServices(): boolean {
        const departureClassOfServices = this.departureClassOfServices.sort((c1, c2) => c1.localeCompare(c2))
        const returnClassOfServices = this.returnClassOfServices.sort((c1, c2) => c1.localeCompare(c2))

        if(departureClassOfServices.length !== returnClassOfServices.length) {
            return false;
        }

        for(let i = 0; i < departureClassOfServices.length; i++) {
            if(departureClassOfServices[i] !== returnClassOfServices[i]) {
                return false;
            }
        }

        return true;
    }

    private _isRoundTrip(): boolean {
        return !this.isOneWay
                && this.departureOrigin?.stationCode === this.returnDestination?.stationCode
                && this.departureDestination?.stationCode === this.returnOrigin?.stationCode
                && this._returnAndDestinationHaveSameClassOfServices()
                && this.departureMinPrice === this.returnMinPrice;
    }

    getPsoMarket(): PsoMarket | null {
        return this.services.stations.getPsoMarket(this.departureOrigin, this.departureDestination)
    }

    private _getPsoAgentName(): NullableString {
        if(this.psoUserOption === PsoUserOptionsEnum.None) {
            return null;
        }

        if(this.psoUserAgreement === PsoUserAgreementEnum.DontAgree) {
            return null;
        }

        if(this.psoUserOption === PsoUserOptionsEnum.Resident) {
            return this.getPsoMarket()?.getAgent(false) ?? null;
        }

        return this.getPsoMarket()?.getAgent(true) ?? null;

    }

    private _createDotRezSessionFromToken(dotRezToken: string): IDotRezBookingSession {
        if(this._useBookingSessionInsteadOfFlightSession) {
            return this.services.dotRezApi.createBookingSession(dotRezToken);
        } else {
            return this.services.dotRezApi.createFlightSearchSession(dotRezToken);
        }
    }

    private async _startNewDotRezSession(): Promise<IDotRezBookingSession> {
        if(this._useBookingSessionInsteadOfFlightSession) {
            return await this.services.user.createBookingSession();
        } else {
            return await this.services.user.createFlightSearchSession();
        }
    }

    private async _createBookingSession(): Promise<IDotRezBookingSession> {
        let agentName = this._getPsoAgentName();

        if(!agentName) {
            agentName = this.agent;
        }

        if(agentName) {
            const dotRezToken = await this.services.dotRezApi.getDeepLinkAgentToken({
                agentName: agentName
            });

            return this._createDotRezSessionFromToken(dotRezToken);

        } else {
            return await this._startNewDotRezSession();
        }
    }


    private _flightSearchSessionPromise: Promise<IDotRezBookingSession> | null = null;
    getFlightSearchSession(): Promise<IDotRezBookingSession> {

        if(this._flightSearchSessionPromise) {
            this._flightSearchSessionPromise = this._flightSearchSessionPromise.then(session => {
                return session.isExpired().then(isExpired => {
                    if(isExpired) {
                        return this._createBookingSession();
                    } else {
                        return session;
                    }
                });
            }).catch(() => {
                return this._createBookingSession();
            });
        } else {
            this._flightSearchSessionPromise = this._createBookingSession();
        }
        return this._flightSearchSessionPromise;
    }

    async applySearch(): Promise<void> {
        if(this.hasErrors()) {
            this.services.logger.error('Invalid booking search params');
            return;
        }

        await this._waitForSchedule();

        const session = await this.getFlightSearchSession();

        if(this._isRoundTrip()) {

            const response = await this._availabilitySimple(session,
                                                            this.departureOrigin,
                                                            this.departureDestination,
                                                            this.departureDate,
                                                            this.returnDate,
                                                            this.departureClassOfServices,
                                                            this.departureMinPrice);

            runInAction(() => {
                this.departureSearchResult = {
                    trips: response.results[0]?.trips || [],
                    faresAvailable: response.faresAvailable
                }
                this.returnSearchResult = {
                    trips: response.results[1]?.trips || [],
                    faresAvailable: response.faresAvailable
                };
            });

        } else {

            const result = await Promise.all([
                this._searchDepartureAvailability(session),
                this._searchReturnAvailability(session)
            ]);

            runInAction(() => {
                this.departureSearchResult = result[0];
                this.returnSearchResult = result[1];
            });
        }
    }

    async applySearchForDepartureOnly(): Promise<void> {

        const session = await this.getFlightSearchSession();

        const response = await this._searchDepartureAvailability(session);

        runInAction(() => {
            this.departureSearchResult = response;
        });
    }

    async applySearchForReturnOnly(): Promise<void> {

        const session = await this.getFlightSearchSession();

        const result = await this._searchReturnAvailability(session);

        runInAction(() => {
            this.returnSearchResult = result;
        });
    }


    private async _availabilitySimple(session: IDotRezBookingSession,
                                      origin: NullableStation,
                                      destination: NullableStation,
                                      beginDate: NullableDate,
                                      endDate: NullableDate,
                                      classOfServices: string[],
                                      minPrice: NullableNumber): Promise<IDotRezSimpleAvailability> {

        if(!origin || !destination || !beginDate) {
            throw new Error('Origin, destination and beginDate are mandatory for availability search');
        }

        const request: IDotRezAvailabilitySimpleRequest = {
            origin: origin.stationMacCode,
            destination: destination.stationMacCode,
            beginDate: this.services.time.formatYYY_MM_DD(beginDate),
            codes: {
                currencyCode: this.services.booking.activeCurrency,
                promotionCode: !this.allowBogo || this.passengers.countAllWithoutInfants() === 1 ? "" : "BOGO1"
            },
            passengers: {
                types: this.passengers.createPassengerTypesForAvailabilitySearch()
            },
            filters: {
                maxConnections: 5
            }
        }

        if(endDate) {
            request.endDate = this.services.time.formatYYY_MM_DD(endDate);
        }

        if(classOfServices.length > 0) {
            request.filters.classesOfService = classOfServices;
        }

        if(!Check.isNullOrUndefined(minPrice)) {
            request.filters.minPrice = minPrice;
        }

        return await session.availabilitySimple(request);
    }

    private async _availabilitySimpleOneWay(session: IDotRezBookingSession,
                                            origin: NullableStation,
                                            destination: NullableStation,
                                            beginDate: NullableDate,
                                            classOfServices: string[],
                                            minPrice: NullableNumber): Promise<IAvailabilityOneWaySearchResult> {
        const response = await this._availabilitySimple(session, origin, destination, beginDate, null, classOfServices, minPrice);

        return {
            faresAvailable: response.faresAvailable,
            trips: response.results[0]?.trips || []
        };
    }

    private async _searchDepartureAvailability(session: IDotRezBookingSession): Promise<IAvailabilityOneWaySearchResult> {
        if(this.isOneWayReturnTrip) {
            return this._createEmptySearchResult();
        }

        return await this._availabilitySimpleOneWay(session,
                                                    this.departureOrigin,
                                                    this.departureDestination,
                                                    this.departureDate,
                                                    this.departureClassOfServices,
                                                    this.departureMinPrice);
    }


    private async _searchReturnAvailability(session: IDotRezBookingSession): Promise<IAvailabilityOneWaySearchResult> {
        if(this.isOneWayDepartureTrip) {
            return this._createEmptySearchResult();
        }

        return await this._availabilitySimpleOneWay(session,
                                                    this.returnOrigin,
                                                    this.returnDestination,
                                                    this.returnDate,
                                                    this.returnClassOfServices,
                                                    this.returnMinPrice);
    }
}


interface IPersistedSearchParams {
    isOneWayDepartureTrip: NullableUndefinedBoolean;
    isOneWayReturnTrip: NullableUndefinedBoolean;
    withBlueBenefits: NullableUndefinedBoolean;
    departureOrigin: NullableString;
    departureDestination: NullableString;
    returnOrigin: NullableString,
    returnDestination: NullableString;
    psoUserOption: PsoUserOptionsEnum | null;
    psoUserAgreement: PsoUserAgreementEnum | null;
    agent: NullableString;
    departureDate: NullableString;
    returnDate: NullableString;
    passengers: Record<string, number>;
    departureClassOfServices: string[] | null;
    returnClassOfServices: string[] | null;
    departureMinPrice: NullableNumber;
    returnMinPrice: NullableNumber;
    allowBogo: boolean;
}
