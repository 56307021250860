import {FormModel} from "../../../../models/forms/form.model";
import {IPassengerInfoFields, PsoPassengerTypeEnum} from "./passenger-info-fields.interface";
import {NullableDate, NullableNumber, NullableString} from "../../../../types/nullable-types";
import {IPassengerType} from "../../../passenger-types/passengers-types.service.interface";
import {BookingModel} from "../booking.model";
import {
    IDotRezPassengerAddressGraphQL,
    IDotRezPassengerInfo,
    IDotRezPassengerName,
    IDotRezPassengerTravelDocument
} from "../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {PassengerTravelDocumentModel} from "./passenger-travel-document.model";
import {Check} from "../../../../types/type-checking";
import {IPersistedBookingContact} from "../contacts/persisted-booking-contact.interface";
import {FormFields} from "../../../../models/forms/form-field.interface";
import {IPassengerViewModel} from "./passenger-view-model.interface";
import {PassengerAttachedCompanionModel} from "./passenger-attached-companion.model";
import {IPassengerAttachedCompanionViewModel} from "./passenger-attached-companion-view-model.interface";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IFeeOwner} from "../base-models/fees/fee-owner.interface";
import {BirthdateValidator} from "../../../../models/forms/field-validators/birthdate.validator";
import {BookingSessionStorageKeys} from "../storage/booking-storage.interface";



export abstract class PassengerModelBase extends FormModel<IPassengerInfoFields> implements IPassengerViewModel, IFeeOwner {
    protected constructor(passengerKey: string,
                          public readonly booking: BookingModel) {
        super(booking.services);
        this._stablePassengerUniqueKey = booking.storage.getItem(this._composeStableKeyStorageIdentifier(passengerKey));
        if(!this._stablePassengerUniqueKey) {
            this._stablePassengerUniqueKey = passengerKey;
        }
        this._passengerKey = passengerKey;
        this._companion = new PassengerAttachedCompanionModel(this);
        this.travelDocument = new PassengerTravelDocumentModel(this);
    }

    abstract get passengerType(): IPassengerType;
    abstract get canHaveSpecialAssistance(): boolean;
    abstract get isPrimaryContact(): boolean;
    abstract set isPrimaryContact(value: boolean);
    abstract get nameOnPreviousSession(): IDotRezPassengerName | null;
    abstract get name(): IDotRezPassengerName;
    abstract get infoOnPreviousSession(): IDotRezPassengerInfo | null;
    abstract get info(): IDotRezPassengerInfo;
    abstract get travelDocuments(): IDotRezPassengerTravelDocument[];
    abstract get addressesOnPreviousSession(): IDotRezPassengerAddressGraphQL[];
    abstract get addresses(): IDotRezPassengerAddressGraphQL[];
    abstract get allowPsoReducedMobilityDiscount(): boolean;
    public abstract get passengerTypeIndex(): number;
    public abstract get hasPerformedCheckInOnAnySegment(): boolean;
    protected abstract _getInitialCustomerNumber(): NullableString;
    abstract get nameChangeBlockingReason(): NullableString;
    abstract saveTravelDocument(travelDocument: IDotRezPassengerTravelDocument): Promise<NullableString>;
    protected abstract _startNameChangeMutation(): void;

    private _composeStableKeyStorageIdentifier(passengerKey: string): string {
        return `${BookingSessionStorageKeys.stablePassengerKey}_${passengerKey}`;
    }

    private readonly _stablePassengerUniqueKey: string;
    get stablePassengerUniqueKey(): string {
        return this._stablePassengerUniqueKey;
    }

    protected onPassengerKeyChanged(newPassengerKey: string) {
        this.booking.storage.removeItem(this._composeStableKeyStorageIdentifier(this.passengerKey));
        this._passengerKey = newPassengerKey;
        this.booking.storage.setItem(this._composeStableKeyStorageIdentifier(newPassengerKey), this.stablePassengerUniqueKey);
    }

    private _passengerKey: string;

    get passengerKey(): string {
        return this._passengerKey;
    }

    get customerNumber(): NullableString {
        return this.fields.customerNumber.value;
    }


    get isTitleRequiredForCheckIn(): boolean {
        return Check.isNullOrUndefined(this.passengerType.implicitTitleValue);
    }


    get isOnDomesticFlight(): boolean {
        return this.booking.isDomesticFlight;
    }

    readonly travelDocument: PassengerTravelDocumentModel;

    computePassengerAge(referenceDate: Date): NullableNumber {
        if(!this.fields.dateOfBirth.value) {
            return null;
        }

        return this.services.time.computeAgeInYears(this.fields.dateOfBirth.value, referenceDate);
    }

    private _getPersistedContactInfo(): IPersistedBookingContact | undefined | null {
        if(this.services.user.isAuthorized) {
            return null;
        }

        if(this.booking.passengers[0].passengerKey === this.passengerKey) {
            return this.booking.services.localStorage.getJson<IPersistedBookingContact>('userProfile.bookingContact');
        }

        return null;
    }

    protected _createFields(): FormFields<IPassengerInfoFields> {
        const persistedContactInfo = this._getPersistedContactInfo();

        const language = this.services.language;
        return {
            firstName: this._createFirstNameField({
                getValueFromPreviousSession: () => this.nameOnPreviousSession?.first,
                initialValue: () => this.name.first,
                defaultValue: persistedContactInfo?.firstName

            }),
            lastName: this._createLastNameField({
                getValueFromPreviousSession: () => this.nameOnPreviousSession?.last,
                initialValue: () => this.name.last,
                defaultValue: persistedContactInfo?.lastName
            }),
            customerNumber: this._createField<NullableString>({
                fieldName: () => language.translate('Customer number'),
                getValueFromPreviousSession: () => this._getInitialCustomerNumber(),
                initialValue: () => this._getInitialCustomerNumber(),
                maxLength: 20,
                isHidden: () => true,
                isRequired: false
            }),
            gender: this._createGenderField({
                getValueFromPreviousSession: () => this.infoOnPreviousSession?.nationality ? this.infoOnPreviousSession?.gender : null,
                //When a passenger is created in dotREZ it gets by default Male as gender
                //We don't want to use the default value Male for any person.
                //So here if the passenger has nationality filled in it means that also gender was filled in by the user.
                initialValue: () => this.info.nationality ? this.info.gender : null,
                isRequired: false
            }),
            dateOfBirth: this._createField<NullableDate>({
                fieldName: () => language.translate('Date of birth'),
                getValueFromPreviousSession: () => this.services.time.tryConvertToDate(this.infoOnPreviousSession?.dateOfBirth),
                initialValue: () => this.services.time.tryConvertToDate(this.info.dateOfBirth),
                defaultValue: this.services.time.tryConvertToDate(persistedContactInfo?.dateOfBirth),
                //isRequired: () => !this.hasPsoMarketDiscount,
                isRequired: !this.passengerType.isAdult,
                isHidden: () => this.passengerType.isAdult,
                validators: [
                    new BirthdateValidator(this.services)
                ]
            }),
            nationality: this._createField<NullableString>({
                fieldName: () => language.translate('Citizenship'),
                getValueFromPreviousSession: () => this.infoOnPreviousSession?.nationality,
                initialValue: () => this.info.nationality,
                defaultValue: this.isEligibleForPsoMarketDiscount ? this.services.configuration.defaultCountryCode : null,
                isRequired: false,
            }),
            psoPassengerType: this._createField<PsoPassengerTypeEnum | null>({
                fieldName: () => '',
                getValueFromPreviousSession: () => this.addressesOnPreviousSession[0]?.lineThree as PsoPassengerTypeEnum,
                initialValue: () => this.addresses[0]?.lineThree as PsoPassengerTypeEnum,
                defaultValue: PsoPassengerTypeEnum.Resident,
                isRequired: () => false, //this.isEligibleForPsoMarketDiscount,
                isHidden: () => true //!this.isEligibleForPsoMarketDiscount
            }),
            addressCity: this._createField<NullableString>({
                fieldName: () => {
                    if(this.fields.psoPassengerType.value === PsoPassengerTypeEnum.Native) {
                        return language.translate('Place of birth');
                    } else {
                        return language.translate('City of residence');
                    }
                },
                getValueFromPreviousSession: () => this.addressesOnPreviousSession[0]?.city,
                initialValue: () => this.addresses[0]?.city,
                isRequired: () => this.isEligibleForPsoMarketDiscount,
                isHidden: () => !this.isEligibleForPsoMarketDiscount,
                maxLength: 32,
                validate: field => this._validateCityOfResidence(field.value)
            }),

        };
    }

    private _validateCityOfResidence(value: NullableString): NullableString {
        const pattern = /^[a-zA-ZÀ-ÿ'().\- ]*$/;
        if(value && !pattern.test(value)) {
            return this.services.language.translate('Not a valid value');
        }

        return null;
    }


    private readonly _companion: PassengerAttachedCompanionModel;
    get companion(): IPassengerAttachedCompanionViewModel {
        return this._companion;
    }

    async saveAsCompanion(): Promise<void> {
        await this._companion.saveAsCompanion();
    }

    computeTitle(): NullableString {
        return this.passengerType.implicitTitleValue
                || this._companion.companionTitle
                || this.services.personTitle.personTitleFromGender(this.fields.gender.value)
                || null
    }

    private _getFirstAndLastName(): {firstName: string; lastName: string} {
        const firstName = this.fields.firstName.value;
        const lastName = this.fields.lastName.value;
        if(firstName && lastName) {
            return {
                firstName: firstName,
                lastName: lastName
            }
        }

        return {
            firstName: this.passengerType.description,
            lastName: (this.passengerTypeIndex + 1).toString()
        }
    }

    getFullName(): string {
        const {firstName, lastName} = this._getFirstAndLastName();
        return `${firstName} ${lastName}`;
    }

    getPassengerFirstName(): string {
        return this._getFirstAndLastName().firstName;
    }

    getInitials(): string {
        const {firstName, lastName} = this._getFirstAndLastName();
        return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }

    computeMinimumBirthDate(): NullableDate {

        const passengerType = this.passengerType;
        const journeys = this.booking.unfilteredJourneys;

        if(Check.isNullOrUndefined(journeys[0])) {
            throw new Error(`Minimum birth date cannot be computed for a booking without departure journey`);
        }

        if(Check.isNullOrUndefined(journeys[1])) {
            return passengerType.computeMinimumBirthDate(journeys[0].designator.departureDate);
        }


        return passengerType.computeMinimumBirthDate(journeys[1].designator.departureDate);
    }

    computeMaximumBirthDate(): Date {
        const passengerType = this.passengerType;
        const journeys = this.booking.unfilteredJourneys;
        if(Check.isNullOrUndefined(journeys[0])) {
            throw new Error(`Maximum birth date cannot be computed for a booking with no journey`);
        }
        return passengerType.computeMaximumBirthDate(journeys[0].designator.departureDate);
    }

    dispose(): void {
        this._companion.dispose();
    }

    get hasSameNameAsOneOfTheCompanions(): boolean {
        for (let companion of this.services.user.profile.getCompanionsForBooking()) {
            if(companion.fields.firstName.value?.toUpperCase() === this.fields.firstName.value?.toUpperCase()
                && companion.fields.lastName.value?.toUpperCase() === this.fields.lastName.value?.toUpperCase()) {
                return true;
            }
        }

        return false;
    }

    protected _onFieldsCreated(fields: FormFields<IPassengerInfoFields>) {
        super._onFieldsCreated(fields);

        const updateShouldSaveAsCompanion = () => {
            if(this.hasSameNameAsOneOfTheCompanions) {
                this.companion.shouldSaveAsCompanion = false;
            }
        }

        fields.lastName.onChange(updateShouldSaveAsCompanion);
        fields.firstName.onChange(updateShouldSaveAsCompanion);
        fields.dateOfBirth.onChange(value => {
            if(this.isPrimaryContact) {
                this.booking.contact.fields.dateOfBirth.setValue(value);
            }
        });
    }

    async executeNameChange(): Promise<ValidationResultEnum> {
        if(!this.hasChanges()) {
            return ValidationResultEnum.Success;
        }


        if(this.activateErrorsValidation().length > 0) {
            return ValidationResultEnum.Failure;
        }

        if(this.booking.passengers.validateDuplicatedPassengersNames() === ValidationResultEnum.Failure) {
            return ValidationResultEnum.Failure;
        }

        if(this.nameChangeBlockingReason) {
            return ValidationResultEnum.Failure;
        }

        if(this.booking.insurance.hasPurchasedInsurance) {
            await this.services.dialogFactory.showSimpleMessageDialog({
                title: this.services.language.translate('Important information about Travel Insurance'),
                message: this.services.language.translate(`Your booking contains Travel Protection with COVID19 Cover. If any passenger name changes, you will need to contact the insurance provider to update your policy.`),
                buttonText: this.services.language.translate('I acknowledge')
            });
        }

        this._startNameChangeMutation();
        return ValidationResultEnum.Success;
    }


    /**
     * IFeeOwner.passengerTypeCode implementation
     */
    get passengerTypeCode(): string {
        return this.passengerType.code;
    }

    get isEligibleForPsoMarketDiscount(): boolean {
        return this.booking.pso.isEligibleForPsoMarketDiscount;
    }

}
