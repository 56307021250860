import React from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../services/booking/models/passenger/passenger-view-model.interface";
import styled from "styled-components";
import {PassengerSelectedSpecialAssistanceSsrsComponent} from "./special-assistance/passenger-selected-special-assistance-ssrs.component";
import {PrimaryContactToggleComponent} from "./primary-contact-toggle.component";
import { PersonCircleFilledIcon } from "../../../../assets/svg/person-circle-filled.icon";
import { CardBox } from "../../../../basic-components/card/card.box";
import {PersonNameFieldEditorComponent} from "../../../../components/forms/person/person-name-field-editor.component";
import {SaveAsCompanionToggleComponent} from "./save-as-companion-toggle.component";
import {AvailableBookingCompanionsComponent} from "./available-booking-companions.component";
import {AttachedCompanionComponent} from "./attached-companion.component";
import {DateFieldEditorComponent} from "../../../../components/forms/date-time/date-field-editor.component";
import {StringFieldEditorComponent} from "../../../../components/forms/string-field/string-field-editor.component";
import {CountryPickerComponent} from "../../../../components/country-picker/country-picker.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {TravelDocumentTypeFieldEditorComponent} from "../../../../components/forms/travel-document-type/travel-document-type-field-editor.component";
import {GenderFieldEditorComponent} from "../../../../components/forms/gender/gender-field-editor.component";
import {useContainerMediaQueriesChecks} from "../../../../hooks/use-container-media-queries-checks.hook";
import {ChildHandsUpCircleFilledIcon} from "../../../../assets/svg/child-hands-up-circle-filled.icon";
import {InfantCircleFilledIcon} from "../../../../assets/svg/infant-circle-filled.icon";
import {PsoPassengerTypeSelectorComponent} from "./pso-passenger-type-selector.component";
import {PsoPassengerCitySelectorComponent} from "./pso-passenger-city-selector.component";
import {ReducedMobilitySsrToggleComponent} from "./special-assistance/reduced-mobility-ssr-toggle.component";


const PassengerCardBox = styled(CardBox)`
    padding: calc(2.25 * ${props => props.theme.spacing.cardDefaultPadding});
    ${props => props.theme.containerMediaQuery.smallScreen}  {
        padding: ${props => props.theme.spacing.cardDefaultPadding};
    }
`


const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    gap: ${props => props.theme.spacing.spacing8};
`

const PersonIconAndPassengerNameBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${props => props.theme.spacing.spacing8};
`


const PassengerDetailsBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const FieldsContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap24};
    width: 100%;
`

const FieldsGridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${props => props.theme.spacing.flexGap20};
    row-gap: ${props => props.theme.spacing.flexGap30};
    ${props => props.theme.containerMediaQuery.smallScreen} {
        grid-template-columns: 1fr;
    }
    
`

const LineBox = styled.hr`
    margin: 0;
    background-color: ${props => props.theme.border.mainColor};
    width: 100%;
`


interface PassengerDetailsEditorComponentProps {
    passenger: IPassengerViewModel;
    onEnterPassengerDetails: (passenger: IPassengerViewModel) => void;
}

export const PassengerDetailsEditorComponent: React.FC<PassengerDetailsEditorComponentProps> = observer((props) => {
    const services = useServices();
    const mediaQuery = useContainerMediaQueriesChecks();
    const passengerFields = props.passenger.fields;


    const onFocusHandler = () => {
        props.onEnterPassengerDetails(props.passenger);
    }

    const renderFieldsForPsoMarket = () => {
        const travelDocumentFields = props.passenger.travelDocument.fields;
        let minimumTravelDocumentIssuedDate = passengerFields.dateOfBirth.value;
        let maximumTravelDocumentIssuedDate = props.passenger.travelDocument.computeMaximumTravelDocumentIssuedDate();
        let minimumTravelDocumentExpiration = props.passenger.travelDocument.computeMinimumTravelDocumentExpirationDate();
        let maximumTravelDocumentExpiration = services.time.addYears(services.time.currentDate, 100);

        return (
            <>
                <PsoPassengerTypeSelectorComponent key={"PSO_PASSENGER_TYPE"}
                                                   field={passengerFields.psoPassengerType}/>

                <PersonNameFieldEditorComponent key={passengerFields.firstName.fieldName}
                                                field={passengerFields.firstName}/>

                <PersonNameFieldEditorComponent key={passengerFields.lastName.fieldName}
                                                field={passengerFields.lastName}/>

                <GenderFieldEditorComponent key={travelDocumentFields.gender.fieldName}
                                            field={travelDocumentFields.gender}
                                            useGenderAsNumber={false}/>

                <DateFieldEditorComponent key={travelDocumentFields.dateOfBirth.fieldName}
                                          field={travelDocumentFields.dateOfBirth}
                                          minimumValue={props.passenger.computeMinimumBirthDate()}
                                          maximumValue={props.passenger.computeMaximumBirthDate()}/>


                <PsoPassengerCitySelectorComponent key={passengerFields.addressCity.fieldName} passenger={props.passenger}/>

                <CountryPickerComponent key={travelDocumentFields.nationality.fieldName}
                                        field={travelDocumentFields.nationality}
                                        popupHeaderTitle={services.language.translate('Search citizenship')}/>




                <TravelDocumentTypeFieldEditorComponent key={travelDocumentFields.documentTypeCode.fieldName}
                                                        field={travelDocumentFields.documentTypeCode}
                                                        onlyDocumentsForPsoMarkets={true}/>

                <StringFieldEditorComponent key={travelDocumentFields.number.fieldName}
                                            field={travelDocumentFields.number}/>

                <DateFieldEditorComponent key={travelDocumentFields.issuedDate.fieldName}
                                          field={travelDocumentFields.issuedDate}
                                          minimumValue={minimumTravelDocumentIssuedDate}
                                          maximumValue={maximumTravelDocumentIssuedDate}/>
                <DateFieldEditorComponent key={travelDocumentFields.expirationDate.fieldName}
                                          field={travelDocumentFields.expirationDate}
                                          minimumValue={minimumTravelDocumentExpiration}
                                          maximumValue={maximumTravelDocumentExpiration}/>
                <CountryPickerComponent key={travelDocumentFields.issuedByCode.fieldName}
                                        field={travelDocumentFields.issuedByCode}/>
            </>
        );
    }

    const renderPassengerFields = () => {
        if(props.passenger.companion.isAttached && !props.passenger.isEligibleForPsoMarketDiscount) {
            return (
                <AttachedCompanionComponent passenger={props.passenger}/>
            )
        }

        if(props.passenger.isEligibleForPsoMarketDiscount) {
            return renderFieldsForPsoMarket();
        }

        return (<>
            <PersonNameFieldEditorComponent key={passengerFields.firstName.fieldName}
                                            field={passengerFields.firstName}/>
            <PersonNameFieldEditorComponent key={passengerFields.lastName.fieldName}
                                            field={passengerFields.lastName}/>

            <DateFieldEditorComponent key={passengerFields.dateOfBirth.fieldName}
                                      field={passengerFields.dateOfBirth}
                                      minimumValue={props.passenger.computeMinimumBirthDate()}
                                      maximumValue={props.passenger.computeMaximumBirthDate()}/>

        </>);
    }


    const renderPrimaryContactToggleForLargeScreen = () => {
        if(mediaQuery.smallScreen) {
            return null;
        }

        if(!props.passenger.allowPsoReducedMobilityDiscount) {
            return null;
        }

        return (
            <PrimaryContactToggleComponent passenger={props.passenger}/>
        )
    }

    const renderPrimaryContactToggleForSmallScreen = () => {
        if(!mediaQuery.smallScreen) {
            return null;
        }

        return (
            <>
                <PrimaryContactToggleComponent passenger={props.passenger}/>
                {props.passenger.passengerType.canBeMadePrimaryContact && <LineBox/>}
            </>
        );
    }

    const renderReducedMobilityToggleForSmallScreen = () => {
        if(!mediaQuery.smallScreen) {
            return null;
        }

        if(!props.passenger.allowPsoReducedMobilityDiscount) {
            return null;
        }

        return (
            <>
                <ReducedMobilitySsrToggleComponent passenger={props.passenger}/>
                <LineBox/>
            </>
        );
    }

    const renderPersonIcon = () => {
        if(props.passenger.passengerType.isChild) {
            return (
                <ChildHandsUpCircleFilledIcon size={1.7}/>
            )
        } else if(props.passenger.passengerType.isInfant) {
            return (
                <InfantCircleFilledIcon size={1.7}/>
            )
        } else {
            return (
                <PersonCircleFilledIcon size={1.7}/>
            );
        }
    }


    return (
        <PassengerCardBox>
            <PassengerDetailsBox onFocus={onFocusHandler}>
                <FieldsContainerBox>
                    <HeaderBox>
                        <PersonIconAndPassengerNameBox>
                            {renderPersonIcon()}
                            <div>
                                {`${props.passenger.passengerType.description} ${props.passenger.passengerTypeIndex + 1}`}
                            </div>
                        </PersonIconAndPassengerNameBox>
                        {renderPrimaryContactToggleForLargeScreen()}

                    </HeaderBox>

                    <FieldsGridBox>
                        {renderPassengerFields()}
                    </FieldsGridBox>

                    {props.passenger.companion.canBeSavedAsCompanion ? (<LineBox/>) : null}
                    <SaveAsCompanionToggleComponent passenger={props.passenger}/>
                    {renderPrimaryContactToggleForSmallScreen()}
                    {renderReducedMobilityToggleForSmallScreen()}
                    <PassengerSelectedSpecialAssistanceSsrsComponent passenger={props.passenger}/>
                </FieldsContainerBox>

                <AvailableBookingCompanionsComponent passenger={props.passenger}/>
            </PassengerDetailsBox>
        </PassengerCardBox>
    )
});
