import {IFeeModel} from "../fee-model.interface";
import {IServiceFactory} from "../../../../../service-factory.interface";
import {IDotRezPassengerFee} from "../../../../../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {Price} from "../../../../../currency/price";
import {FeeCodeEnum, FeeTypeEnum} from "../../../../../dot-rez-api/data-contracts/enums/fee-type.enum";
import {NullableString} from "../../../../../../types/nullable-types";
import {ISsrType} from "../../../../../ssr-types/ssr-types.service.interface";
import {isDiscountServiceCharge, sumOfNonTaxesServiceCharges} from "../service-charges.helpers";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../../../ssr-types/ssrs-preferred-orders-in-shopping-cart";
import {IFeeOwner} from "../fee-owner.interface";

export abstract class PassengerFeeBaseModel implements IFeeModel {
    protected constructor(protected readonly feeData: IDotRezPassengerFee,
                          protected readonly services: IServiceFactory,
                          protected readonly feeOwner: IFeeOwner) {
    }

    abstract createPrice(amount: number): Price;


    get description(): string {

        switch (this.feeCode) {
            case FeeCodeEnum.DateChangeFee:
                return this.services.language.translate('Date change fee');
            case FeeCodeEnum.SCLY:
                return this.services.language.translate('Sicilia discount');
            case FeeCodeEnum.Insurance:
                return this._getInsuranceDescription();
        }

        switch (this.feeType) {
            case FeeTypeEnum.SpoilageFee:
                return this.services.language.translate('Spoilage fee');
            case FeeTypeEnum.PenaltyFee:
                return this.services.language.translate('Penalty fee');
            case FeeTypeEnum.NameChangeFee:
                return this.services.language.translate('Name change fee');
            case FeeTypeEnum.ServiceFee:
                return this.services.language.translate('Adjustment fee');
            case FeeTypeEnum.NonFlightServiceFee:
                return this.services.language.translate('Other services');
            default:
                return this.feeType.toString();
        }
    }

    get passengerTypeCode(): string {
        return this.feeOwner.passengerTypeCode;
    }

    private _getInsuranceDescription(): string {
        if (this.note) {
            const passengerTypeCode = this.note.split('|')[0];
            if (passengerTypeCode) {
                const passengerType = this.services.passengerTypes.getPassengerType(passengerTypeCode)
                return this.services.language.translationFor('Travel insurance for {passengerType}')
                    .withParams({
                        passengerType: passengerType.description.toLowerCase()
                    });
            }
        }


        return this.services.language.translate('Travel insurance');
    }

    get createdDate(): Date {
        return this.services.time.parseIsoDate(this.feeData.createdDate);
    }

    get details(): NullableString {
        if(this.feeType === FeeTypeEnum.SpoilageFee) {
            return this.services.language.translate('You can use this amount to purchase new services');
        }

        return null;
    }

    get feeKey(): string {
        return this.feeData.passengerFeeKey;
    }

    get feeGroupKey(): string {
        if(this.ssrType) {
            return `${this.ssrType.ssrCode}_${this.priceToDisplay.toString()}`;
        } else {
            return `${this.feeType}_${this.priceToDisplay.toString()}`;
        }
    }


    get feeType(): FeeTypeEnum {
        return this.feeData.type;
    }

    get flightReference(): NullableString {
        return this.feeData.flightReference;
    }

    get feeCode(): string {
        return this.feeData.code;
    }

    get note(): NullableString {
        return this.feeData.note;
    }

    get ssrType(): ISsrType | null {
        if(this.feeData.ssrCode) {
            return this.services.ssrTypes.getSsrType(this.feeData.ssrCode);
        } else {
            return null;
        }
    }

    get ssrNumber(): number {
        return this.feeData.ssrNumber;
    }


    get quantity(): number {
        return 1;
    }

    readonly initialPrice: Price | null = null;

    get currentPrice(): Price {
        return this.createPrice(sumOfNonTaxesServiceCharges(this.feeData.serviceCharges));
    }

    get priceToDisplay(): Price {
        return this.currentPrice;
    }


    get totalToDisplay(): Price {
        return this.priceToDisplay.multiply(this.quantity);
    }

    get discount(): Price {
        const discount = this.feeData.serviceCharges.filter(serviceCharge => isDiscountServiceCharge(serviceCharge.type))
            .sum(fee => fee.amount);

        return this.createPrice(discount);
    }

    get isCommitted(): boolean {
        return this.feeData.isConfirmed;
    }

    get isPurchasedOnCurrentSession(): boolean {
        return !this.isCommitted;
    }

    get preferredOrderInLists(): number {
        if(this.ssrType) {
            return this.ssrType.preferredOrderInLists;
        }

        if(this.feeType === FeeTypeEnum.SeatFee) {
            return SSRS_PREFERRED_ORDER_IN_LISTS.SEATS;
        }

        return SSRS_PREFERRED_ORDER_IN_LISTS.OTHER;
    }

    get shouldBeDisplayedInShoppingCart(): boolean {
        return this.feeCode !== FeeCodeEnum.FLX;
    }
}
