import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../hooks/use-services.hook";
import styled from "styled-components";
import {PassengerTravelDocumentComponent} from "./components/passenger-travel-document.component";
import {BookingFlowPage} from "../../booking-flow/booking-flow.page";
import {FormComponent, FormComponentType} from "../../../components/forms/form.component";
import {DangerousGoodsAgreementFooterComponent} from "./components/dangerous-goods-agreement-footer.component";
import {DangerousGoodsAgreementContentComponent} from "./components/dangerous-goods-agreement-content.component";
import {BookingFlowPageContentOptions} from "../../booking-flow/booking-flow-page.props";


const CheckInPassengersDetailsPageContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.flexGap20};
`

const CheckInPassengersDetailsPageContent: React.FC = observer(() => {
    const services = useServices();
    const passengers = services.booking.current.passengers.getAllPersonsInTheBooking();

    if(services.booking.current.dangerousGoodsTermsAndConditionsAccepted) {
        return (
            <FormComponent type={FormComponentType.Flat}>
                <CheckInPassengersDetailsPageContentBox>
                    {passengers.map(p => <PassengerTravelDocumentComponent key={p.stablePassengerUniqueKey} passenger={p}/>)}
                </CheckInPassengersDetailsPageContentBox>
            </FormComponent>
        );
    } else {
        return (
            <DangerousGoodsAgreementContentComponent/>
        );
    }
});



export const CheckInPassengersDetailsPage: React.FC = observer(() => {
    const services = useServices();

    const shouldRenderDangerousGoodsAgreement = () => {
        if(!services.booking.currentPageCanBeRendered) {
            return false;
        }

        return !services.booking.current.dangerousGoodsTermsAndConditionsAccepted;
    }

    const getFooterRenderer = () => {
        if(!services.booking.currentPageCanBeRendered) {
            return undefined;
        }

        if(services.booking.current.dangerousGoodsTermsAndConditionsAccepted) {
            return undefined;
        }

        return () => (
            <DangerousGoodsAgreementFooterComponent/>
        );
    }

    const getTitle = () => {
        if(shouldRenderDangerousGoodsAgreement()) {
            return services.language.translate('Dangerous Goods');
        } else {
            return services.language.translate('Passengers Details');
        }
    }

    let contentOptions: BookingFlowPageContentOptions = {
        renderContent: () => (<CheckInPassengersDetailsPageContent/>)
    }

    if(shouldRenderDangerousGoodsAgreement()) {
        contentOptions.maxContentWidth = '640px';
    }

    return (
        <BookingFlowPage
                 headerOptions={{
                    title: getTitle()
                 }}
                 contentOptions={contentOptions}
                 footerOptions={{
                     renderExtraFooterContent: getFooterRenderer()
                 }}
                 shoppingCartOptions={{
                     hideContinueButton: shouldRenderDangerousGoodsAgreement(),
                     hideShoppingCart: shouldRenderDangerousGoodsAgreement(),
                     showPromotionCode: false
                 }} />

    );
});
