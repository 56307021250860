import React from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {SeatMapSegmentsSwiperSmallScreenComponent} from "./seat-map-segments-swiper.small-screen.component";
import {SeatMapPassengersSwiperSmallScreenComponent} from "./seat-map-passengers-swiper.small-screen.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {SeatMapComponent} from "../components/seat-map/seat-map.component";
import {ZIndex} from "../../../../types/z-index";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {SeatMapAnimationComponent} from "../seatmap-animation.component";
import {SeatMapMarketingMessageComponent} from "../components/marketing-message/seat-map-marketing-message.component";

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 0;
`

const HeaderContainerBox = styled.div<{$isInsideDialog: boolean}>`
    position: sticky;
    top: -1px;
    z-index: ${ZIndex.StickyHeaders};
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    ${props => props.$isInsideDialog
            ? css`
                background-color: ${props => props.theme.colors.dialogHeaderBackground};
            `
            : css`
                background-color: ${props => props.theme.colors.pageContentBackground};
                padding-left: ${props => props.theme.spacing.pageContentHorizontalPadding};
                padding-right: ${props => props.theme.spacing.pageContentHorizontalPadding};
            `
    }
`

const HeaderTopSectionBox = styled.div`
    width: 100%;
    
`

const HeaderBottomSectionBox = styled.div`
    width: 100%;
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 
             ${props => props.theme.spacing.pageContentHorizontalPadding}
             ${props => props.theme.spacing.spacing8}
             ${props => props.theme.spacing.pageContentHorizontalPadding};
    
`


export const SeatSelectionPageContentSmallScreenComponent: React.FC<{dialogHandler: IDialogConfirmationHandler | null}> = observer((props) => {
    const services = useServices();
    const segmentSeatMap = services.booking.current.seatsMapsEditors.currentSeatMap;
    if(!segmentSeatMap) {
        return null;
    }

    const renderMarketingMessage = () => {
        return (<SeatMapMarketingMessageComponent segmentSeatMap={segmentSeatMap}/>);
        /*
        if(screenMediaQuery.xsAndBelow && !segmentSeatMap.currentPassengerSegment.benefitDedicatedFreeSeatsRows) {
            return null;
        }
        return (
            <SeatMapMarketingMessageComponent segmentSeatMap={segmentSeatMap}/>
        )
         */
    }

    return (<ContainerBox>
        <HeaderContainerBox $isInsideDialog={Boolean(props.dialogHandler)}>
            <HeaderTopSectionBox>
                <SeatMapSegmentsSwiperSmallScreenComponent/>
            </HeaderTopSectionBox>
            <HeaderBottomSectionBox>
                <SeatMapPassengersSwiperSmallScreenComponent/>
            </HeaderBottomSectionBox>
        </HeaderContainerBox>
        <ContentBox>
            {renderMarketingMessage()}
            <SeatMapAnimationComponent>
                <SeatMapComponent seatMap={segmentSeatMap}/>
            </SeatMapAnimationComponent>

        </ContentBox>
    </ContainerBox>)
})