import {IRoute} from "../../../navigation/models/route.interface";
import {SeatsSelectionBaseStep} from "../common/seats-selection-base-step";
import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {IManageMyBookingRoutes} from "../../../navigation/routes/manage-my-booking/manage-my-booking.routes.interface";

export class ManageMyBookingSeatsSelectionStep extends SeatsSelectionBaseStep<IManageMyBookingRoutes> {

    protected get routes(): IManageMyBookingRoutes {
        return this.services.navigator.routes.manageMyBooking;
    }

    get route(): IRoute {
        return this.routes.selectSeats;
    }

    protected _shouldShowActivateNotification(previousStep: IWizardStep | null): boolean {
        return true;
    }

    protected async _goToNextStep(): Promise<void> {
        this._activateStepByRoute(this.routes.extras);
    }


}
