import React from "react";
import {observer} from "mobx-react";
import {useServices} from "../../../../../hooks/use-services.hook";
import styled from "styled-components";
import {CardBox} from "../../../../../basic-components/card/card.box";
import {ManageMyBookingSectionTitleBox} from "../../boxes/manage-my-booking-section-title.box";
import {ManageMyBookingPassengerComponent} from "./manage-my-booking-passenger.component";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";

const SectionContainerBox = styled(CardBox)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.pageContentVerticalPadding}; 
`

const PassengersGridBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.spacing.flexGap20};
    width: 100%;
    ${props => props.theme.containerMediaQuery.xsAndBelow} {
        grid-template-columns: 1fr;
    }
`


export const ManageMyBookingPassengersComponent: React.FC = observer(() => {
    const services = useServices();
    const booking = services.booking.current;
    const allPersonsInTheBooking = booking.passengers.getAllPersonsInTheBooking();

    const renderOnePassenger = (passenger: IPassengerViewModel) => {
        return (
            <ManageMyBookingPassengerComponent key={passenger.stablePassengerUniqueKey} passenger={passenger}/>
        );
    }

    return (
        <SectionContainerBox>
            <ManageMyBookingSectionTitleBox>
                {services.language.translate('Passengers')}
            </ManageMyBookingSectionTitleBox>
            <PassengersGridBox>
                {allPersonsInTheBooking.map(renderOnePassenger)}
            </PassengersGridBox>
        </SectionContainerBox>


    )
});
