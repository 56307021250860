import {IPassengerType} from "../passengers-types.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {NullableDate, NullableNumber, NullableString} from "../../../types/nullable-types";

export abstract class PassengerTypeBase implements IPassengerType {
    protected constructor(
        protected readonly services: IServiceFactory,
        public readonly code: string
    ) {
    }

    public abstract get implicitTitleValue(): NullableString;
    abstract getMinimumAge(): number;
    protected abstract _getMaximumAge(): NullableNumber;
    abstract get description(): string;
    abstract get canBeMadePrimaryContact(): boolean;
    abstract get isAdult(): boolean;
    abstract get isChild(): boolean;
    abstract get isInfant(): boolean;

    get isBlueBenefits(): boolean {
        return false;
    }

    get benefitDedicatedFreeSeats(): boolean {
        return false;
    }

    get benefitReducedMobilityDiscount(): boolean {
        return false;
    }

    computeMinimumBirthDate(referenceDate: Date): NullableDate {
        const max = this._getMaximumAge();
        const time = this.services.time;
        if (max) {
            return time.addDays(time.addYears(time.makeShortDate(referenceDate), -max), 1);
        }
        return time.addYears(time.currentDate, -120);

    }
    computeMaximumBirthDate(referenceDate: Date): Date {
        const min = this.getMinimumAge();
        const time = this.services.time;
        return time.addYears(time.makeShortDate(referenceDate), -min);
    }

    get ageLimitsDescription(): string {
        const min = this.getMinimumAge();
        const max = this._getMaximumAge();

        if (min && max) {
            return this.services.language.translationFor('{min}-{max} YEARS').withParams({ min: min, max: max });
        }
        if (min) {
            return this.services.language.translationFor('{min}+ YEARS').withParams({ min: min });
        }

        if (max) {
            return this.services.language.translationFor('Under {max} YEARS').withParams({ max: max });
        }

        throw new Error(`Incorrect age limits specified for passenger type ${this.code}`);
    }
}