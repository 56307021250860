import {JourneyModel} from "../../journey/journey.model";
import {NullablePrice, Price} from "../../../../currency/price";
import {IServiceFactory} from "../../../../service-factory.interface";
import {IJourneyInfantsShoppingCartViewModel} from "./journey-infants-shopping-cart-view-model.interface";
import {ShoppingCartModeEnum} from "../../../booking-strategies/booking-strategy.interface";
import {computed, makeObservable} from "mobx";
import {InfantJourneyShoppingCartModel} from "./infant-journey-shopping-cart.model";
import {IPassengerAnalyticsData} from "../../analytics/google-analytics.intefaces";

export class JourneyInfantsShoppingCartModel implements IJourneyInfantsShoppingCartViewModel {
    constructor(private readonly journey: JourneyModel,
                private readonly shoppingCartMode: ShoppingCartModeEnum) {
        makeObservable(this, {
            infantsShoppingCarts: computed
        });
    }

    get description(): string {
        const bundleName = this.journey.selectedBundle?.bundleType.bundleName || '';
        const passengerTypeDescription = this.journey.services.passengerTypes.getInfantType().description;
        return `${passengerTypeDescription} ${bundleName}`.trim();
    }

    get infantsShoppingCarts(): InfantJourneyShoppingCartModel[] {
        const result: InfantJourneyShoppingCartModel[] = [];
        this.journey.booking.passengers.forEach(p => {
            if(p.infant) {
                result.push(new InfantJourneyShoppingCartModel(p, this.journey, this.shoppingCartMode));
            }
        })
        return result;
    }

    get fareQuantity(): number {
        return this.infantsShoppingCarts.sum(ijsc => ijsc.fareQuantity);
    }

    get initialFare(): NullablePrice {
        return this.infantsShoppingCarts[0].initialFare;
    }

    get currentFare(): Price {
        return this.infantsShoppingCarts[0].currentFare;
    }

    get fareToDisplay(): Price {
        return this.infantsShoppingCarts[0].fareToDisplay;
    }

    get totalFareToDisplay(): Price {
        return this.fareToDisplay.multiply(this.fareQuantity);
    }

    private get services(): IServiceFactory {
        return this.journey.services;
    }


    private get _passengerTypeCode(): string {
        return this.services.passengerTypes.getInfantType().code;
    }


    getAnalyticsData(): IPassengerAnalyticsData {
        return { passengerTypeCode: this._passengerTypeCode, totalAmount: this.fareToDisplay.amount, quantity: this.fareQuantity }
    }

}


