import {SeatsSelectionBaseStep} from "../common/seats-selection-base-step";
import {IOnBeforeActivationContext, IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import {IBookingRoutes} from "../../../navigation/routes/booking/booking.routes.interface";
import {DialogResult} from "../../../dialog/dialog-enums";

export class BookingFlowSeatsSelectionStep extends SeatsSelectionBaseStep<IBookingRoutes> {

    protected get routes(): IBookingRoutes {
        return this.services.navigator.routes.booking;
    }

    protected async _goToNextStep(): Promise<void> {
        this._activateStepByRoute(this.routes.bags);
    }

    get route() {
        return this.routes.seats;
    }

    protected _shouldShowActivateNotification(previousStep: IWizardStep | null): boolean {
        return !Boolean(previousStep && this.routes.bags.equals(previousStep.route));
    }

    protected async _onBeforeActivation(context: IOnBeforeActivationContext): Promise<DialogResult> {
        const booking = this.booking;
        if(booking.mutationsManager.retrieveSeatsMapMutation.isMutationInProgress) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await booking.mutationsManager.retrieveSeatsMapMutation.waitForMutation();
                }
            });
        }

        return DialogResult.Accepted;
    }
}
