import {MobileWalletPaymentMethodModel} from "../mobile-wallet-payment-method.model";
import React from "react";
import {AirlineCompanyName} from "../../../../../../global-constants";
import {IGooglePayMethodViewModel} from "./google-pay-method.view-model.interface";
import {parseCommaSeparatedValues} from "../../../../../../utils/parse-comma-separated-values";
import {Lazy} from "../../../../../../utils/lazy";
import {GooglePaymentHandler, IGooglePayConfigs} from "./google-payment-handler";
import {
    IPaymentsConfiguration,
    PaymentSupportedOS, PaymentSupportedPlatformTypes
} from "../../../../../configuration/objects/payments-configuration.interface";
import {GooglePayMarkIcon} from "../../../../../../assets/svg/google-pay-mark.icon";



export class GooglePayMethodModel extends MobileWalletPaymentMethodModel implements IGooglePayMethodViewModel {

    get name(): string {
        return "Google Pay";
    }

    renderIcon(): React.ReactElement | null {
        return (<GooglePayMarkIcon />);
    }

    async canMakePayments(): Promise<boolean> {
        if(this.services.device.isHybrid) {
            return false;
        }
        return await super.canMakePayments();

        /*
        if(this.services.configuration.data.payments.enableNuvei) {
            return await super.canMakePayments();
        }

        return false;
         */
    }

    private get commonGooglePayConfigs(): IPaymentsConfiguration["googlePay"] {
        return this.services.configuration.data.payments.googlePay;
    }

    protected _getSupportedOS(): PaymentSupportedOS {
        return this.commonGooglePayConfigs.supportedOS;
    }

    protected _getSupportedPlatformsTypes(): PaymentSupportedPlatformTypes {
        return this.commonGooglePayConfigs.supportedPlatforms;
    }


    private _googlePaymentHandler: Lazy<GooglePaymentHandler> = new Lazy<GooglePaymentHandler>(() => {
        return new GooglePaymentHandler(this.booking, this._getGooglePayConfigs(), this.code);
    });
    private get googlePaymentHandler(): GooglePaymentHandler {
        return this._googlePaymentHandler.value;
    }

    protected async _canMakePayments(): Promise<boolean> {
        return await this.googlePaymentHandler.isReadyToPay();
    }

    createGooglePayButton(): HTMLElement | null {
        return this.googlePaymentHandler.createGooglePayButton();
    }

    _getGooglePayConfigs(): IGooglePayConfigs {
        const nuveiConfigs = this.services.configuration.data.pspConfigs.Nuvei;
        return {
            apiVersion: 2,
            apiVersionMinor: 0,
            gateway: nuveiConfigs.googleGateway,
            gatewayMerchantId: nuveiConfigs.googleGatewayMerchantId,
            environment: nuveiConfigs.environment === 'int' ? "TEST" : "PRODUCTION",
            merchantId: this.commonGooglePayConfigs.merchantId,
            merchantName: AirlineCompanyName,
            allowedAuthMethods: parseCommaSeparatedValues(nuveiConfigs.googleAllowedAuthMethods),
            allowedCardNetworks: parseCommaSeparatedValues(nuveiConfigs.googleAllowedCardNetworks),
            allowCreditCards: true,
            acquirerBankCountry: nuveiConfigs.acquirerBankCountry
        };
    }
}