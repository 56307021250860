import {IPassengerTypeSelector, IPassengerTypeSelectorsList} from "../passengers-types.service.interface";
import {AdultSelector} from "./adult-selector";
import {ChildSelector} from "./child-selector";
import {InfantSelector} from "./infant-selector";
import {IServiceFactory} from "../../service-factory.interface";
import {NullableString} from "../../../types/nullable-types";
import {
    IDotRezAvailabilityLowFarePassengers
} from "../../dot-rez-api/data-contracts/requests/booking/lowfare-search.request";
import {
    IDotRezAvailabilitySimpleRequestPassengers
} from "../../dot-rez-api/data-contracts/requests/booking/search-simple.request";

export abstract class PassengersTypesSelectorsListBase implements IPassengerTypeSelectorsList {

    constructor(protected readonly services: IServiceFactory) {

    }

    abstract get canAddMorePassengers(): boolean;
    abstract get maximumNumberOfPassengersAllowedOnBooking(): number;
    abstract get passengersMaximumLimitReachedErrorMessage(): NullableString;
    abstract withBlueBenefits(): boolean;

    abstract createPassengerTypesForLowFareSearch(): IDotRezAvailabilityLowFarePassengers;
    abstract createPassengerTypesForAvailabilitySearch(): IDotRezAvailabilitySimpleRequestPassengers;
    abstract createPassengerTypesForRegularSell(): IDotRezAvailabilitySimpleRequestPassengers;
    abstract createPassengerTypesForBlueBenefitsSell(): IDotRezAvailabilitySimpleRequestPassengers;
    abstract limit(maxNumberOfMaturePassengers: number): void;
    protected _allSelectors: IPassengerTypeSelector[] = [];
    getAll(): IPassengerTypeSelector[] {
        return this._allSelectors;
    }

    getSelectedOnly(): IPassengerTypeSelector[] {
        return this._allSelectors.filter(p => p.count > 0);
    }
    countAdults(): number {
        return this._countPassengerType(AdultSelector);
    }
    countChildren(): number {
        return this._countPassengerType(ChildSelector);
    }

    countAllWithoutInfants(): number {
        return this.countAdults() + this.countChildren();
    }

    countAll(): number {
        return this._allSelectors.sum(p => p.count);
    }

    countInfants(): number {
        return this._countPassengerType(InfantSelector);
    }

    findByCode(code: string): IPassengerTypeSelector {
        const index = this._allSelectors.findIndex(pTypeSelector => pTypeSelector.hasCode(code));
        if (index < 0) {
            throw new Error(`Could not find passenger type with code ${code}`);
        }
        return this._allSelectors[index];
    }

    private _countPassengerType(passengerTypeConstructor: new (...args: any[]) => IPassengerTypeSelector): number {
        return this._allSelectors.filter(selector => (selector instanceof passengerTypeConstructor))
                                 .sum(pt => pt.count);
    }
}