import {BookingMutationActionBase} from "../../../booking-mutation-action-base";
import {WaitForMutationBehaviorEnum} from "../../../booking-mutation-waiter.interface";
import {BookingModel} from "../../../../booking.model";
import {IFareToSell} from "../../../../booking-view-model.interface";
import {IDotRezPartialBookingSessionData} from "../../../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {JourneyModel} from "../../../../journey/journey.model";

export abstract class MoveJourneyMutation extends BookingMutationActionBase {
    constructor(protected readonly booking: BookingModel,
                protected fareToSell: IFareToSell,
                protected onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>) {
        super(booking, {
            waitForMutationBehavior: WaitForMutationBehaviorEnum.ThrowOnError
        });
    }

    protected abstract get currentJourneyModel(): JourneyModel | null;


    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        if(!this.currentJourneyModel) {
            throw new Error(`Cannot move journey because it doesn't exist`);
        }

        if(this.fareToSell.journeyKey === this.currentJourneyModel.journeyKey) {
            this.services.alert.showError(this.services.language.translate('There is no point to replace a flight with the same flight'));
            return {};
        }

        if(this.booking.initialBookingSnapshot.journeys.all(j => j.journeyKey !== this.currentJourneyModel?.journeyKey)) {
            //if the journey that we want to replace is not one of the initial booking
            //then we need to clear any sold seats otherwise those seats will be marked as HeldForAnotherSession
            //and then if the user goes back and selects again this journey then we won't be able to resell those seats because they are considered as locked on another session
            await this.currentJourneyModel.removeAllSeats();
        }

        /*
        await this.booking.session.moveTrip({
            fromJourneyKey: this.currentJourneyModel.journeyKey,
            journeyKey: this.fareToSell.journeyKey,
            fareKey: this.fareToSell.fareAvailabilityKey
        });

         */

        await this.services.airlineWebapi.moveFlight({
            dotRezToken: this.booking.session.token,
            fromJourneyKey: this.currentJourneyModel.journeyKey,
            toJourneyKey: this.fareToSell.journeyKey,
            fareKey: this.fareToSell.fareAvailabilityKey
        });

        const bookingSessionData = await this.booking.session.bookingStateQueryBuilder().useAllAvailabilities().getBookingState();

        await this.onAfterSell(bookingSessionData);

        return await this._moveSeats();

    }

    private async _moveSeats(): Promise<IDotRezPartialBookingSessionData> {
        if(!this.currentJourneyModel) {
            return {};
        }

        const seatsToMove = this.currentJourneyModel.getSeatsToResellOnFlightChange();

        if(seatsToMove.length === 0) {
            return {};
        }

        await this.services.airlineWebapi.moveFlightSeats({
            journeyKey: this.currentJourneyModel.journeyKey,
            dotRezToken: this.booking.token,
            seatsToMove: seatsToMove.map(s => {
                return {
                    passengerKey: s.passengerKey,
                    seatKey: s.seat.seatKey
                }
            })
        });

        return await this.booking.session.bookingStateQueryBuilder().useBookingData().useSeatMapsAvailability().getBookingState();
    }

}
