import {IServiceFactory} from "../../service-factory.interface";
import {IPassengerType, IPassengerTypeSelectorsList} from "../passengers-types.service.interface";
import {runInAction} from "mobx";
import {PassengerTypeSelectorBase} from "./passenger-type-selector-base";
import {AdultSelector} from "./adult-selector";


export class InfantSelector extends PassengerTypeSelectorBase {
    constructor(services: IServiceFactory,
                private readonly adultSelector: AdultSelector,
                passengersList: IPassengerTypeSelectorsList, count: number = 0) {
        super([services.passengerTypes.getInfantType()], services, passengersList, count);
        adultSelector.attachInfantSelector(this);
    }
    get count(): number {
        return this._count;
    }

    set count(value) {
        if (value > this.adultSelector.count) {
            this.services.alert.showError(this.services.language.translate('Only one infant per adult is allowed'));
        } else {
            runInAction(() => {
                this._count = Math.max(0, value);
            });
        }
    }

    get allowIncrement(): boolean {
        return this.count < this.adultSelector.count;
    }

    protected _getImplicitPassengerType(): IPassengerType {
        return this.passengerTypes[0];
    }

}