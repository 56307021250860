import {BookingModel} from "../../models/booking.model";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {
    UpdateBookingDataMutation
} from "../../models/mutation-actions/update-booking-data/update-booking-data.mutation";
import {
    LoadPaymentMethodsMutation
} from "../../models/mutation-actions/load-payment-methods/load-payment-methods.mutation";

export class PrePaymentOperations {
    constructor(private readonly booking: BookingModel, private readonly options: {loadPaymentMethodsAsync: boolean}) {
    }

    async executePrepaymentOperations(): Promise<void> {
        await waitForBookingMutations(this.booking);
        this.booking.mutationsManager.startMutation(new UpdateBookingDataMutation(this.booking));
        await waitForBookingMutations(this.booking);

        if(this.booking.pso.shouldRecalculatePsoDiscount) {
            await this.booking.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.pso.applyPsoMarketDiscount();
                }
            });
        }

        if(this.options.loadPaymentMethodsAsync) {
            this.booking.mutationsManager.startMutation(new LoadPaymentMethodsMutation(this.booking));
        } else {
            await this.booking.paymentHandler.loadPaymentMethods({
                showLoadingIndicator: true
            })
        }

    }
}