import React from "react";
import {observer} from "mobx-react";
import {PassengerSsrQuantityEditorComponent} from "./passenger-ssr-quantity-editor.component";
import styled from "styled-components";
import {
    IMaturePassengerViewModel
} from "../../../../services/booking/models/passenger/mature-passenger-view-model.interface";
import {
    IBookingSsrsAggregatorViewModel
} from "../../../../services/booking/models/ssrs/aggregator/booking-ssrs-aggregator-view-model.interface";
import {IJourneyViewModel} from "../../../../services/booking/models/journey/journey-view-model.interface";

const PassengerJourneySsrsBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
    width: 100%;
    border-radius: ${props => props.theme.border.defaultRadius};
`

interface PassengerJourneySsrsSelectionComponentProps {
    passenger: IMaturePassengerViewModel;
    journey: IJourneyViewModel;
    ssrsAggregator: IBookingSsrsAggregatorViewModel;

}

export const PassengerJourneySsrsSelectionComponent: React.FC<PassengerJourneySsrsSelectionComponentProps> = observer((props) => {

    const journeySsrs = props.ssrsAggregator.journeysSsrsBuckets.find(jb => jb.parentJourney.journeyKey === props.journey.journeyKey);
    if(!journeySsrs) {
        return null;
    }

    const passengerSsrsEditors = journeySsrs.getAllSsrsInTheBucket().selectMany(ssr => ssr.passengersSsrEditors).filter(pssr => pssr.passengerKey === props.passenger.passengerKey);


    return (
        <PassengerJourneySsrsBox>
            {
                passengerSsrsEditors.map((passengerSsrEditor) => {
                    return (
                        <PassengerSsrQuantityEditorComponent key={passengerSsrEditor.ssrType.ssrCode + passengerSsrEditor.stablePassengerUniqueKey}
                                                             showPriceLabel={props.ssrsAggregator.useSameOptionsForAllFlights}
                                                             passengerSsrEditor={passengerSsrEditor}/>
                    )
                })
            }

        </PassengerJourneySsrsBox>
    );
});

