import {ISessionStorageService} from "../../../storage/session-storage.service.interface";

export const BookingSessionStorageKeys = {
    departureJourneyKey: 'departureJourneyKey',
    returnJourneyKey: 'returnJourneyKey',
    newSsrQuantity: 'newSsrQuantity',
    journeyAllBags: 'journeyAllBags',
    primaryContact: 'primaryContact',
    voucherCode: 'voucherCode',
    voucherAmount: 'voucherAmount',
    voucherApplyStatus: 'voucherApplyStatus',
    infantCustomerNumber: 'infantCustomerNumber',
    isJourneySelected: 'isJourneySelected',
    currentSegmentSeatMap: 'currentSegmentSeatMap',
    currentPassengerSeatMap: 'currentPassengerSeatMap',
    bookingTimestamp: 'bookingTimestamp',
    insuranceQuotes: 'insuranceQuotes',
    hasInsurance: 'hasInsurance',
    sameForAllFlights: 'sameForAllFlights',
    checkInPreviousSelectedJourneys: 'checkInPreviousSelectedJourneys',
    pendingPaymentDetails: 'pendingPaymentDetails',
    bookingPaymentMethods: 'bookingPaymentMethods',
    paymentAttempt: 'paymentAttempt',
    analyticsPaymentDetails: 'analyticsPaymentDetails',
    selectedPaymentMethod: 'selectedPaymentMethod',
    mappedIdsWithAnalyticsModel: 'mappedIdsWithAnalyticsModel',
    analyticsDataInSession: 'analyticsDataInSession',
    invoiceRequested: 'invoiceRequested',
    dangerousGoodsTermsAndConditionsAccepted: 'dangerousGoodsAccepted',
    dataPrivacyAccepted: 'dataPrivacyAccepted',
    selectedPaymentTab: 'selectedPaymentTab',
    manageMyBookingFlow: 'manageMyBookingFlow',
    stablePassengerKey: 'stablePassengerKey'
}

export interface IBookingStorage extends ISessionStorageService {
    dispose(): void;
    reset(): void;
}
