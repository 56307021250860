import {IWizardStep} from "../../../../models/wizard/wizard.step.interface";
import React from "react";
import {DialogCloseButtonBehavior} from "../../../dialog/dialog-enums";
import {waitForBookingMutations} from "../../models/mutation-actions/wait-for-booking-mutations";
import {BookingStepBase} from "./booking-step.base";
import {
    RandomSeatAllocationDialogComponent
} from "../../../../pages/booking-flow/seat-selection/dialogs/random-seat-allocation/random-seat-allocation-dialog.component";
import {IDialogConfirmationHandler} from "../../../dialog/dialog.service.interface";
import {
    showFreeSeatsNotificationDialog
} from "../../../../pages/booking-flow/seat-selection/dialogs/show-free-seats-notification-dialog";

export abstract class SeatsSelectionBaseStep<TRoutes> extends BookingStepBase {

    protected abstract _shouldShowActivateNotification(previousStep: IWizardStep | null): boolean;
    protected abstract _goToNextStep(): Promise<void>;
    protected abstract get routes(): TRoutes;

    private _activationNotificationShown = false;
    private _randomSeatAllocationDialogWasShown = false;

    get title(): string {
        return this.services.language.translate('Seats');
    }

    private async _moveToNextStep(): Promise<void> {
        if(this.booking.mutationsManager.retrieveSsrsAvailabilityMutation.isMutationInProgress) {
            await this.services.loadingIndicator.execute({
                action: async () => {
                    await this.booking.mutationsManager.retrieveSsrsAvailabilityMutation.waitForMutation();
                }
            });
        }
        await this._goToNextStep();
    }

    async next(): Promise<void> {
        if(this._randomSeatAllocationDialogWasShown) {
            await this._moveToNextStep();
        } else {
            await waitForBookingMutations(this.booking);
            if(this._allPassengersHaveSeats()) {
                await this._moveToNextStep();
            } else {
                await this._showRandomSeatAllocationDialog();
            }
        }

    }

    async _onActivated(previousStep: IWizardStep | null): Promise<void> {
        await super._onActivated(previousStep);
        this.booking.seatsMapsEditors.moveToNextPassengerWithoutASeat();

        if(!this._activationNotificationShown && this._shouldShowActivateNotification(previousStep)) {
            this._activationNotificationShown = true;
            const allPassengersWithDedicatedFreeSeatsHaveASeat =  this.booking.getAllPassengersSegments()
                .filter(ps => ps.passengerType.benefitDedicatedFreeSeats)
                .all(ps => ps.hasSeat);

            if(!allPassengersWithDedicatedFreeSeatsHaveASeat) {
                showFreeSeatsNotificationDialog(this.booking);
            }

        }
    }


    private _allPassengersHaveSeats(): boolean {
        return this.booking.filteredJourneys.all(journey => journey.allPassengersHaveSeats);
    }


    private async _showRandomSeatAllocationDialog(): Promise<void> {

        const seatMaps = [...this.services.booking.current.seatsMapsEditors.seatMaps];
        const chosenSeatMap = seatMaps.sort((sm1, sm2) => sm2.seatsPerRowCount - sm1.seatsPerRowCount)[0];

        const renderDialogComponent = (dialogHandler: IDialogConfirmationHandler) => {
            return (<RandomSeatAllocationDialogComponent dialogHandler={dialogHandler} seatMap={chosenSeatMap} />);
        }


        this.services.analytics.seatSelectionEvents.logViewPromotion(this.booking);
        this._randomSeatAllocationDialogWasShown = true;
        await this.services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.None,
            render: renderDialogComponent,
            maxHeight: "98%",
            onAccept: async () => {
                this.services.analytics.seatSelectionEvents.logSelectPromotion(this.booking, true)
            },
            onReject: async () => {
                this.services.analytics.seatSelectionEvents.logSelectPromotion(this.booking, false)
                //await this._autoAssignSeats();
                await this._moveToNextStep();
            }
        });
    }
}
