import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import styled, {css} from "styled-components";
import {SeatMapComponent} from "../components/seat-map/seat-map.component";
import {useServices} from "../../../../hooks/use-services.hook";
import {ZIndex} from "../../../../types/z-index";
import {SeatMapPassengersLargeScreenComponent} from "./seat-map-passengers.large-screen.component";
import {SeatMapsFlightsTabsLargeScreenComponent} from "./seat-maps-flights-tabs.large-screen.component";
import {SeatMapMarketingMessageComponent} from "../components/marketing-message/seat-map-marketing-message.component";
import {SeatMapsNavigationButtonsComponent} from "../components/navigation-buttons/seat-maps-navigation-buttons.component";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {SeatMapAnimationComponent} from "../seatmap-animation.component";
import {SeatFiltersComponent} from "../components/seat-filters/seat-filters.component";

const PageBox = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1;
`

const HeaderBox = styled.div<{$isInsideDialog: boolean; $addExtraTopPadding: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: sticky;
    padding-left: ${props => props.theme.spacing.spacing24};
    width: 100%;
    z-index: ${ZIndex.StickyHeaders};
    border-bottom: 1px solid ${props => props.theme.colors.lightShade};
    ${props => props.$isInsideDialog 
        ? css`
            top: 0;
            background-color: ${props => props.theme.colors.dialogHeaderBackground};
            padding-left: calc(${props => props.theme.spacing.spacing24} + ${props => props.theme.spacing.dialogHorizontalPadding});
            padding-right: ${props => props.theme.spacing.dialogHorizontalPadding};
            `
        : css`
            top: -1px;
            background-color: ${props => props.theme.colors.pageContentBackground};
        `
    }

    ${props => props.$addExtraTopPadding && !props.$isInsideDialog
            ? css`
                padding-top: ${props => props.theme.spacing.pageHeaderVerticalPadding};
            `
            : ``
    }
    
`


const ContentBox = styled.div<{$isInsideDialog: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.spacing20};
    background-color: ${props => props.theme.colors.cardBackground};

    ${props => props.$isInsideDialog
            ? css`
            padding-left: ${props => props.theme.spacing.dialogHorizontalPadding};
            padding-right: ${props => props.theme.spacing.dialogHorizontalPadding};
            `
            : css`
            `
    }
    
`

const SideBox = styled.div<{$headerHeight: string}>`
    position: sticky;
    top: ${props => props.$headerHeight};
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    padding: ${props => props.theme.spacing.spacing20} ${props => props.theme.spacing.spacing16};
    z-index: ${ZIndex.StickyHeaders - 2};
    border-radius: ${props => props.theme.border.defaultRadius};
    ${props => props.theme.containerMediaQuery.lAndBelow} {
        flex-basis: 25%;
    }
    
`

const ContentLeftBox = styled(SideBox)`
    gap: ${props => props.theme.spacing.spacing12};
`

const ContentLeftTitleBox = styled.div`
    font-size: ${props => props.theme.fontSize.h4};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colors.darkTint};
`

const ContentRightBox = styled(SideBox)`
    min-height: 400px;
    justify-content: space-between;
`

const ContentMiddleBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`

const SeatFilters = styled(SeatFiltersComponent)`
    
    padding-bottom: ${props => props.theme.spacing.spacing12};
`

export const SeatSelectionPageContentLargeScreenComponent: React.FC<{dialogHandler: IDialogConfirmationHandler | null; scrollTop: number}> = observer((props) => {
    const services = useServices();
    const headerElementRef = React.useRef<HTMLDivElement | null>(null);
    const seatMaps = services.booking.current.seatsMapsEditors;
    const segmentSeatMap = seatMaps.currentSeatMap;
    const [headerScrollHeight, setHeaderScrollHeight] = useState("0");

    useEffect(() => {
        const el = headerElementRef.current;
        if(el) {
            setHeaderScrollHeight(`${el.scrollHeight}px`);
        }
    }, [props.scrollTop]);



    if(!segmentSeatMap) {
        return null;
    }

    const isInsideDialog = Boolean(props.dialogHandler);

    const renderSeatFilters = () => {
        if(props.scrollTop > 3 || Boolean(props.dialogHandler)) {
            return (<SeatFilters/>)
        }
        return null;
    }

    return (
        <PageBox>
            <HeaderBox $isInsideDialog={isInsideDialog} ref={headerElementRef} $addExtraTopPadding={props.scrollTop > 3}>
                <SeatMapsFlightsTabsLargeScreenComponent/>
                {renderSeatFilters()}
            </HeaderBox>
            <ContentBox $isInsideDialog={isInsideDialog}>
                <ContentLeftBox $headerHeight={headerScrollHeight}>
                    <ContentLeftTitleBox>
                        {services.language.translate('Seat selections on this flight')}
                    </ContentLeftTitleBox>
                    <SeatMapPassengersLargeScreenComponent segmentSeatMap={segmentSeatMap}/>
                </ContentLeftBox>

                <ContentMiddleBox>
                    <SeatMapAnimationComponent>
                        <SeatMapComponent seatMap={segmentSeatMap}/>
                    </SeatMapAnimationComponent>
                </ContentMiddleBox>


                <ContentRightBox $headerHeight={headerScrollHeight}>
                    <SeatMapMarketingMessageComponent segmentSeatMap={segmentSeatMap}/>
                    <SeatMapsNavigationButtonsComponent dialogHandler={props.dialogHandler}/>
                </ContentRightBox>
            </ContentBox>

        </PageBox>
    )
})