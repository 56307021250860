import {BookingModel} from "../models/booking.model";

import {IBookingStrategy, ShoppingCartModeEnum} from "./booking-strategy.interface";
import {computed, IReactionDisposer, makeObservable, observable, runInAction} from "mobx";
import {ISsrType} from "../../ssr-types/ssr-types.service.interface";
import {AvailabilityModel} from "../../flight-search/models/availability.model";
import {IServiceFactory} from "../../service-factory.interface";
import {IDotRezBookingSession} from "../../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {IBookingStrategyAnalyticsConfiguration} from "./analytics/booking-strategy-analytics-configuration.interface";
import {JourneyModel} from "../models/journey/journey.model";
import {IBookingStrategyToBookingAdapter} from "./booking-strategy-to-booking.adapter.interface";
import {BookingFlowTypeEnum} from "./booking-flow-type.enum";
import {IFareToSell} from "../models/booking-view-model.interface";
import {
    IDotRezBookingSessionData,
    IDotRezPartialBookingSessionData
} from "../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {BookingSnapshotModel} from "../models/snapshots/booking/booking-snapshot.model";
import {IRoundTripLowFareReader} from "../../low-fare/low-fare-readers/low-fare-reader.interface";
import {RoundTripLowFareReaderFactory} from "../../low-fare/round-trip-low-fare-reader-factory";
import {IFlightSearchControllerViewModel} from "../../flight-search/flight-search-controller/flight-search-controller-view-model.interface";
import {IAvailabilityFareReducer} from "../../flight-search/models/fare-reducer.interface";
import {Price} from "../../currency/price";
import {createEmptyBookingSessionData} from "../models/create-empty-booking-session-data";
import {BookingStorage} from "../models/storage/booking-storage";
import {IDotRezInitialBookingData} from "./booking-initial-data.interface";
import {NullableString} from "../../../types/nullable-types";
import {IWizard} from "../../../models/wizard/wizard.interface";
import {ISessionStorageService} from "../../storage/session-storage.service.interface";
import {IClientPaymentCallbacks} from "../../airline-webapi/requests/begin-payment-base.request";
import {IRoute} from "../../navigation/models/route.interface";
import {
    buildPaymentCanceledUrl,
    buildPaymentFailedUrl,
    buildPaymentSuccessUrl
} from "../models/payment/payment-status-query-params.enum";
import {BookingSessionStorageKeys} from "../models/storage/booking-storage.interface";


export interface IBookingStrategyOptions {
    bookingSession: IDotRezBookingSession;
    bookingSessionData: IDotRezBookingSessionData;
    searchController: IFlightSearchControllerViewModel;
    initialBookingData: IDotRezInitialBookingData | null;
}


export abstract class BookingStrategyBase implements IBookingStrategy, IBookingStrategyToBookingAdapter, IAvailabilityFareReducer {

    constructor(protected readonly services: IServiceFactory, options: IBookingStrategyOptions) {

        this._flightSearchController = options.searchController;
        if(options.initialBookingData) {
            this._initialBookingSnapshot = new BookingSnapshotModel(options.initialBookingData, services);
        } else {
            this._initialBookingSnapshot = new BookingSnapshotModel(createEmptyBookingSessionData(options.bookingSessionData.bookingData.currencyCode), services);
        }

        this._booking = new BookingModel(services, options.bookingSession, options.bookingSessionData, this);


        this._saveTokenInSessionStorage();
        this._persistInitialBookingDataInSessionStorage(options.initialBookingData);

        this._dangerousGoodsTermsAndConditionsAccepted = ('true' === this._booking.storage.getItem(BookingSessionStorageKeys.dangerousGoodsTermsAndConditionsAccepted));

        makeObservable<this, '_dangerousGoodsTermsAndConditionsAccepted'>(this, {
            steps: computed,
            _dangerousGoodsTermsAndConditionsAccepted: observable.ref,
        });
    }

    abstract get bookingFlowType(): BookingFlowTypeEnum;
    abstract get analyticsConfiguration(): IBookingStrategyAnalyticsConfiguration;
    protected abstract _createSteps(sessionStorage: ISessionStorageService): IWizard;
    abstract canShowSsr(ssrType: ISsrType): boolean;
    abstract get allowedShoppingCartModes(): ShoppingCartModeEnum[];
    abstract getPaymentRoute(): IRoute;
    abstract getFinalPriceRoute(): IRoute;
    abstract getFinalizePaymentRoute(): IRoute;
    abstract onPaymentSuccess(): Promise<void>;

    get hideFinalPriceStep(): boolean {
        if(this.services.navigator.currentRoute.equals(this.getFinalPriceRoute())) {
            return false;
        }

        if(this.booking.hasPurchasesOnCurrentSession) {
            return this.booking.balanceDue.amount > 0;
        }

        return true;

    }

    protected _reactions: IReactionDisposer[] = [];


    get agentName(): NullableString {
        return this.flightSearchController.fields.agent.value;
    }

    get availabilityPricingInformationMessage(): string {
        return this.services.language.translate('All prices are per passenger and include airport taxes');
    }


    createBookingStorage(token: string): BookingStorage {
        return new BookingStorage(token, this.services.sessionStorage);
    }

    shouldFilterOutJourney(journey: JourneyModel): boolean {
        return false;
    }

    get activeCurrency(): string {
        if(this._booking) {
            return this._booking.currency;
        } else {
            return this.services.currency.current;
        }
   }

    get showStandardAndBlueBenefitsPrice(): boolean {
        return this.services.blueBenefits.isEnabled;
    }


    private _steps: IWizard | null = null;
    get steps(): IWizard {
        if(this._steps) {
            this._steps.dispose();
        }

        this._steps = this._createSteps(this.booking.storage);

        return this._steps;
    }

    private readonly _booking: BookingModel;
    get isBookingInitialized(): boolean {
        return Boolean(this._booking);
    }

    protected _persistInitialBookingDataInSessionStorage(initialBookingData: IDotRezInitialBookingData | null): void {
        if(initialBookingData && this.services.device.isWeb) {
            this.services.sessionStorage.setJson('activeBooking.initialData', initialBookingData);
        } else {
            this.services.sessionStorage.removeItem('activeBooking.initialData');
        }
    }


    protected _saveTokenInSessionStorage(): void {
        const currentActiveToken = this.services.sessionStorage.getItem('activeBooking.token');
        if(currentActiveToken && currentActiveToken !== this.booking.session.token) {
            this.services.sessionStorage.removeItem(currentActiveToken);
        }
        this.services.sessionStorage.setItem('activeBooking.token', this.booking.session.token);
    }

    private readonly _flightSearchController: IFlightSearchControllerViewModel;
    get flightSearchController(): IFlightSearchControllerViewModel {
        return this._flightSearchController;
    }

    get booking(): BookingModel {
        return this._booking;
    }


    private _availability: AvailabilityModel | null = null;
    get availability(): AvailabilityModel {
        if(!this._availability) {
            this._availability = new AvailabilityModel(this.flightSearchController, this, this.services);

        }
        return this._availability;
    }


    private _cleanUpActiveBookingStorageKeys(): void {
        const sessionStorage = this.services.sessionStorage;
        const activeToken = sessionStorage.getItem('activeBooking.token');

        if(activeToken === this.booking.token) {
            sessionStorage.removeItem('activeBooking.initialData');
            sessionStorage.removeItem('activeBooking.searchControllerStorageKey');
            sessionStorage.removeItem('activeBooking.token');
        }

        sessionStorage.removeItem(this.booking.token);

    }

    async dispose(): Promise<void> {
        this._reactions.forEach(r => r());
        this._reactions = [];
        this._steps?.dispose();

        this._availability?.dispose();
        this._lowFareReaderFactory?.dispose();
        this._flightSearchController?.dispose();

        this._cleanUpActiveBookingStorageKeys();
        if(this._booking) {
            try {
                await this._booking.reset();

            } catch(err){
                this.services.logger.warning('Failed to reset booking on dispose', err);
            } finally {
                this._booking.dispose();
            }
        }
    }

    get allowPromoCode(): boolean {
        return false;
    }

    async upgradeCurrentBookingSessionToAuthorizedUser(userName: string, password: string): Promise<void> {

        if(this._booking) {
            await this._booking.upgradeToAuthorizedUser(userName, password);
        }
    }


    private readonly _initialBookingSnapshot: BookingSnapshotModel;
    get initialBookingSnapshot(): BookingSnapshotModel {
        return this._initialBookingSnapshot;
    }


    async sellDepartureJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        throw new Error(`This booking strategy ${this.constructor.name} doesn't support sellDepartureJourney`);
    }

    async sellDepartureJourneyBundle(bundleCode: string): Promise<void> {
        throw new Error(`This booking strategy ${this.constructor.name} doesn't support sellDepartureJourneyBundle`);
    }

    async sellReturnJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        throw new Error(`This booking strategy ${this.constructor.name} doesn't support sellReturnJourney`);
    }

    async sellReturnJourneyBundle(bundleCode: string): Promise<void> {
        throw new Error(`This booking strategy ${this.constructor.name} doesn't support sellReturnJourneyBundle`);
    }

    private _lowFareReaderFactory: RoundTripLowFareReaderFactory | null = null;
    getLowFaresReader(): IRoundTripLowFareReader {
        if(!this._lowFareReaderFactory) {
            this._lowFareReaderFactory = new RoundTripLowFareReaderFactory(this.services,
                this.flightSearchController,
                true,
                () => this.activeCurrency);
        }
        return this._lowFareReaderFactory.lowFareReader;

    }

    reduceDepartureFare(price: Price): Price {
        return price;
    }

    reduceReturnFare(price: Price): Price {
        return price;
    }

    get departureBundleNameToShowOnFare(): NullableString {
        if(this.isBookingInitialized) {
            return this.booking.departureJourney?.selectedBundle?.bundleType.bundleName || null;
        }

        return null;
    }

    get returnBundleNameToShowOnFare(): NullableString {

        if(this.isBookingInitialized) {
            return this.booking.returnJourney?.selectedBundle?.bundleType.bundleName || null;
        }

        return null;
    }

    get allowSellInsurance(): boolean {
        return false;
    }

    get shouldSyncContactWithPrimaryPassenger(): boolean {
        return false;
    }

    get supportsPayment(): boolean {
        return true;
    }

    getPaymentCallbacks(): IClientPaymentCallbacks {
        const finalizePaymentRoute = this.getFinalizePaymentRoute();
        return {
            success: buildPaymentSuccessUrl(finalizePaymentRoute.path),
            cancel: buildPaymentCanceledUrl(finalizePaymentRoute.path),
            failed: buildPaymentFailedUrl(finalizePaymentRoute.path)
        }
    }

    async goBackToFlightSelection(): Promise<void> {

    }

    private _dangerousGoodsTermsAndConditionsAccepted = false;
    get dangerousGoodsTermsAndConditionsAccepted(): boolean {
        return this._dangerousGoodsTermsAndConditionsAccepted;
    }

    set dangerousGoodsTermsAndConditionsAccepted(value: boolean) {
        runInAction(() => {
            this._dangerousGoodsTermsAndConditionsAccepted = value;
            this.booking.storage.setItem(BookingSessionStorageKeys.dangerousGoodsTermsAndConditionsAccepted, value.toString());
        })
    }

    get allowChangeSearch(): boolean {
        return false;
    }

    get shouldRenderBagsInExtras(): boolean {
        return true;
    }

    get shouldValidateBags(): boolean {
        return false;
    }
}
