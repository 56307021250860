import {Wizard} from "../../../../models/wizard/wizard";
import {
    BookingFlowDepartingFlightSelectionStep
} from "../../steps/booking-flow/booking-flow-departing-flight-selection.step";
import {BookingFlowReturnFlightSelectionStep} from "../../steps/booking-flow/booking-flow-return-flight-selection.step";
import {BookingFlowPassengerDetailsStep} from "../../steps/booking-flow/booking-flow-passenger-details.step";
import {BookingFlowSeatsSelectionStep} from "../../steps/booking-flow/booking-flow-seats-selection.step";
import {BookingFlowBagsSelectionStep} from "../../steps/booking-flow/booking-flow-bags-selection.step";
import {BookingFlowExtrasSelectionStep} from "../../steps/booking-flow/booking-flow-extras-selection.step";
import {BookingFlowPaymentStep} from "../../steps/booking-flow/booking-flow-payment.step";
import {BookingStrategyBase, IBookingStrategyOptions} from "../booking-strategy-base";
import {BookingFlowFinalPriceStep} from "../../steps/booking-flow/booking-flow-final-price.step";
import {ANALYTICS_AFFILIATIONS} from "../analytics/analytics-affiliations";
import {IBookingStrategyAnalyticsConfiguration} from "../analytics/booking-strategy-analytics-configuration.interface";
import {BookingFlowTypeEnum} from "../booking-flow-type.enum";
import {IFareToSell} from "../../models/booking-view-model.interface";
import {
    IDotRezPartialBookingSessionData
} from "../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {
    SellDepartureJourneyMutation
} from "../../models/mutation-actions/sell-trip/booking-flow/sell-departure-journey.mutation";
import {
    SellReturnJourneyMutation
} from "../../models/mutation-actions/sell-trip/booking-flow/sell-return-journey.mutation";
import {ISsrType} from "../../../ssr-types/ssr-types.service.interface";
import {
    SellDepartureJourneyBundleMutation
} from "../../models/mutation-actions/sell-bundle/sell-departure-journey-bundle.mutation";
import {
    SellReturnJourneyBundleMutation
} from "../../models/mutation-actions/sell-bundle/sell-return-journey-bundle.mutation";
import {NullableString} from "../../../../types/nullable-types";
import {ShoppingCartModeEnum} from "../booking-strategy.interface";
import {ISessionStorageService} from "../../../storage/session-storage.service.interface";
import {IServiceFactory} from "../../../service-factory.interface";
import {reaction} from "mobx";
import {AvailableTripModel} from "../../../flight-search/models/available-trip.model";
import {ILowFareResult} from "../../../low-fare/low-fare-readers/low-fare-reader.interface";
import {IRoute} from "../../../navigation/models/route.interface";
import {
    IAnalyticsFareEvent,
    IAnalyticsLowFareEvent
} from "../../models/analytics/everyMundo-analytics-flight-search.interface";


export class BookingFlowStrategy extends BookingStrategyBase {

    constructor(services: IServiceFactory, options: IBookingStrategyOptions) {
        super(services, options);

        setTimeout(() => {
            this._reactions.push(reaction(() => [this.availability.departureTrips, this.availability.returnTrips],
                async (trips) => {
                    if (trips) {
                        await this._sendSearchAnalyticsEventData(trips);
                        // this.services.analytics.flightSelectionEvents.logFlightSearchEvent(this.flightSearchController as FlightSearchControllerModel);
                        // this.services.analytics.flightSelectionEvents.logAdsRemarketingHome(this.flightSearchController as FlightSearchControllerModel)
                    }
                }, {
                    fireImmediately: true
                }))
        });

    }

    get hideFinalPriceStep(): boolean {
        return true;
    }

    private async _sendSearchAnalyticsEventData(trips: (undefined | AvailableTripModel[])[]): Promise<void> {
        if (!trips || trips.length === 0) {
            return;
        }
        // TODO: refactor this part
        const [departureTrips, returnTrips] = trips;
        let lowFareResults: IAnalyticsLowFareEvent[] = [];
        let fareResults: IAnalyticsFareEvent[] = [];
        if (departureTrips && departureTrips.length > 0 && this.flightSearchController.departureDate) {
            lowFareResults = [...lowFareResults, ...await this._getLowFareForTrip(true, this.flightSearchController.departureDate)];
            fareResults = [...fareResults, ...this._getFareForTrip(true, departureTrips)];
        }
        if (returnTrips && returnTrips.length > 0 && this.flightSearchController.returnDate) {
            lowFareResults = [...lowFareResults, ...await this._getLowFareForTrip(false, this.flightSearchController.returnDate)];
            fareResults = [...fareResults, ...this._getFareForTrip(false, returnTrips)];
        }


        this.services.analytics.logFlightSearchForEveryMundo({
            BookingFlowType: 'Standard',
            CurrencyCode: this.booking.currency,
            Device: this._formatDeviceType(),
            Fare: fareResults,
            LowFare: lowFareResults,
            FlightPassengerAdult: this.flightSearchController.passengers.countAdults(),
            FlightPassengerChild: this.flightSearchController.passengers.countChildren(),
            FlightPassengerInfant: this.flightSearchController.passengers.countInfants(),
            FlightTotalPax: this.flightSearchController.passengers.getSelectedOnly().sum(item => item.count),
            Language: this.services.language.currentLanguage
        })
    }

    private async _getLowFareForTrip(isOutbound: boolean,
                                     searchDate: Date): Promise<IAnalyticsLowFareEvent[]> {
        const getLowFareFunc = this._getLowFareStrategy(isOutbound);



        const lowFareResult: IAnalyticsLowFareEvent[] = [];

        for(let i = -3; i <= 3; i++) {
            const date = this.services.time.addDays(searchDate, i);
            const price = (await getLowFareFunc(date)).price;
            if(price) {
                lowFareResult.push({
                    Date: this.services.time.formatMM_DD_YYYY_withSlash(date),
                    LowestFare: price.amount,
                    IsOutbound: isOutbound
                })
            }
        }

        return lowFareResult;
    }

    private _getLowFareStrategy(isOutbound: boolean): (date: Date) => Promise<ILowFareResult> {
        return isOutbound ?
            (date: Date) => this.getLowFaresReader().getDepartureLowFareAsync(date) :
            (date: Date) => this.getLowFaresReader().getReturnLowFareAsync(date);
    }

    private _formatDeviceType(): string {
        return this.services.device.isMobile ? "Mobile": "Desktop";
    }


    private _getFareForTrip(isOutbound: boolean, trips: AvailableTripModel[]): IAnalyticsFareEvent[] {
        return trips
            .selectMany(trip =>
                trip.journeys.map(journey => {
                    return {
                        FlightNumber: journey.segments.map(s => s.identifier.fullIdentifierCode()).join(('|')),
                        OperatingAirline: journey.segments.map(s => s.identifier.carrierCode).distinct(c => c).join('|'),
                        FareCategory: "Economy",//static value for all flights
                        FareCategoryType: journey.fares[0].productClass, //only on product class for journey
                        FlightOutboundDate: this.services.time.formatMM_DD_YYYY_withSlash(journey.designator.departureDate),
                        FlightReturnDate: this.services.time.formatMM_DD_YYYY_withSlash(journey.designator.departureDate),
                        DepartureTime: journey.designator.formatDepartureTime(),
                        ArrivalTime: journey.designator.formatArrivalTime(),
                        FlightDuration: `${Math.floor(journey.designator.travelTime.totalHours)}hrs:${journey.designator.travelTime.minutes}mins`,
                        Layover: journey.designator.numberOfStops > 0,
                        FlightOrigin: journey.designator.origin.stationCode,
                        Destination: journey.designator.destination.stationCode,
                        FlightType: this.flightSearchController.isOneWay ? "OneWay" : "RoundTrip",
                        RewardsPresent: false, // this is the default on the old app
                        FlightRouteType: journey.segments.some(s => !s.isDomesticFlight) ? "International" : "Domestic",   // add to constants
                        IsOutbound: isOutbound,
                        Cupon: null,
                        Price: journey.fares[0].discountedPrice.amount //TODO - review this
                    } as IAnalyticsFareEvent;
                }));
    }

    get bookingFlowType(): BookingFlowTypeEnum {
        return BookingFlowTypeEnum.Booking;
    }



    canShowSsr(ssrType: ISsrType): boolean {
        return ssrType.canBeShownOnBookingFlow(this.booking);
    }

    get allowedShoppingCartModes(): ShoppingCartModeEnum[] {
        return [ShoppingCartModeEnum.ShowAllPurchases];
    }

    protected _createSteps(sessionStorage: ISessionStorageService): Wizard {
        const wizard = new Wizard(this.services, 'bookingFlowStrategy', sessionStorage);
        wizard.addStep(new BookingFlowDepartingFlightSelectionStep(this.booking, wizard))
        wizard.addStep(new BookingFlowReturnFlightSelectionStep(this.booking, wizard))
        wizard.addStep(new BookingFlowPassengerDetailsStep(this.booking, wizard));
        wizard.addStep(new BookingFlowSeatsSelectionStep(this.booking, wizard));
        wizard.addStep(new BookingFlowBagsSelectionStep(this.booking, wizard));
        wizard.addStep(new BookingFlowExtrasSelectionStep(this.booking, wizard));
        wizard.addStep(new BookingFlowFinalPriceStep(this.booking, wizard));
        wizard.addStep(new BookingFlowPaymentStep(this.booking, wizard));
        return wizard;
    }


    get allowPromoCode(): boolean {
        return true;
    }

    get analyticsConfiguration(): IBookingStrategyAnalyticsConfiguration {
        return {
            affiliation: ANALYTICS_AFFILIATIONS.booking,
            shouldReportFares: true
        }
    }

    async sellDepartureJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        if (this.booking.departureJourney?.wasSoldFromThisFare(fareToSell)) {
            return;
        }

        const mutation = new SellDepartureJourneyMutation(
            this.booking,
            fareToSell,
            async (bookingSessionData) => {
                await onAfterSell(bookingSessionData);
            });

        this.booking.mutationsManager.startSellDepartureJourneyMutation(mutation);

        await mutation.waitForMutation();
    }

    async sellDepartureJourneyBundle(bundleCode: string): Promise<void> {
        const departureJourney = this.booking.departureJourney;
        if (!departureJourney) {
            throw new Error('You cannot sell departure journey bundle if there is no departure journey');
        }

        if (bundleCode === departureJourney.selectedBundle?.bundleCode) {
            return;
        }

        departureJourney.markBundleAsSelected(bundleCode); //we need to do this in order for the UI to update and not waiting for the request to finish
        this.booking.mutationsManager.startMutation(new SellDepartureJourneyBundleMutation(this.booking, bundleCode));
        this.booking.mutationsManager.startRetrieveSsrsAvailability();
        this.booking.mutationsManager.startRetrieveSeatsMap();
    }

    async sellReturnJourney(fareToSell: IFareToSell, onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>): Promise<void> {
        if (this.booking.returnJourney?.wasSoldFromThisFare(fareToSell)) {
            return;
        }

        const mutation = new SellReturnJourneyMutation(this.booking,
            fareToSell,
            async (bookingSessionData) => {
                await onAfterSell(bookingSessionData);
            });

        this.booking.mutationsManager.startSellReturnJourneyMutation(mutation);

        await mutation.waitForMutation();
    }

    async sellReturnJourneyBundle(bundleCode: string): Promise<void> {

        const returnJourney = this.booking.returnJourney;

        if (!returnJourney) {
            throw new Error('You cannot sell return journey bundle if there is no return journey');
        }

        if (bundleCode === returnJourney.selectedBundle?.bundleCode) {
            return;
        }

        returnJourney.markBundleAsSelected(bundleCode); //we need to do this in order for the UI to update and not waiting for the request to finish

        this.booking.mutationsManager.startMutation(new SellReturnJourneyBundleMutation(this.booking, bundleCode));
        this.booking.mutationsManager.startRetrieveSsrsAvailability();
        this.booking.mutationsManager.startRetrieveSeatsMap();
    }

    get departureBundleNameToShowOnFare(): NullableString {
        // on booking flow we don't want to show the bundle name above the fare because we have a dedicated step for bundle selection
        return null;
    }

    get returnBundleNameToShowOnFare(): NullableString {
        // on booking flow we don't want to show the bundle name above the fare because we have a dedicated step for bundle selection
        return null;
    }

    get allowSellInsurance(): boolean {
        return this.services.configuration.envConfigs.enableInsurance;
    }

    get shouldSyncContactWithPrimaryPassenger(): boolean {
        return true;
    }

    getFinalPriceRoute(): IRoute {
        return this.services.navigator.routes.booking.finalPrice;
    }
    getPaymentRoute(): IRoute {
        return this.services.navigator.routes.booking.payment;
    }

    getFinalizePaymentRoute(): IRoute {
        return this.services.navigator.routes.booking.finalizePayment;
    }


    async onPaymentSuccess(): Promise<void> {
        await this.services.booking.switchToManageMyBooking();
    }

    async goBackToFlightSelection(): Promise<void> {
        await this.steps.goToStepByRoute(this.services.navigator.routes.booking.departingFlight);
    }

    get allowChangeSearch(): boolean {
        return true;
    }

    get shouldRenderBagsInExtras(): boolean {
        return false;
    }

    get shouldValidateBags(): boolean {
        return true;
    }
}
