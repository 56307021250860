import {IServiceFactory} from "../../../../service-factory.interface";
import {SpecialAssistanceSsrBase} from "../special-assistance-ssr-base";

//PREGNANT WOMAN
export default class PregSsrType extends SpecialAssistanceSsrBase {
    constructor(services: IServiceFactory) {
        super('PREG', services);
    }

    get benefitPsoDiscount(): boolean {
        return false;
    }
}
