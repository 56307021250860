import React from "react";
import styled from "styled-components";
import { useServices } from "../../../../../hooks/use-services.hook";
import { FlightItineraryAccordionHeaderComponent } from "../flight-itinerary-accordion-header.component";
import { FlightItineraryAccordionComponent } from "../flight-itinerary-accordion.component";
import {observer} from "mobx-react-lite";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";
import {PersonCircleFilledIcon} from "../../../../../assets/svg/person-circle-filled.icon";

const PassengerInfoBox = styled.div`
    display: flex;
    flex-direction: row;
`;

const PassengerNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    color: ${props => props.theme.colors.primaryTint};
    margin-left: ${props => props.theme.spacing.spacing12};
`;

const PassengerInfoComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const passenger = props.passenger;
    return (
        <PassengerInfoBox key={passenger.stablePassengerUniqueKey}>
            <PersonCircleFilledIcon size={2.4}/>
            <PassengerNameBox>
                {passenger.getFullName()}
            </PassengerNameBox>
        </PassengerInfoBox>
    )
});

export const FlightItineraryPassengersComponent: React.FC = () => {
    const services = useServices();

    const renderAccordionHeader = () => {
        return(
            <FlightItineraryAccordionHeaderComponent renderIcon={() => <PersonCircleFilledIcon/>}
                                                     title={services.language.translate('Passengers')} />
        )
    }

    const renderAccordionContent = () => {
        const passengers = services.booking.current.passengers.getAllPersonsInTheBooking();
        return(
            <>
                {passengers.map((passenger) => {
                    return (
                        <React.Fragment key={passenger.stablePassengerUniqueKey}>
                            <VerticalSpacer size={"medium"}/>
                            <PassengerInfoComponent passenger={passenger}/>

                        </React.Fragment>
                    );
                })}
            </>
        )
    }

    return(
        <FlightItineraryAccordionComponent renderHeader={renderAccordionHeader}
                                           renderContent={renderAccordionContent}/>
    )
}
