import {IServiceFactory} from "../../../../service-factory.interface";
import {OnlineCheckInBlockerSsr} from "../../online-check-in-blocker-ssr";
import {SSRS_PREFERRED_ORDER_IN_LISTS} from "../../../ssrs-preferred-orders-in-shopping-cart";
import React from "react";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";
import {
    SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE,
    SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE,
    SSR_LARGE_ICON_SIZE
} from "../../../ssr-types.service.interface";


//This SSR doesn't inherit SpecialAssistanceSsrBase on purpose because we want to handle it separately
export default class PrmpSsrType extends OnlineCheckInBlockerSsr {
    constructor(services: IServiceFactory) {
        super('PRMP', services);
    }

    get benefitPsoDiscount(): boolean {
        return true;
    }

    get preferredOrderInLists(): number {
        return SSRS_PREFERRED_ORDER_IN_LISTS.SPECIAL_ASSISTANCE;
    }

    get smallIcon(): React.ReactElement | null {
        return <WheelchairIcon/>;
    }

    get largeIcon(): React.ReactElement | null {
        return <WheelchairIcon size={SSR_LARGE_ICON_SIZE}/>;
    }

    get iconInExtrasFirstLargeTile(): React.ReactElement | null {
        return (<WheelchairIcon size={SSR_ICON_SIZE_IN_EXTRAS_FIRST_LARGE_TILE}/>);
    }

    get iconInExtrasSmallTile(): React.ReactElement | null {
        return (<WheelchairIcon size={SSR_ICON_SIZE_IN_EXTRAS_SMALL_TILE}/>);
    }
}
