import {MaturePassengerModel} from "./mature-passenger.model";
import {PassengersListViewModel} from "./passengers-list-view-model";
import {BookingModel} from "../booking.model";
import {ValidationResultEnum} from "../../../../types/validation-result.enum";
import {IFormField} from "../../../../models/forms/form-field.interface";
import {SavePassengersDetailsMutation} from "../mutation-actions/passengers/save-passengers-details.mutation";
import {IServiceFactory} from "../../../service-factory.interface";
import {NullableString} from "../../../../types/nullable-types";
import {InfantPassengerModel} from "./infant-passenger.model";

export class PassengersListModel extends PassengersListViewModel<MaturePassengerModel> {
    countPassengerType(typeCode: string): number {
        return this.filter(p => p.passengerType.code === typeCode).length;
    }

    countAllPassengers(): number {
        return this.length;
    }

    countInfants(): number {
        return this.filter(p => Boolean(p.infant)).length;
    }

    countAdults(): number {
        return this.filter(p => p.passengerType.isAdult).length;
    }

    countChildren(): number {
        return this.filter(p => p.passengerType.isChild).length;
    }

    getByPassengerKey(passengerKey: string): MaturePassengerModel {
        const passenger = this.filter(p => p.passengerKey === passengerKey)[0];
        if(passenger) {
            return passenger;
        }

        throw new Error(`There is no passenger with key ${passengerKey}`);
    }

     private _getDuplicatedPassengersNames(): string[] {
        const groupedByFullName = this.getAllPersonsInTheBooking().groupByKey(p => p.getFullName().toUpperCase());
        return Object.keys(groupedByFullName)
              .filter(fullName => groupedByFullName[fullName].length > 1);
    }


    private get booking(): BookingModel {
        return this[0].booking;
    }

    private get services(): IServiceFactory {
        return this.booking.services;
    }

    validatePassengersDetails(): ValidationResultEnum {
        let fieldsWithErrors: IFormField[] = [];
        let noPrimaryContact = true;
        this.forEach(passenger => {
            fieldsWithErrors = [
                ...fieldsWithErrors,
                ...passenger.activateErrorsValidation({setFocusOnFirstError: false})
            ];

            if(passenger.isPrimaryContact) {
                fieldsWithErrors = [
                    ...fieldsWithErrors,
                    ...this.booking.contact.activateErrorsValidation({setFocusOnFirstError: false})
                ];
                noPrimaryContact = false;
            }
        });

        this.forEach(passenger => {
            if(passenger.infant) {
                fieldsWithErrors = [
                    ...fieldsWithErrors,
                    ...passenger.infant.activateErrorsValidation({setFocusOnFirstError: false})
                ];
            }
        })

        fieldsWithErrors = [
            ...fieldsWithErrors,
            ...(noPrimaryContact ? this.booking.contact.activateErrorsValidation({setFocusOnFirstError: false}) : [])
        ];

        if(fieldsWithErrors.length > 0) {
            fieldsWithErrors[0].setFocus();
            return ValidationResultEnum.Failure;
        }

        return this.validateDuplicatedPassengersNames();
    }

    validateDuplicatedPassengersNames(): ValidationResultEnum {
        const duplicatedNames = this._getDuplicatedPassengersNames();
        if(duplicatedNames.length > 0) {
            const msg = this.services.language.translationFor('Passenger names must be unique. The following passenger names appears more than once: {passengersNames}').withParams({passengersNames: duplicatedNames.join(', ')});
            this.services.alert.showError(msg);
            return ValidationResultEnum.Failure
        }

        return ValidationResultEnum.Success;
    }

    async savePassengersDetails(): Promise<ValidationResultEnum> {

        const validationResult = this.validatePassengersDetails();

        if(validationResult === ValidationResultEnum.Success) {
            this.booking.mutationsManager.startMutation(new SavePassengersDetailsMutation(this.booking));
        }

        return validationResult;
    }

    private async _savePassengerTravelDocument(passenger: MaturePassengerModel): Promise<NullableString> {
        try {
            await passenger.travelDocument.saveTravelDocument();
        } catch (err) {
            this.services.logger.error(`Failed to save travel document for passenger ${passenger.getFullName()}`, err);
            return passenger.getFullName();
        }
        return null;
    }

    private async _saveInfantTravelDocument(infant: InfantPassengerModel): Promise<NullableString> {
        try {
            await infant.travelDocument.saveTravelDocument();
        } catch (err) {
            this.services.logger.error(`Failed to save travel document for infant ${infant.getFullName()}`, err);
            return infant.getFullName();
        }

        return null;
    }

    async saveTravelDocuments(): Promise<void> {

        if(this.length === 0) {
            return;
        }

        const passengersResult = await Promise.all(this.map(passenger => this._savePassengerTravelDocument(passenger)))
        const infantResult = await Promise.all(this.filter(p => p.infant).map(passenger => this._saveInfantTravelDocument(passenger.infant!)));

        const failedPassengersNames = [
            ...passengersResult,
            ...infantResult
        ].filter(name => name)
         .map(name => name as string);

        if(failedPassengersNames.length === 1) {
            this.services.alert.showError(this.services.language.translationFor('Failed to save travel document for passenger {passengerName}').withParams({
                passengerName: failedPassengersNames[0]
            }));
        } else if(failedPassengersNames.length > 1) {
            this.services.alert.showError(this.services.language.translationFor('Failed to save travel document for the following passengers: {passengersNames}').withParams({
                passengersNames: failedPassengersNames.join(', ')
            }));
        }
    }
}
