import {NullableNumber, NullableString} from "../../../types/nullable-types";
import {IServiceFactory} from "../../service-factory.interface";
import {PassengerTypeCodesEnum} from "../passenger-type-codes.enum";
import {PassengerTypeBase} from "./passenger-type-base";

export abstract class AdultBase extends PassengerTypeBase {
    get description(): string {
        return this.services.language.translate('Adult');
    }
    get implicitTitleValue(): NullableString {
        return null;
    }
    getMinimumAge(): number {
        return 12;
    }

    get isAdult(): boolean {
        return true;
    }

    get isChild(): boolean {
        return false;
    }

    get isInfant(): boolean {
        return false;
    }
    protected _getMaximumAge(): NullableNumber {
        return null;
    }

    get canBeMadePrimaryContact(): boolean {
        return true;
    }
}

export class Adult extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.Adult);
    }
}

export class AdultBlueBenefits extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.AdultClubMember);
    }

    get isBlueBenefits(): boolean {
        return true;
    }
}

export class AdultWithChild extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.AdultWithChild);
    }

    get benefitDedicatedFreeSeats(): boolean {
        return true;
    }
}

export class AdultWithReducedMobility extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.AdultWithReducedMobility);
    }

    get benefitReducedMobilityDiscount(): boolean {
        return true;
    }
}

/**
 * Adult that is at the same time a companion for a child and for another adult with reduced mobility
 */
export class AdultWithChildAndCompanionForPRM extends AdultBase {
    constructor(services: IServiceFactory) {
        super(services, PassengerTypeCodesEnum.AdultWithAndChildAndCompanionForPRM);
    }

    get benefitReducedMobilityDiscount(): boolean {
        return true;
    }

    get benefitDedicatedFreeSeats(): boolean {
        return true;
    }
}