import React, {useState} from "react";
import {observer} from "mobx-react";
import {IPassengerViewModel} from "../../../../../services/booking/models/passenger/passenger-view-model.interface";
import {IonToggle} from "@ionic/react";
import styled from "styled-components";
import {useServices} from "../../../../../hooks/use-services.hook";
import {WheelchairIcon} from "../../../../../assets/svg/wheelchair.icon";

const ToggleContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 6px;
    font-size: ${props => props.theme.fontSize.subtitle2};
    font-weight: ${props => props.theme.fontWeight.medium};
    
    justify-content: flex-end;
    ${props => props.theme.screenMediaQuery.smallScreen} {
        justify-content: space-between;
    }
    
    
`

const LabelBox = styled.div`
    flex-grow: 1;
`

const IconBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`

const LabelAndIconContainerBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    max-width: min(100%, 330px);
    ${props => props.theme.screenMediaQuery.smallScreen} {
        max-width: unset;
    }
`


export const ReducedMobilitySsrToggleComponent: React.FC<{passenger: IPassengerViewModel}> = observer((props) => {
    const services = useServices();
    const booking = services.booking.current;

    const [ssrsAggregator] = useState(booking.createSsrsAggregator({
        ssrTypes: [services.ssrTypes.PRMP],
        onlyForPassengers: () => [props.passenger],
    }));

    ssrsAggregator.useSameOptionsForAllFlights = true;

    if(!props.passenger.allowPsoReducedMobilityDiscount) {
        return null;
    }

    if(!ssrsAggregator.canBeModifiedForAllFlightsAndAllPassengers) {
        return null;
    }

    const toggleSsrQuantity = () => {
        let newQuantity: number;
        if(ssrsAggregator.totalSsrsQuantity > 0) {
            newQuantity = 0;
        } else {
            newQuantity = 1;
        }
        ssrsAggregator.journeysSsrsBuckets.forEach((bucket) => {
            bucket.getAllSsrsInTheBucket().forEach(ssr => {

                ssr.applySameQuantityForAllPassengers(newQuantity);
            });
        });
    }

    const onLabelClick = () => {
        toggleSsrQuantity();
    }


    return (
        <ToggleContainerBox>
            <LabelAndIconContainerBox onClick={onLabelClick}>
                <IconBox>
                    <WheelchairIcon size={1.5}/>
                </IconBox>

                <LabelBox>
                    {booking.pso.getReducedMobilitySsrTranslation()}
                </LabelBox>
            </LabelAndIconContainerBox>

            <IonToggle checked={ssrsAggregator.totalSsrsQuantity > 0}
                       onIonChange={() => toggleSsrQuantity()}/>
        </ToggleContainerBox>
    );
})