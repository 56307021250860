import {MobileWalletPaymentMethodModel} from "../mobile-wallet-payment-method.model";
import React from "react";
import {LogoApplePayIcon} from "../../../../../../assets/svg/logo-apple-pay.icon";
import {IApplePayConfigs, IApplePayMethodViewModel} from "./apple-pay-method.view-model.interface";
import {parseCommaSeparatedValues} from "../../../../../../utils/parse-comma-separated-values";
import {AuthorizePaymentEvent, CapacitorApplePay, ValidateMerchantEvent} from "@jackobo/capacitor-apple-pay";
import {MobileWalletProviderEnum} from "../../../../../airline-webapi/requests/begin-booking-payment.request";
import {ValidationResultEnum} from "../../../../../../types/validation-result.enum";
import {isPaymentSuccess} from "../../../../../airline-webapi/enums/payment-transaction-status.enum";
import {AirlineCompanyName} from "../../../../../../global-constants";
import {
    IPaymentsConfiguration,
    PaymentSupportedOS,
    PaymentSupportedPlatformTypes
} from "../../../../../configuration/objects/payments-configuration.interface";

export class ApplePayMethodModel extends MobileWalletPaymentMethodModel implements IApplePayMethodViewModel{

    get name(): string {
        return "Apple Pay";
    }

    async canMakePayments(): Promise<boolean> {
        return await super.canMakePayments();

        /*
        if(this.services.configuration.data.payments.enableNuvei) {
            return await super.canMakePayments();
        }

        return false;
         */
    }

    renderIcon(): React.ReactElement | null {
        return (<LogoApplePayIcon />);
    }

    private get commonApplePayConfigs(): IPaymentsConfiguration["applePay"] {
        return this.services.configuration.data.payments.applePay;
    }

    protected _getSupportedOS(): PaymentSupportedOS {
        return this.commonApplePayConfigs.supportedOS;
    }

    protected _getSupportedPlatformsTypes(): PaymentSupportedPlatformTypes {
        return this.commonApplePayConfigs.supportedPlatforms;
    }


    getApplePayConfigs(): IApplePayConfigs {
        const nuveiConfigs = this.services.configuration.data.pspConfigs.Nuvei;
        return {
            merchantId: this.commonApplePayConfigs.merchantId,
            acquirerBankCountry: nuveiConfigs.acquirerBankCountry,
            merchantCapabilities: parseCommaSeparatedValues(nuveiConfigs.appleMerchantCapabilities),
            supportedNetworks: parseCommaSeparatedValues(nuveiConfigs.appleSupportedNetworks)
        }
    }

    protected async _canMakePayments(): Promise<boolean> {
        return Boolean((await CapacitorApplePay.canMakePayments())?.canMakePayments);
    }

    async pay(): Promise<void> {

        const validationResult = await this.booking.paymentHandler.performStartBookingPaymentValidations();
        if(validationResult.result !== ValidationResultEnum.Success) {
            return;
        }

        await CapacitorApplePay.addListener('validateMerchant', async (event: ValidateMerchantEvent) => {
            const response = await this.services.airlineWebapi.applePayValidateMerchant({
                validationUrl: event.validationURL
            });

            await CapacitorApplePay.completeMerchantValidation({
                merchantSession: response.merchantSession
            })
        });

        await CapacitorApplePay.addListener('authorizePayment', async (event: AuthorizePaymentEvent) => {
            try {
                const paymentResult = await this.booking.paymentHandler.startBookingPayment({
                    paymentMethodCode: this.code,
                    mobileWalletProvider: MobileWalletProviderEnum.ApplePay,
                    mobileWalletToken: JSON.stringify(event.payment),
                    skipValidations: true
                })

                if(isPaymentSuccess(paymentResult.transactionStatus)) {
                    await CapacitorApplePay.paymentAuthorizationSuccess();
                } else {
                    await CapacitorApplePay.paymentAuthorizationFail();
                }
            } finally {
                await CapacitorApplePay.removeAllListeners();
            }

        });

        await CapacitorApplePay.addListener('cancel', async () => {
            await CapacitorApplePay.removeAllListeners();
        });

        const configs = this.getApplePayConfigs();


        await CapacitorApplePay.startPayment({
            merchantId: configs.merchantId,
            countryCode: configs.acquirerBankCountry,
            currencyCode: this.booking.currency,
            supportedNetworks: configs.supportedNetworks,
            merchantCapabilities: configs.merchantCapabilities,
            total: {
                label: AirlineCompanyName,
                amount: this.booking.balanceDue.amount.toFixed(2)
            }
        });



    }
}