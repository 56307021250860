import {IServiceFactory} from "../service-factory.interface";
import {
    IStationConfiguration,
    IStationMarketConfiguration
} from "../configuration/objects/stations-configurations.interface";
import {ICountryConfiguration} from "../configuration/objects/country-configuration.interface";
import {IStation} from "./station.interface";
import {NullableString, NullableUndefinedString} from "../../types/nullable-types";
import {
    IPsoMarketConfiguration, IPsoDiscountConfiguration,
    IPsoMarketSeasonConfiguration
} from "../configuration/objects/pso-markets-configuration.interface";
import {BookingModel} from "../booking/models/booking.model";
import {IDotRezBookingData} from "../dot-rez-api/data-contracts/booking/booking-state/booking-state.data-contracts";
import {IDotRezBookingSession} from "../dot-rez-api/session/booking-session/dot-rez-booking.session.interface";
import {PassengerFeeModel} from "../booking/models/fees/passenger/passenger-fee.model";
import {IApplyPsoDiscountFeeResponse} from "../airline-webapi/responses/apply-pso-discount-fee.response";

export class Mac {
    constructor(public readonly code: string,
                public readonly name: string,
                public readonly macStations: string[],
                public readonly countryCode: string) {
    }
}

export type NullableMac = Mac | null;

export class PsoMarketSeason {
    constructor(private readonly seasonConfig: IPsoMarketSeasonConfiguration) {
    }

    get name(): string {
        return this.seasonConfig.name;
    }

    get enabled(): boolean {
        return this.seasonConfig.enabled;
    }

    appliesToBundle(bundle: string): boolean {
        return this.enabled && bundle === this.seasonConfig.bundle;
    }

    get agent(): string {
        return this.seasonConfig.agent;
    }
}

export class PsoMarketDiscountOptions {
    constructor(private readonly discountConfig: IPsoDiscountConfiguration,
                public  readonly departureDateUntil: Date) {
    }

    async applyDiscount(booking: BookingModel): Promise<void> {
        await booking.mutationsManager.waitForMutations();

        const applyDiscountFeeResult = await booking.services.airlineWebapi.applyPsoDiscountFee({
            dotRezToken:  booking.token,
            journeysAmounts: booking.shoppingCart.journeysAllPurchases.map(j => {
                return {
                    key: j.journeyKey,
                    amount: j.grandTotalToDisplay.amount
                }
            })
        });


        const bookingData = await this._getBookingData(booking.session);

        if(bookingData) {
            booking.updateBookingSessionData({
                bookingData: bookingData
            });
        }

        await this._updateSsrs(booking, applyDiscountFeeResult);
    }

    private async _getBookingData(session: IDotRezBookingSession): Promise<IDotRezBookingData | undefined> {
        const {bookingData} = await session.bookingStateQueryBuilder().useBookingData().getBookingState();

        return bookingData;
    }


    hasPsoDiscountFee(booking: BookingModel): boolean {
        return Boolean(this._findFeeModel(booking));
    }

    private async _updateSsrs(booking: BookingModel, psoDiscountResult: IApplyPsoDiscountFeeResponse): Promise<void> {

        const ssrType = booking.services.ssrTypes.getSsrType(this.discountConfig.ssrCode);

        for(let journey of booking.unfilteredJourneys) {
            const hasDiscountFee = psoDiscountResult.journeysKeys.includes(journey.journeyKey);
            for(let ps of journey.getAllPassengersSegments()) {
                if(hasDiscountFee) {
                    ps.getSsr(ssrType).newQuantity = 1;
                } else {
                    ps.getSsr(ssrType).newQuantity = 0;
                }
            }
        }

        await booking.sellSsrs({skipDebounce: true});

        await booking.mutationsManager.waitForMutations();
    }

    private _findFeeModel(booking: BookingModel): PassengerFeeModel | null {
        for(let p of booking.passengers) {
            let f = p.fees.find(f => f.feeCode === this.discountConfig.feeCode) ?? null;
            if(f) {
                return f;
            }
        }
        return null;
    }

}


export class PsoMarket {
    constructor(private readonly configuration: IPsoMarketConfiguration,
                private readonly services: IServiceFactory) {
        this._seasons = (configuration.seasons ?? []).map(seasonConfig => new PsoMarketSeason(seasonConfig));
    }

    private readonly _seasons: PsoMarketSeason[];

    get marketName(): string {
        return this.configuration.marketName;
    }

    getAgent(forActiveSeason: boolean): string {
        if(forActiveSeason && this.activeSeason) {
            return this.activeSeason.agent;
        } else {
            return this.configuration.agent;
        }
    }

    get bookDateUntil(): Date {
        return this.services.time.parseIsoDate(this.configuration.bookDateUntil);
    }


    get departureDateUntil(): Date {
        return this.services.time.parseIsoDate(this.configuration.departureDateUntil);
    }

    get isSicilia(): boolean {
        return this.configuration.marketName.toLowerCase() === 'sicilia';
    }

    get allowReducedMobilityDiscount(): boolean {
        return this.configuration.allowReducedMobilityDiscount;
    }

    getMarketNameTranslation(): string {
        switch (this.configuration.marketName.toLowerCase()) {
            case 'sardinia':
                return this.services.language.translate('Sardinia');
            case 'marche':
                return this.services.language.translate('Marche');
            case 'sicilia':
                return this.services.language.translate('Sicilia');
            default:
                const marketName = this.configuration.marketName;
                return marketName[0].toUpperCase() + marketName.substring(1);
        }
    }

    getAgentForBundle(bundle: string): NullableString {
        if(bundle === this.configuration.bundle) {
            return this.configuration.agent;
        }

        return this._seasons.find(s => s.appliesToBundle(bundle))?.agent ?? null;
    }

    get activeSeason(): PsoMarketSeason | null {
        return this._seasons.find(s => s.enabled) ?? null;
    }

    get discountOptions(): PsoMarketDiscountOptions | null {
        if(this.configuration.discountOptions) {
            return new PsoMarketDiscountOptions(this.configuration.discountOptions, this.departureDateUntil);
        }

        return null;
    }
}

export class Station implements IStation {
    constructor(private readonly services: IServiceFactory,
                private readonly stationConfig: IStationConfiguration,
                private readonly mac: NullableMac) {
    }

    get stationCode(): string {
        return this.stationConfig.code;
    }

    get stationMacCode(): string {
        if(this.mac?.code) {
            return this.mac.code;
        }

        return this.stationCode;
    }

    get stationName(): string {
        return this.stationConfig.name;
    }

    get stationMacName(): string {
        if(this.mac?.name) {
            return this.mac.name;
        }

        return this.stationName;
    }

    get marketsConfig(): IStationMarketConfiguration[] {
        return this.stationConfig.markets
    }

    get allMacStations(): string[] {
        return this.mac?.macStations ?? [];
    }

    private get country(): ICountryConfiguration | null {
        const country = this.services.country.tryGetCountry(this.stationConfig.countryCode);
        if(!country) {
            this.services.logger.error(`Missing country code "${this.stationConfig.countryCode}" for station ${this.stationCode}`);
        }
        return country;
    }

    get countryCode(): string {
        return this.country?.code || '';
    }

    get countryName(): string {
        return this.country?.name || '';
    }

    get markets(): Station[] {
        const markets: Station[] = []
        this.marketsConfig.forEach(m => {
            let station = this.services.stations.tryGetStation(m.code);
            if(station) {
                markets.push(station);
                if(station.mac) {
                    station = this.services.stations.tryGetStation(station.mac.code);
                    if(station) {
                        markets.push(station);
                    }
                }
            }
        });
        return markets.distinct(m => m.stationCode);
    }

    isOnlineCheckInAllowed(destination: Station): boolean {
        const market = this.marketsConfig.find(m => m.code === destination.stationCode);
        if(market) {
            return market.allowedOnlineCheckIn;
        }

        return false;
    }
}

export type NullableStation = Station | null;
export type NullableUndefinedStation = Station | null | undefined;

export interface IStationService {
    getAllStations(): Station[];
    findStations(stationCodes: string[]): Station[];
    getStation(stationCode: string): Station;
    tryGetStation(stationCode: NullableUndefinedString): NullableStation;
    stationExists(stationCode: string): boolean;
    groupStationsAlphabetically(stations: Station[]): Record<string, Station[]>;
    getPsoMarket(from: NullableStation, to: NullableStation): PsoMarket | null;
}


