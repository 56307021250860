import {computed, makeObservable, observable, reaction} from "mobx";
import {
    IStationService,
    Mac,
    NullableStation, PsoMarket,
    Station
} from "./station.service.interface";
import {Check} from "../../types/type-checking";
import {ServiceBase} from "../service-base";
import {IServiceFactory} from "../service-factory.interface";
import {NullableUndefinedString} from "../../types/nullable-types";
import {IStationMarketConfiguration} from "../configuration/objects/stations-configurations.interface";


export class StationService extends ServiceBase implements IStationService {
    constructor(services: IServiceFactory) {
        super(services);

        makeObservable(this, {
            _macsMap: observable.ref,
            _stationsMap: observable.ref,
            _psoMarkets: observable.ref,
            stations: computed
        });

        reaction(() => this.services.configuration.data,
            (data) => {
                this._macsMap = data.macs.toDictionaryOfType(m => m.code.toUpperCase(), m => new Mac(m.code.toUpperCase(), m.name, m.macStations, m.countryCode));
                this._stationsMap = data.stations.toDictionaryOfType(stationConfig => stationConfig.code, stationConfig => {
                    if(stationConfig.macCode) {
                        return new Station(this.services, stationConfig, this._macsMap[stationConfig.macCode.toUpperCase()] ?? null);
                    } else {
                        return new Station(this.services, stationConfig, null);
                    }
                });

                this._addMacsToTheListOfStations(this._macsMap);

                this._psoMarkets = this._readPsoMarkets();


            }, {
                fireImmediately: true
            });

    }

    _macsMap: Record<string, Mac> = {};
    _stationsMap: Record<string, Station> = {};


    get stations(): Station[] {
        return Object.values(this._stationsMap).sort((s1, s2) => s1.stationName.localeCompare(s2.stationName));
    }

    private _addMacsToTheListOfStations(macs: Record<string, Mac>): void {
        Object.values(macs).forEach(mac => {
            let marketsConfigs: IStationMarketConfiguration[] = [];

            mac.macStations.forEach(macStation => {
                marketsConfigs = [
                    ...marketsConfigs,
                    ...(this._stationsMap[macStation]?.marketsConfig ?? [])
                ];
            });

            this._stationsMap[mac.code] = new Station(this.services, {
                code: mac.code,
                name: mac.name,
                macCode: mac.code,
                countryCode: mac.countryCode,
                markets: marketsConfigs.distinct(m => m.code)
            }, mac);
        });

    }

    getAllStations(): Station[] {
        return this.stations.filter(s => s.markets.length > 0);
    }

    findStations(stationCodes: string[]): Station[] {
        return this.stations.filter(station => stationCodes.includes(station.stationCode));
    }

    getStation(stationCode: string): Station {
        const station = this.tryGetStation(stationCode)
        if(station) {
            return station;
        }
        throw new Error(`There is no station with code ${stationCode}`);
    }

    tryGetStation(stationCode: NullableUndefinedString): NullableStation {
        if(!stationCode) {
            return null;
        }
        const searchResult = this.findStations([stationCode]);
        if(searchResult.length === 0) {
            return null;
        }

        return searchResult[0];
    }

    stationExists(stationCode: string): boolean {
        return !Check.isNullOrUndefined(this.tryGetStation(stationCode));
    }

    groupStationsAlphabetically(stations: Station[]): Record<string, Station[]> {
        const result: Record<string, Station[]> = {};

        for (const station of stations.sort((s1, s2) => s1.stationName.localeCompare(s2.stationName))) {
            const firstLetter = station.stationName[0].toUpperCase();

            if (!result[firstLetter]) {
                result[firstLetter] = [];
            }

            result[firstLetter].push(station);
        }

        return result;
    }

    _psoMarkets: Record<string, Record<string, PsoMarket>> = {};
    private _readPsoMarkets(): Record<string, Record<string, PsoMarket>> {

        const psoMarkets = this.services.configuration.data.specialPriceMarketsV2.markets;


        const result: Record<string, Record<string, PsoMarket>> = {};

        for (let market of psoMarkets) {
            // if(market.marketName === 'sardinia') {
            //     market.routes.push(['FCO', 'CAG']);
            // }
            for (let pair of market.routes) {
                const from = pair[0]?.toUpperCase();
                const to = pair[1]?.toUpperCase();

                if (!from || !to) {
                    continue;
                }

                if (!result[from]) {
                    result[from] = {};
                }

                const psoMarket = new PsoMarket(market, this.services);
                result[from][to] = psoMarket;

                if (!result[to]) {
                    result[to] = {};
                }

                result[to][from] = psoMarket;
            }
        }
        return result;
    }


    getPsoMarket(from: NullableStation, to: NullableStation): PsoMarket | null {
        if(!from || !to) {
            return null;
        }

        let fromStations: string[] = [from.stationCode];
        let toStations: string[] = [to.stationCode];

        if(this._macsMap[from.stationCode]) {
            fromStations = this._macsMap[from.stationCode].macStations;
        }

        if(this._macsMap[to.stationCode]) {
            toStations = this._macsMap[to.stationCode].macStations;
        }

        for(let origin of fromStations) {
            for(let destination of toStations) {
                const relatedPsoMarkets = this._psoMarkets[origin];
                if(relatedPsoMarkets && relatedPsoMarkets[destination]) {
                    return relatedPsoMarkets[destination];
                }
            }
        }
        return null;
    }
}
