import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {
    ISegmentSeatMapEditorViewModel
} from "../../../../services/booking/models/seat-maps/segment-seat-map-editor-view-model.interface";
import {SeatMapPassengerComponent} from "../components/passengers/seat-map-passenger.component";
import {
    IPassengerSegmentViewModel
} from "../../../../services/booking/models/passenger-segment/passenger-segment-view-model.interface";

const PassengersBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.spacing12};
    width: 100%;
`

export const SeatMapPassengersLargeScreenComponent: React.FC<{segmentSeatMap: ISegmentSeatMapEditorViewModel}> = observer((props) => {
    let passengers = props.segmentSeatMap.segment.passengers;
    /*
    passengers = [
        ...passengers,
        ...passengers,
        ...passengers,
        ...passengers,
        ...passengers,
    ]

     */

    const renderOnePassenger = (passenger: IPassengerSegmentViewModel) => {

        return (
            <SeatMapPassengerComponent key={passenger.passengerSegmentStableUniqueKey}
                                       passengerSegment={passenger}
                                       segmentSeatMap={props.segmentSeatMap}/>
        );
    }

    return (
        <PassengersBox>
            {passengers.map(renderOnePassenger)}
        </PassengersBox>
    )
});