import React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useServices} from "../../../../hooks/use-services.hook";
import {IPassengerSsrEditorViewModel} from "../../../../services/booking/models/ssrs/passenger-ssr-editor-view-model.interface";
import {SeatSsrValidationDialogComponent} from "../../../../components/seat-ssr-validation-dialog/seat-ssr-validation-dialog.component";
import {IServiceFactory} from "../../../../services/service-factory.interface";
import {PriceWithDiscountComponent} from "../../../../components/price/price-with-discount.component";
import {StandardPriceLabelComponent} from "../../../../components/price/standard-price-label.component";
import {QuantityDecrementButtonComponent, QuantityIncrementButtonComponent} from "../../../../components/quantity-edit-buttons/quantity-edit-buttons";
import {SsrModificationBlockingReasonComponent} from "../ssr-modification-blocking-reason.component";

const PassengerSsrQuantityEditorBox = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.colors.mediumShade};
    border-bottom: none;
    padding: ${props => props.theme.spacing.spacing12} ${props => props.theme.spacing.spacing16};
    gap: ${props => props.theme.spacing.flexGap12};
    &:first-of-type {
        border-top-left-radius: ${props => props.theme.border.defaultRadius};
        border-top-right-radius: ${props => props.theme.border.defaultRadius};
    }

    &:last-of-type {
        border-bottom-left-radius: ${props => props.theme.border.defaultRadius};
        border-bottom-right-radius: ${props => props.theme.border.defaultRadius};
        border-bottom: 1px solid ${props => props.theme.colors.mediumTint};
    }
`

const DetailsBox = styled.div`
    font-size: ${props => props.theme.fontSize.body2};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SsrBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
`

const SsrNameBox = styled.div`
    font-size: ${props => props.theme.fontSize.h5};
    font-weight: ${props => props.theme.fontWeight.semiBold};
`;

const SsrDescriptionBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    color: ${props => props.theme.colors.mediumTint};
`

const PriceBox = styled.div`
    font-size: ${props => props.theme.fontSize.h6};
    font-weight: ${props => props.theme.fontWeight.medium};
    color: ${props => props.theme.colors.primary};
`


const SsrQuantityBox = styled.div`
    font-size: ${props => props.theme.fontSize.h2};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    text-align: center;
`

const QuantityPlusMinusButtonsBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.spacing.spacing16};
    line-height: 1;
`




async function incrementQuantity(passengerSsrEditor: IPassengerSsrEditorViewModel, services: IServiceFactory, onIncrement: () => void): Promise<void> {
    const restrictedSeats = passengerSsrEditor.getRestrictedSeats();
    if(restrictedSeats.length > 0) {
        await services.dialog.showSheetModalDialog({
            backdropDismiss: false,
            render: modalHandler => (<SeatSsrValidationDialogComponent dialogHandler={modalHandler}
                                                                       seats={restrictedSeats}
                                                                       ssrType={passengerSsrEditor.ssrType}/>),
            onAccept: async () => {
                onIncrement();
                await services.loadingIndicator.execute({
                    action: async () => {
                        for(let seat of restrictedSeats) {
                            if(seat.assignedTo) {
                                await seat.assignedTo.removeSeat();
                            }
                        }

                        await services.booking.current.mutationsManager.waitForMutations();
                        await services.booking.current.sellSsrs({skipDebounce: true});
                        await services.booking.current.mutationsManager.waitForMutations();
                    }
                });

                const passengerSegment = services.booking.current.getAllPassengersSegments().find(ps => ps.passenger.passengerKey === passengerSsrEditor.passengerKey && !ps.hasSeat);

                if(passengerSegment) {
                    services.booking.current.seatsMapsEditors.setCurrentPassengerSegment(passengerSegment);
                }

                await services.dialogFactory.showSeatsSelection();
            }
        });
    } else {
        onIncrement();
    }
}



interface QuantityPlusMinusButtonsComponentProps {
    passengerSsrEditor: IPassengerSsrEditorViewModel;
}

const QuantityPlusMinusButtonsComponent: React.FC<QuantityPlusMinusButtonsComponentProps> = observer((props) => {
    const services = useServices();

    const canIncrement = props.passengerSsrEditor.canBeModifiedForAtLeastOneSegment && props.passengerSsrEditor.newQuantity < props.passengerSsrEditor.maxAllowedQuantity;
    const canDecrement = props.passengerSsrEditor.canBeModifiedForAtLeastOneSegment && props.passengerSsrEditor.newQuantity > props.passengerSsrEditor.minAllowedQuantity;

    const canBeChanged = canIncrement || canDecrement;



    const onIncrementQuantity = async () => {
        if(!canIncrement) {
            return;
        }
        await incrementQuantity(props.passengerSsrEditor,
            services,
            () => {
                props.passengerSsrEditor.newQuantity++;
            });
    }

    const onDecrementQuantity = () => {
        if(!canDecrement) {
            return;
        }
        props.passengerSsrEditor.newQuantity--;
    }

    return(
        <QuantityPlusMinusButtonsBox>
            <QuantityDecrementButtonComponent disabled={!canDecrement}
                                              onClick={onDecrementQuantity}/>
            {
                canBeChanged && (<SsrQuantityBox>
                    {props.passengerSsrEditor.newQuantityToDisplay}
                </SsrQuantityBox>)
            }

            <QuantityIncrementButtonComponent disabled={!canIncrement}
                                              onClick={onIncrementQuantity}/>
        </QuantityPlusMinusButtonsBox>
    )
});


interface PassengerSsrQuantitySelectionComponentProps {
    passengerSsrEditor: IPassengerSsrEditorViewModel;
    showPriceLabel: boolean;
}

export const PassengerSsrQuantityEditorComponent: React.FC<PassengerSsrQuantitySelectionComponentProps> = observer((props) => {

    const renderSsrDetails = () => {
        if (!props.passengerSsrEditor.ssrType.details) {
            return null;
        }
        return (<SsrDescriptionBox>{props.passengerSsrEditor.ssrType.details}</SsrDescriptionBox>);
    }


    const renderPriceLabel = () => {
        if(props.showPriceLabel) {
            return (<StandardPriceLabelComponent/>);
        }
        return null;
    }

    return (
        <PassengerSsrQuantityEditorBox>
            <DetailsBox>
                <SsrBox>
                    <SsrNameBox>
                        {props.passengerSsrEditor.ssrType.name}
                    </SsrNameBox>
                    {renderSsrDetails()}
                    <PriceBox>
                        <PriceWithDiscountComponent discountedPrice={props.passengerSsrEditor.discountedMinPrice}
                                                    standardPrice={props.passengerSsrEditor.standardMinPrice}
                                                    label={renderPriceLabel()}/>
                    </PriceBox>
                </SsrBox>

                <QuantityPlusMinusButtonsComponent passengerSsrEditor={props.passengerSsrEditor}/>
            </DetailsBox>
            <SsrModificationBlockingReasonComponent reason={props.passengerSsrEditor.modificationBlockingReason}/>
        </PassengerSsrQuantityEditorBox>
    )
});
