import {
    IPassengerType,
    IPassengerTypeSelector,
    IPassengerTypeSelectorsList
} from "../passengers-types.service.interface";
import {IServiceFactory} from "../../service-factory.interface";
import {makeObservable, observable, runInAction} from "mobx";
import {
    IDotRezAvailabilitySimpleRequestPassengers
} from "../../dot-rez-api/data-contracts/requests/booking/search-simple.request";


export abstract class PassengerTypeSelectorBase implements IPassengerTypeSelector {
    protected constructor(protected passengerTypes: IPassengerType[],
                          protected services: IServiceFactory,
                          protected passengersSelectionList: IPassengerTypeSelectorsList,
                          public _count: number = 0) {

        makeObservable(this, {
            _count: observable.ref
        });
    }

    protected abstract _getImplicitPassengerType(): IPassengerType;

    hasCode(code: string): boolean {
        return this.passengerTypes.some(passengerType => passengerType.code === code);
    }

    getCodesForAvailabilitySearch(): IDotRezAvailabilitySimpleRequestPassengers {
        let code = this.getCurrentCode();
        if(this.passengersSelectionList.withBlueBenefits()) {
            const blueBenefitsType = this.passengerTypes.find(pt => pt.isBlueBenefits);
            if(blueBenefitsType) {
                code = blueBenefitsType.code;
            }
        }

        return [
            {
                count: this.count,
                type: code
            }
        ];
    }

    getCurrentCode(): string {
        return this._getCurrentPassengerType().code;
    }

    private _getCurrentPassengerType(): IPassengerType {
        if(this.passengersSelectionList.withBlueBenefits()) {
            const passengerType = this.passengerTypes.find(pt => pt.isBlueBenefits);
            if(passengerType) {
                return passengerType;
            }
        }

        return this._getImplicitPassengerType();
    }

    getCodeForRegularSell(): string {
        return this._getCurrentPassengerType().code;
    }

    getCodeForBlueBenefitsSell(): string {
        const blueBenefitsType = this.passengerTypes.find(pt => pt.isBlueBenefits);
        if(blueBenefitsType) {
            return blueBenefitsType.code;
        }

        return this._getCurrentPassengerType().code;
    }

    getAllCodes(): string[] {
        return this.passengerTypes.map(passengerType => passengerType.code);
    }

    get description(): string {
        return this._getCurrentPassengerType().description;
    }

    get ageLimitsDescription(): string {
        return this._getCurrentPassengerType().ageLimitsDescription;
    }

    get minimumAge(): number {
        return this._getCurrentPassengerType().getMinimumAge();
    }

    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._setCount(value);
    }

    get allowDecrement(): boolean {
        return this.count > this._getMinimumCount();
    }

    get allowIncrement(): boolean {
        return this.passengersSelectionList.canAddMorePassengers;
    }

    protected _setCount(value: number): void {
        if (value > this._count && !this.passengersSelectionList.canAddMorePassengers) {
            if(this.passengersSelectionList.withBlueBenefits()) {
                this.services.alert.showError(this.services.language.translationFor(`Your Blue Benefits subscription allows maximum {numberOfPassengers} passengers on a booking`).withParams({
                    numberOfPassengers: this.services.user.profile.blueBenefitsSubscription.maxNumberOfPassengers
                }));
            } else {
                this.services.alert.showError(this.services.language.translationFor(`Maximum number of passengers allowed on a booking is {numberOfPassengers}`).withParams({
                    numberOfPassengers: this.passengersSelectionList.maximumNumberOfPassengersAllowedOnBooking
                }));
            }

            return;
        }

        if (value < this._getMinimumCount()) {
            return;
        }

        runInAction(() => {
            this._count = Math.max(0, value);
        });
    }

    get summary(): string {
        return `${this.count} x ${this.description}`;
    }

    protected _getMinimumCount(): number {
        return 0;
    }
}