import {IBookingViewModel} from "../../../../services/booking/models/booking-view-model.interface";
import {IDialogConfirmationHandler} from "../../../../services/dialog/dialog.service.interface";
import {FreeSeatsNotificationDialogComponent} from "./free-seats-notification-dialog.component";
import {DialogCloseButtonBehavior} from "../../../../services/dialog/dialog-enums";
import React from "react";

export async function showFreeSeatsNotificationDialog(booking: IBookingViewModel): Promise<void> {
    if(!booking.hasPassengersThatBenefitDedicatedFreeSeatsRows) {
        return;
    }
    const services = booking.services;

    const renderDialog = (dialogHandler: IDialogConfirmationHandler) => (<FreeSeatsNotificationDialogComponent dialogHandler={dialogHandler} booking={booking} />);




    if(booking.services.screenMediaQuery.smallScreen) {
        const computeBreakpoints = () => {
            if(window.innerHeight <= 540) {
                return [1];
            } else  if(window.innerHeight <= 660) {
                return [0.75, 0.85, 1];
            } else if(window.innerHeight <= 800){
                return [0.65, 0.8, 1];
            } else {
                return [0.55, 0.75, 1];
            }

        }
        await services.dialog.showSheetModalDialog({
            breakpoints:  computeBreakpoints(),
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            render: renderDialog
        })
    } else {
        await services.dialog.showPopupDialog({
            closeButtonBehavior: DialogCloseButtonBehavior.Accept,
            width: '420px',
            render: renderDialog
        })
    }
}