import {BookingMutationActionBase} from "../../../booking-mutation-action-base";
import {BookingModel} from "../../../../booking.model";
import {IFareToSell} from "../../../../booking-view-model.interface";
import {IDotRezPartialBookingSessionData} from "../../../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {WaitForMutationBehaviorEnum} from "../../../booking-mutation-waiter.interface";
import {JourneyModel} from "../../../../journey/journey.model";
import {JourneySnapshotModel} from "../../../../snapshots/journey/journey-snapshot.model";
import {IDotRezSsrToAdd} from "../../../../../../dot-rez-api/data-contracts/requests/booking/update-ssrs.request";
import {
    IBookingStateQueryBuilder
} from "../../../../../../dot-rez-api/graph-ql/queries/booking-state-query-builder.interface";
import {
    IDotRezCancelJourneyRequest
} from "../../../../../../dot-rez-api/data-contracts/requests/booking/cancel-journey.request";

export abstract class VoluntaryJourneyChangeMutation extends BookingMutationActionBase {
    constructor(protected readonly booking: BookingModel,
                protected fareToSell: IFareToSell,
                protected hasFreeChange: boolean,
                protected _onAfterSell: (bookingSessionData: IDotRezPartialBookingSessionData) => Promise<void>) {
        super(booking, {
            waitForMutationBehavior: WaitForMutationBehaviorEnum.ThrowOnError
        });
    }

    protected abstract get currentJourneyModel(): JourneyModel | null;
    protected abstract get initialJourneySnapshot(): JourneySnapshotModel | null;

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {

        if(!this.currentJourneyModel) {
            throw new Error(`Cannot replace journey because it doesn't exist`);
        }

        const journeyKeyToReplace = this.currentJourneyModel.journeyKey;

        if(this.fareToSell.journeyKey === journeyKeyToReplace) {
            this.services.alert.showError(this.services.language.translate('There is no point to replace a flight with the same flight'));
            return {};
        }

        if(this.booking.initialBookingSnapshot.journeys.all(j => j.journeyKey !== journeyKeyToReplace)) {
            //if the journey that we want to replace is not one of the initial booking
            //then we need to clear any sold seats otherwise those seats will be marked as HeldForAnotherSession
            //and then if the user goes back and selects again this journey then we won't be able to resell those seats because they are considered as locked on another session
            await this.currentJourneyModel.removeAllSeats();
        }


        const cancelTripRequest: IDotRezCancelJourneyRequest = {
            journeyKey: journeyKeyToReplace,
            //waiveSpoilageFee: false
        }

        if(this.hasFreeChange) {
            cancelTripRequest.waivePenaltyFee = true;
        }



        await this.booking.session.cancelTrip(cancelTripRequest);

        let bookingStateQueryBuilder = this.booking.session.bookingStateQueryBuilder().useBookingData();

        try {
            bookingStateQueryBuilder = await this._executePostJourneyDeleteActions(bookingStateQueryBuilder);
        } catch (err) {
            this.services.alert.showError(this.services.language.translate('A network error prevent us from continuing changing your flight. We reset your changes in order to prevent leaving your booking in an inconsistent state. You can try again latter.'));
            await this.services.navigator.goHome();
            return {};
        }

        return await bookingStateQueryBuilder.getBookingState();
    }

    private async _executePostJourneyDeleteActions(bookingStateQueryBuilder: IBookingStateQueryBuilder): Promise<IBookingStateQueryBuilder> {
        const searchController = this.booking.flightSearchController;
        await this.booking.session.sellTrip({
            journeysToCancel: [],
            ssrsToRemove: [],
            sellTripRequest: {
                preventOverlap: false,
                currencyCode: this.booking.currency,
                infantCount: searchController.passengers.countInfants(),
                keys: [
                    {
                        journeyKey: this.fareToSell.journeyKey,
                        fareAvailabilityKey: this.fareToSell.fareAvailabilityKey
                    }
                ],
                passengers: {
                    types: this.fareToSell.passengerTypes
                }
            }
        });

        const bundleCode = this.initialJourneySnapshot?.currentBundleCode;
        if(bundleCode) {
            await this.booking.session.sellBundle([
                this.fareToSell.journeyKey
            ], [], {
                bundleCode: bundleCode
            })
        }


        const bookingSessionData = await this.booking.session.bookingStateQueryBuilder().useAllAvailabilities().getBookingState();

        await this.onAfterSell(bookingSessionData);

        bookingStateQueryBuilder = await this._tryResellInitialSsrs(bookingStateQueryBuilder);

        return await this._tryResellInitialSeats(bookingStateQueryBuilder);
    }


    private async _tryResellInitialSsrs(bookingStateQueryBuilder: IBookingStateQueryBuilder): Promise<IBookingStateQueryBuilder> {
        if(!this.currentJourneyModel) {
            return bookingStateQueryBuilder;
        }
        const ssrsToAdd: IDotRezSsrToAdd[] = this.currentJourneyModel.getSsrsToResellOnFlightChange();

        if(ssrsToAdd.length === 0) {
            return bookingStateQueryBuilder;
        }

        await this.booking.session.addSsrs(ssrsToAdd);

        return bookingStateQueryBuilder.useSsrsAvailability();
    }

    private async _tryResellInitialSeats(bookingStateQueryBuilder: IBookingStateQueryBuilder): Promise<IBookingStateQueryBuilder> {
        if(!this.currentJourneyModel) {
            return bookingStateQueryBuilder;
        }

        const seatsToResell = this.currentJourneyModel.getSeatsToResellOnFlightChange();
        if(seatsToResell.length === 0) {
            return bookingStateQueryBuilder;
        }

        for(let seatToResell of seatsToResell) {
            try {

                await this.services.airlineWebapi.addSeat({
                    dotRezToken: this.booking.token,
                    journeyKey: this.currentJourneyModel.journeyKey,
                    passengerKey: seatToResell.passengerKey,
                    seatKey: seatToResell.seat.seatKey
                });

                seatToResell.seat.holdItForThisSession();
            } catch (err) {
                this.services.logger.error(`Failed to resell seat ${seatToResell.seat.seatNumber}`, err);
            }
        }

        return bookingStateQueryBuilder.useSeatMapsAvailability();
    }

    protected async onAfterSell(bookingSessionData: IDotRezPartialBookingSessionData): Promise<void> {
        await this._onAfterSell(bookingSessionData);
        this.booking.seatsMapsEditors.moveToNextPassengerWithoutASeat();
    }

    async onAfterBookingSessionDataUpdated(): Promise<void> {
        await super.onAfterBookingSessionDataUpdated();

        if(this.initialJourneySnapshot?.journeyKey && this.currentJourneyModel?.journeyKey) {
            this.booking.shoppingCart.notifications.pushDateChangeNotification(this.initialJourneySnapshot.journeyKey, this.currentJourneyModel?.journeyKey);
            this.currentJourneyModel.onBundleUpdated();
        }
    }
}
