import {
    IDotRezPartialBookingSessionData
} from "../../../../dot-rez-api/data-contracts/booking/dot-rez-booking-session-data.interface";
import {BookingModel} from "../../booking.model";
import {SeatModel} from "../../seat-maps/seat.model";
import {MaturePassengerModel} from "../../passenger/mature-passenger.model";
import {SeatMutationBase} from "./seat-mutation-base";

export class SellSeatMutation extends SeatMutationBase {
    constructor(booking: BookingModel,
                private readonly journeyKey: string,
                private readonly seat: SeatModel,
                private readonly passenger: MaturePassengerModel) {
        super(booking);
    }

    async _runMutation(): Promise<IDotRezPartialBookingSessionData> {
        try {
            const result = await this.services.airlineWebapi.addSeat({
                dotRezToken: this.session.token,
                journeyKey: this.journeyKey,
                passengerKey: this.passenger.passengerKey,
                seatKey: this.seat.seatKey
            });
            this.booking.analyticsHandler.addKeyMappings([result.seatKey]);

            this.services.analytics.seatSelectionEvents.logAddToCart(this.booking, this.journeyKey, this.passenger.passengerKey, result.seatKey);

            return {};

        } catch (err) {
            this.services.logger.error(`Failed to assign seat ${this.seat.seatKey} to passenger ${this.passenger.passengerKey}`, err);
            this._showError();
            return await this.session.bookingStateQueryBuilder().useSeatMapsAvailability().getBookingState();
        }

    }

    private _showError(): void {

        const msg = this.services.language.translationFor("Sorry! We couldn't allocate seat {seatNumber} to passenger {passengerName}. Please pick another seat.")
            .withParams({
                seatNumber: this.seat.seatNumber,
                passengerName: this.passenger.getFullName()
            });

        this.services.alert.showError(msg);
    }
}

