import React from "react";
import { observer } from "mobx-react";
import { useServices } from "../../../../../hooks/use-services.hook";
import { IJourneyViewModel } from "../../../../../services/booking/models/journey/journey-view-model.interface";
import { FlightItineraryExtrasHeaderComponent } from "./flight-itinerary-extras-header.component";
import { FlightItineraryExtrasDesignatorComponent } from "./flight-itinerary-extras-designator.component";
import { FlightItineraryAccordionComponent } from "../flight-itinerary-accordion.component";
import { FlightItineraryExtrasPassengerSsrsComponent } from "./flight-itinerary-extras-passenger-ssrs.component";
import {VerticalSpacer} from "../../../../../basic-components/spacing/vertical-spacer";

interface FlightItineraryExtrasComponentProps {
    journey: IJourneyViewModel;
}

export const FlightItineraryExtrasComponent: React.FC<FlightItineraryExtrasComponentProps> = observer((props) => {
    const services = useServices();
    const passengers = services.booking.current.passengers;

    const renderHeader = () => {
        return (
            <FlightItineraryExtrasHeaderComponent journey={props.journey} title={
                <FlightItineraryExtrasDesignatorComponent journey={props.journey}/>}>
            </FlightItineraryExtrasHeaderComponent>
        )
    }

    const renderContent = () => {
        return (
            <>
                {passengers.map((passenger) => {
                    return (
                        <React.Fragment key={passenger.stablePassengerUniqueKey}>
                            <VerticalSpacer size={"medium"}/>
                            <FlightItineraryExtrasPassengerSsrsComponent journey={props.journey}
                                                                         passenger={passenger}/>

                        </React.Fragment>
                    )
                })}
            </>
        )
    }

    return (
        <FlightItineraryAccordionComponent renderHeader={renderHeader}
                                           renderContent={renderContent}/>
    )
});
